import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-upload-lawyer',
  templateUrl: './admin-upload-lawyer.component.html',
  styleUrls: ['./admin-upload-lawyer.component.scss']
})
export class AdminUploadLawyerComponent implements OnInit {

  photographForm: FormGroup;
  // @Output() valueChange = new EventEmitter();
  @Output() buttonChange = new EventEmitter();
  @Output() removeOwner = new EventEmitter();
  @Input() appid: any;
  breadCrumbItems: any;
  applicationData: any;
  currentUser: any;
  DocumentList: Array<any>;
  AllowMultiple = false;
  fileToUpload: Array<any> = [];
  file: any;
  isLoading: boolean;
  submited: boolean;
  fileExtension: string;
  @ViewChild("Files", { static: false }) files: any;
  stateName: any;
  selectedDocument: any;
  requestURL: any;
  docFields: any;
  applicationDatadisplay: any;
  param: any;
  surveyNumbers: any = [];
  base64: any = [];
  // timeLeft: number = 120;
  interval: any;
  // isTimeOut = false;
  SelectedsurveyNumber: any;
  Selectedstate: any = 'Select';
  StateID:any;
  captchaValue: any;
  imagePath: any;
  inputType: any;
  docs: any;
  doctype: any;
  isClicked: any = false;
  isWrongCaptcha: boolean = false;
  isCaptchaEntered: boolean = false;
  key: any;
  stateList: any[] = [
    // {
    //   MappingID: 1,
    //   StateID: 7,
    //   APIState: "Gujarat",
    //   APIDocument: "Satbara",
    // },
    // {
    //   MappingID: 2,
    //   StateID: 7,
    //   APIState: "Gujarat",
    //   APIDocument: "Property Card",
    // },
  ];
  isFetchData: boolean = false;
  satbaraDocName: string;
  EightADocName: string;
  IsManualUpload: boolean = undefined;
  uploadType: any;
  currentKey: any;
  base64Array: any;
  isCall: boolean = true;
  DocumentType: string;
  DocumentSubType: string;
  DocumentName: string;
  otherdoc: boolean = false;
  firstName: any;
  lastName: any;
  propertyIndex:any = 0;
  documentIndex:any;
  appId: any;
  constructor(  private generalService: GeneralService,
    private router: Router,
    private route: ActivatedRoute,
    public dialogRef:MatDialogRef<AdminUploadLawyerComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any) {
      if(this.data){
        this.DocumentType = data.DocumentType;
        this.DocumentSubType = data.DocumentSubType ;
        this.DocumentName = data.DocumentName;
        this.propertyIndex = data.PropertyIndex ? data.PropertyIndex : 0;
        this.documentIndex = data.documentIndex;
        this.appId =data.appId;
        this.applicationData = data.applicationData
        // console.log("data",this.applicationData);
      }else{
        this.DocumentType = this.route.snapshot.queryParamMap.get('DocumentType');
        this.DocumentSubType = this.route.snapshot.queryParamMap.get('DocumentSubType');
        this.DocumentName = this.route.snapshot.queryParamMap.get('DocumentName');
        this.propertyIndex = this.route.snapshot.queryParamMap.get('PropertyIndex');
        this.appId = this.route.snapshot.params.AppID;
      }
      if(this.DocumentSubType === null || this.DocumentType === null || this.DocumentSubType === "null" || this.DocumentType === 'null'){
        this.DocumentSubType  ='';
        this.DocumentType = '';
      }
      if(this.route.snapshot.queryParamMap.get('OtherDocument')) {
        this.otherdoc = true;
      }
      this.currentUser = this.generalService.getcurrentUser();
      this.photographForm = new FormGroup({
        FileName: new FormControl("", [ Validators.required ]),
        FileType: new FormControl("", Validators.required),
        Description: new FormControl(""),
        uploadfile: new FormControl(null),
        DocumentSubTypeId: new FormControl(this.DocumentSubType ? this.DocumentSubType : '',),
        UserID: new FormControl(this.currentUser.UserID, Validators.required),
        CreatedBy: new FormControl(this.currentUser.UserID, Validators.required),
        DocumentTypeId: new FormControl(this.DocumentType ? this.DocumentType : ''),
        selectDocument: new FormControl(this.DocumentName, Validators.required),
        selectState: new FormControl(null),
        uploadType: new FormControl('Manual', Validators.required),
        selectSurveyNumber: new FormControl(null),
        DocNumber: new FormControl(null),
        CaptchCode: new FormControl(null),
      });
     }

  ngOnInit() {
    this.DocumentList = [];
    this.ChangeType('Manual');
    // this.isLoading = true;
    // this.generalService.getApplicationInformationdisplay(this.appId).subscribe((res) => {
    //   this.applicationDatadisplay = res.data;
    //   console.log("Lawyer Data",this.applicationDatadisplay)
    // });
    if(this.currentUser.UserType !== "Lawyer"){
      this.StateID = this.applicationData.data.Properties[this.propertyIndex].StateID;
        this.firstName = this.applicationData.data.ApplicatntDetails[0].FirstName;
        this.lastName =  this.applicationData.data.ApplicatntDetails[0].LastName;
      // this.generalService
      // .getMultiplePropertyInfo(this.appId)
      // .subscribe((appInfo) => {
      //   this.applicationData = appInfo;
      //   this.StateID = this.applicationData.data.Properties[this.propertyIndex].StateID;
      //   this.firstName = this.applicationData.data.ApplicatntDetails[0].FirstName;
      //   this.lastName =  this.applicationData.data.ApplicatntDetails[0].LastName;
        // console.log("Laywer data",this.applicationData)
        // this.generalService
        //   .GetLawyerDocumentList(this.appId)
        //   .subscribe((resp) => {
        //     this.isLoading = false;
        //     resp.data.map((data) => {
        //       if (data.Status !== "Reviewed") {
        //         this.DocumentList.push(data);
        //       }
        //     });
        //     this.submited = false;
        //     this.isLoading = false;
        //     this.photographForm.controls.FileType.disable();
        //     this.photographForm.controls.selectState.disable();
        //     if(this.DocumentType !== null) {
        //       console.log(this.DocumentType);
        //       this.photographForm.controls.selectDocument.disable();
        //     }
        //   });
        this.generalService
          .getDropDownState(this.StateID)
          .subscribe((res) => {
            this.isLoading = false;
            this.stateList = res.data;
            // this.getDocumentName();
          });
      // });
    }
    else{
        // this.isLoading = false;
        this.generalService
        .GetApplicationInformation(this.appId)
        .subscribe((appInfo) => {
          this.applicationData = appInfo;
          this.StateID = this.applicationData.data[0].StateID ;
          this.firstName = this.applicationData.data[0].FirstName;
          this.lastName =  this.applicationData.data[0].LastName;
          // this.generalService
          //   .GetLawyerDocumentList(this.appId)
          //   .subscribe((resp) => {
          //     this.isLoading = false;
          //     resp.data.map((data) => {
          //       if (data.Status !== "Reviewed") {
          //         this.DocumentList.push(data);
          //       }
          //     });
          //     this.submited = false;
          //     this.isLoading = false;
          //     this.photographForm.controls.FileType.disable();
          //     this.photographForm.controls.selectState.disable();
          //     if(this.DocumentType !== null) {
          //       console.log(this.DocumentType);
          //       this.photographForm.controls.selectDocument.disable();
          //     }
          //   });
          this.generalService
            .getDropDownState(this.StateID)
            .subscribe((res) => {
              this.isLoading = false;
              this.stateList = res.data;
              // this.getDocumentName();
            });
        });
    }
  }
  private prepareSave(): any {
    const input = new FormData();
    // console.log('filename',this.photographForm.controls.uploadfile)
    if(this.fileToUpload.length === 0){
      input.append("uploadfile", this.photographForm.controls.uploadfile.value.this.photographForm.get("FileName").value);
    }
    for (var i = 0; i < this.fileToUpload.length; i++) {
      if(this.IsManualUpload == true) {
        input.append("uploadfile", this.fileToUpload[i]);
      } else {
        input.append("uploadfile", this.fileToUpload[i], this.photographForm.get("FileName").value);
      }  
    }

    // uploadfile
    console.log('this.fileToUpload[i]',this.fileToUpload[i])
    // This can be done a lot prettier; for example automatically assigning values by
    // looping through `this.form.controls`, but we'll keep it as simple as possible here
    input.append("FileName",this.photographForm.get("FileName").value);
    input.append("FileType", this.photographForm.get("FileType").value);
    input.append("Description", this.photographForm.get("Description").value);

    if(this.photographForm.get("DocumentSubTypeId").value === null || this.photographForm.get("DocumentSubTypeId").value === 'null'){
      input.append("DocumentSubTypeId",'');
    }
    else{
      input.append("DocumentSubTypeId",this.photographForm.get("DocumentSubTypeId").value);
    }
    if(this.photographForm.get("DocumentTypeId").value === null || this.photographForm.get("DocumentTypeId").value === 'null'){
      input.append("DocumentTypeId",'');
    }
    else{
      input.append("DocumentTypeId",this.photographForm.get("DocumentTypeId").value);
    }
    // input.append('uploadfile', this.photographForm.get('uploadfile');
    input.append("UserID", this.photographForm.get("UserID").value);
    input.append("CreatedBy", this.photographForm.get("CreatedBy").value);
    return input;
  }
  private prepareotherDocSave(): any {
    const input = new FormData();
    for (var i = 0; i < this.fileToUpload.length; i++) {
      // console.log('file',this.fileToUpload)
      input.append("File", this.fileToUpload[i], this.photographForm.get("FileName").value + this.fileExtension);  
    }
    input.append("FileName", this.photographForm.get("FileName").value);
    input.append("FileType", this.photographForm.get("FileType").value);
    input.append("Description", this.photographForm.get("Description").value);
    input.append("AppID", this.applicationData.data.ApplicatntDetails[0].AppID);
    input.append("PropertyID", this.applicationData.data.Properties[this.propertyIndex].PropertyID);
    return input;
  }
  private prepareSave_Process_PropertyDoc(AppID, state, document): any {
    // debugger;
    // console.log('savgd');
    const input1 = new FormData();
    // console.log('file',this.fileToUpload)
    for (var i = 0; i < this.fileToUpload.length; i++) {
      if(this.IsManualUpload == true) {
        input1.append("files[]", this.fileToUpload[i]);
      } else {
        input1.append("files[]", this.fileToUpload[i], this.photographForm.get("FileName").value + this.fileExtension);
      }
    }
    // This can be done a lot prettier; for example automatically assigning values by
    // looping through `this.form.controls`, but we'll keep it as simple as possible here
    input1.append("application_id", AppID);
    input1.append("state", state);
    input1.append("document", document);
    return input1;
  }
  get f() {
    return this.photographForm.controls;
  }
  onchange(type, e, name) {
    if (type == 'Manual') {
      this.IsManualUpload = true;
      // if (!this.AllowMultiple && e.length > 1) {
      //   this.photographForm.controls.uploadfile.setErrors({
      //     CustomeFileUploadError: true
      //   })
      // }
      if (e && e.length > 0) {
        const file = e[0];
        this.fileToUpload = e;
        let fileName = file.name.split(".")[0];
        const filetype = file.type;
        const fileExtension = file.name.split(".").pop();
        this.setform(fileName, filetype, fileExtension);
      } else {
        this.photographForm.controls.FileType.setValue("");
        this.photographForm.controls.FileName.setValue("");
        this.photographForm.controls.Description.setValue("");
        // this.photographForm.controls.DocumentTypeId.setValue(null);
        this.fileExtension = "";
      }
      // console.log('this. upload file',this.fileToUpload)
    } else if(type == 'Auto') {
      const file = new File([e], name);
      this.fileToUpload = e;
      let fileName = name;
      const filetype = 'application/pdf';
      const fileExtension = 'pdf';
      this.photographForm.controls.FileType.setValue("");
      this.setform(fileName, filetype, fileExtension);
    }
  }
  setform(fileName, filetype, extension) {
    if (
      (filetype.toLowerCase() === "application/pdf" &&
      extension.toLowerCase() === "pdf" ) ||( filetype.toLowerCase() === "image/jpeg" && extension.toLowerCase() === "jpg" ) ||
      ( filetype.toLowerCase() === "image/png" && extension.toLowerCase() === "png" )
    ) {
      this.photographForm.controls.FileType.setValue(extension.toUpperCase());
      this.photographForm.controls.FileName.setValue(fileName);
      this.fileExtension = extension.toLowerCase();
    } else {
      this.photographForm.controls.uploadfile.setValue([]);
      Swal.fire({
        title: `Error`,
        text: `${extension} File Are Not Supported`,
        type: "error",
        showConfirmButton:false,
        timer:3000
      });
    }
  }
  ChangeType(data) {
    // this.photographForm.controls.selectDocument.setValue(null);
    // this.photographForm.controls.selectDocument.setValue(null);
    this.photographForm.controls.uploadfile.setValue(null);
    this.photographForm.controls.selectState.disable();
    this.photographForm.controls.selectDocument.enable();
    this.submited = false;
    this.uploadType = data;
    if(this.uploadType == 'Manual') {
      this.photographForm.controls['uploadfile'].setValidators([Validators.required]);
      this.photographForm.controls['selectState'].clearValidators();
      this.IsManualUpload = true;
    } else {
      this.photographForm.controls['selectState'].setValidators([Validators.required]);
      this.photographForm.controls['uploadfile'].clearValidators();
      this.IsManualUpload = false;
    }
    this.photographForm.controls['uploadfile'].updateValueAndValidity();
    this.photographForm.controls['selectState'].updateValueAndValidity();
    this.photographForm.controls['DocNumber'].updateValueAndValidity();
    this.photographForm.controls['selectSurveyNumber'].updateValueAndValidity();
    this.photographForm.controls['CaptchCode'].updateValueAndValidity();
  }
  addMultiplePropertyDoc() {
    this.generalService
      .AddMultiplePropertyDocument(
        this.applicationData.data.Properties[this.propertyIndex].PropertyID,
        this.prepareSave(),
        ''
      )
      .subscribe((data) => {
        this.isLoading = false;
        this.ResetForm();
        if (data.status === 200) {
          this.submited = false;
          Swal.fire({
            title: "Uploaded",
            text: "Property Document Upload Successfully",
            type: "success",
            showConfirmButton:false,
            timer:3000
          }).then(() => {
            this.CloseDialog(true);
            if (this.currentUser.UserType === "Bank Manager") {
              this.router.navigate([
                `/loan/view-pvr-list`,
              ])
            } else if (this.currentUser.UserType === "Lawyer") {

              this.updateAppStatus('TC Document In Progress');
              this.router.navigate([
                `/loan/assignment/${this.appId}`,
              ]);
            } else {
              // this.router.navigate([
              //   `/loan/view-pvr/${this.appId}`,
              // ])
              this.buttonChange.emit('success');
              // if(this.IsManualUpload == true && this.isFetchData == true) {
              //   this.valueChange.emit('success');
              // } else {
              //   this.buttonChange.emit('success');
              // }
            }
          });
        } else {
          Swal.fire({
            title: data.error_code,
            text: data.message,
            type: "error",
            showConfirmButton:false,
            timer:3000
          });
        }
      });
    // .catch((err) => {
    //   this.isLoading = false;
    // });
  }
  addMultiplePropertyOtherDoc() {
    this.generalService
      .newDocumentUpload(
        this.prepareotherDocSave()
      )
      .subscribe((data) => {
        this.isLoading = false;
        if (data.status === 200) {
          this.submited = false;
          Swal.fire({
            title: "Uploaded",
            text: "Property Document Upload Successfully",
            type: "success",
            showConfirmButton:false,
            timer:3000
          }).then(() => {
            this.updateAppStatus('TC Document In Progress');
            this.router.navigate([`/loan/assignment/${this.appId}`]);
          });
        } else {
          Swal.fire({
            title: data.error_code,
            text: data.message,
            type: "error",
            showConfirmButton:false,
            timer:3000
          });
        }
      });
  }
  onSubmit() {
    this.photographForm.controls['DocNumber'].clearValidators();
    this.photographForm.controls['selectSurveyNumber'].clearValidators();
    this.photographForm.controls['CaptchCode'].clearValidators();
    this.photographForm.controls['DocNumber'].updateValueAndValidity();
    this.photographForm.controls['selectSurveyNumber'].updateValueAndValidity();
    this.photographForm.controls['CaptchCode'].updateValueAndValidity();
    this.submited = true;
    if( this.otherdoc == true) {
      this.photographForm.controls['DocumentTypeId'].clearValidators();
      this.photographForm.controls['DocumentSubTypeId'].clearValidators();
      this.photographForm.controls['selectDocument'].clearValidators();
      this.photographForm.controls['DocumentTypeId'].updateValueAndValidity();
      this.photographForm.controls['DocumentSubTypeId'].updateValueAndValidity();
      this.photographForm.controls['selectDocument'].updateValueAndValidity();
      this.photographForm.controls.DocumentTypeId.setValue('');
      this.photographForm.controls.DocumentSubTypeId.setValue('');
      this.photographForm.controls.selectDocument.setValue('');
    } 
    if (this.photographForm.valid) {
      this.stateName = this.applicationData.data.Properties[this.propertyIndex].StateName 
      // 1 is Property ID
      this.isLoading = true;
      // console.log("params data",this.photographForm.value);
      // if (this.AllowMultiple) {
      if (this.photographForm.value.selectState) {
        // this.generalService.ownerInfo.emit(true);
        if(this.IsManualUpload == true) {
          this.generalService
          .ProcessDocumentUpload(
            this.prepareSave_Process_PropertyDoc(
              this.applicationData.data.ApplicatntDetails[0].AppID,
              this.stateName,
              this.photographForm.value.selectState.APIDocument
            )
          )
          .then((data) => {
            this.addMultiplePropertyDoc();
          })
          .catch((err) => {
            this.isLoading = false;
          });
        } else {
          this.addMultiplePropertyDoc();
        }
        setTimeout(() => {
          if (this.isLoading) {
            this.addMultiplePropertyDoc();
          }
        }, 100000);
      } else {
        this.addMultiplePropertyDoc();
      }
    } else{
      // console.log(this.photographForm);
    }
  }
  close(){
    this.CloseDialog(false);
  }
  Changedocument(e) {
    this.photographForm.controls.selectState.enable();
    this.photographForm.controls.selectState.setValue(null)
    this.selectedDocument = e.DocumentName;
    if (
      this.selectedDocument === "Govt. certified copy of latest 7/12" ||
      this.selectedDocument === "Property Card"
    ) {
      this.isFetchData = true;
    } else {
      this.isFetchData = false;
    }
    this.AllowMultiple = false;
    if (e.DocumentName === "Govt. certified copy of latest 7/12")
      this.AllowMultiple = true;

    this.ResetForm();
    if (e) {
      this.f.DocumentSubTypeId.setValue(e.DocumentSubType);
      this.f.DocumentTypeId.setValue(e.DocumentType);
    } else {
      this.f.DocumentSubTypeId.setValue('');
      this.f.DocumentTypeId.setValue('');
      // this.f.DocumentTypeId.setValue(null);
    }
  }
  onChangeState(value) {
    let find = this.stateList.find((f) => f.APIDocument === value.APIDocument);
    if (find) {
      this.stateName = find.APIState;
    }
    if(this.IsManualUpload == false) {
      this.getDocURL(value.StateID, value.APIDocument);
    }
  }
  getDocURL(stateId, doctype) {
    this.isLoading = true;
    this.generalService
      .GetDocumentURL(stateId, doctype)
      .subscribe((res) => {
        this.docFields = res;
        this.requestURL = res.url;
        this.getDocument();
      });

  }
  getDocument() {
    let formData: FormData = new FormData();

    formData.append('app_id', this.applicationData.data.ApplicatntDetails[0].AppID);
    formData.append('state', this.applicationData.data.Properties[this.propertyIndex].StateName);
    formData.append('document', this.docFields.document);
    const hasDistrict = 'District' in this.docFields;
    if (hasDistrict) {
      const distFieldname = this.docFields.District;
      formData.append('district', this.applicationData.data.ApplicatntDetails[0][distFieldname]);
    }
    const hasHobli = 'Hobli' in this.docFields;
    if (hasHobli) {
      const hobliFieldname = this.docFields.Hobli;
      formData.append('hobli', this.applicationData.data.ApplicatntDetails[0][hobliFieldname]);
    }
    const hastaluka = 'Taluka' in this.docFields;
    if (hastaluka) {
      const talukaFieldname = this.docFields.Taluka;
      formData.append('taluka',this.applicationData.data.ApplicatntDetails[0][talukaFieldname]);
    }
    const hasVillage = 'Village' in this.docFields;
    if (hasVillage) {
      const villageFieldname = this.docFields.Village;
      formData.append('village', this.applicationData.data.ApplicatntDetails[0][villageFieldname]);
    }
    this.generalService
      .downloadDocument(this.requestURL, formData)
      .subscribe((res): any => {
        this.isLoading = false;
        if (res.base64PDF) {
          const responseType = typeof(res.base64PDF)
          this.inputType = undefined;
          this.key = undefined;
          if (responseType === 'object') {
            this.base64.push(res.base64PDF.data);
          } else if(responseType === 'string') {
            if(res.base64PDF.includes('data')) {
              let str:string =`'data': '`;
              let str2: string = `'}`;
              this.base64Array = res.base64PDF.split(',');
              for(let i=0;i<this.base64Array.length;i++) {
                this.base64Array[i] = this.base64Array[i].replace(str,'"data": "');
                this.base64Array[i] = this.base64Array[i].replace(str2,'"}');
              }
              let base64PDF:any = this.base64Array.join(',');
              base64PDF = JSON.parse(base64PDF);
              this.base64Array = [];
              if(this.applicationData.data.Properties[this.propertyIndex].StateName === "Karnataka") {
                this.base64Array = base64PDF;
              } else {
                this.base64Array.push(base64PDF);
              }
              for(let i=0;i<this.base64Array.length;i++) {
                this.base64.push(this.base64Array[i].data);
              }
            } else {
              if(this.applicationData.data.Properties[this.propertyIndex].StateName === "Madhya Pradesh" || this.applicationData.data.Properties[this.propertyIndex].StateName === "Chhattisgarh" || this.applicationData.data.Properties[this.propertyIndex].StateName === "Tripura") {
                let str3:string =`[`;
                let str4: string = `]`;
                let str5: string = `b'`;
                let str6: string = `'`;
                this.base64Array = res.base64PDF.split(',');
                for(let i=0;i<this.base64Array.length;i++) {
                  this.base64Array[i] = this.base64Array[i].replace(str3,'');
                  this.base64Array[i] = this.base64Array[i].replace(str4,'');
                  this.base64Array[i] = this.base64Array[i].replace(str5,'');
                  this.base64Array[i] = this.base64Array[i].replace(str6,'');
                }
                for(let i=0;i<this.base64Array.length;i++) {
                  this.base64.push(this.base64Array[i]);
                }
              }
              else {
                this.base64.push(res.base64PDF);
                if(res.base64PDF.includes(`b'`)) {
                  this.base64[0] = this.base64[0].substring(2, this.base64[0].length - 1);
                }
              }
            } 
          }
          if (this.base64.length > 0) {
            this.pauseTimer();
            this.isLoading = false;
            if (this.isFetchData) {
              // this.valueChange.emit();
            }
            let file_array= [];
            for(let i=0;i<this.base64.length;i++) {
              const byteArray = new Uint8Array(
                atob(this.base64[i])
                  .split("")
                  .map(char => char.charCodeAt(0))
              );
              const file = new Blob([byteArray], { type: "application/pdf" });
              file_array.push(file);
            }
            
            let pdfName = this.docFields.document + ".pdf";
            this.onchange('Auto', file_array, pdfName);
          } else {
            this.removeDocument(false);
            Swal.fire({
              title: `Error`,
              text: `Your Requested Document Not Found`,
              type: "error",
              showConfirmButton:false,
              timer:3000
            });
            this.isLoading = false;
            this.pauseTimer();
          }
        }
        else {
          this.removeDocument(false);
          Swal.fire({
            title: `Error`,
            text: `Your Requested Document Not Found`,
            type: "error",
            showConfirmButton:false,
            timer:3000
          });
          this.isLoading = false;
          this.pauseTimer();
        }
      }, error => {
        this.removeDocument(false);
        Swal.fire({
          title: `Error`,
          text: `Your Requested Document Not Found`,
          type: "error",
          showConfirmButton:false,
          timer:3000
        });
        this.isLoading = false;
        this.pauseTimer();
      });
    this.startTimer();
  }
  _getSurveyNumbers() {
    // this.isTimeOut === false && 
    if (this.isCall == true) {
      this.isCall = false;
      this.generalService.getSurveyList(this.applicationData.data.ApplicatntDetails[0].AppID).subscribe((response: any) => {
        this.isCall = true;
        this.inputType = response.Type;
        this.key = response.key;
        if (response.key && response.key != 'Captcha'){
          if(this.currentKey != response.key) {
            if (this.inputType == 'dropdown') {
              this.surveyNumbers = response.value.split(',');
              this.SelectedsurveyNumber = this.surveyNumbers[0];
            }
            this.currentKey = response.key;
            this.pauseTimer();
            this.isLoading = false;
          }
        } else if (response.key && response.key === 'Captcha') {
          if (response.value != 'True' && response.value != 'False' && this.isCaptchaEntered == false) {
            this.imagePath = response.value;
            if(this.imagePath.charAt(0) == ',') {
              this.imagePath = this.imagePath.substring(1);
            }
            this.pauseTimer();
            this.isLoading = false;
          } else if (response.value == 'True') {
            this.pauseTimer();
            this.isWrongCaptcha = false;
          } else if (response.value == 'False' && this.isCaptchaEntered == true) {
            this.isWrongCaptcha = true;
            this.isCaptchaEntered = false;
            this.captchaValue = '';
            return;
          }
        }
      }, error => {
        this.isCall = true;
      });
    }
  }

  startTimer() {
    let limit;
    if (this.key == 'Captcha')
      limit = 1000;
    else
      limit = 2000;
    this.interval = setInterval(() => {
      // if (this.timeLeft > 0) {
      //   this.timeLeft--;
      // }
      // if (this.timeLeft === 0) {
      //   this.isTimeOut = true;
      // }
      this._getSurveyNumbers();
    }, limit);
  }
  _postRequest() {
    this.isLoading = true;
    this.pauseTimer();
    let formData: FormData = new FormData();
    if (this.key === 'Captcha') 
      this.isCaptchaEntered = true;
    formData.append('app_id', this.applicationData.data.ApplicatntDetails[0].AppID);
    formData.append('key', this.key);
    formData.append('value', `${this.key != 'Captcha' ? this.SelectedsurveyNumber.trim() : this.captchaValue}`);
    this.generalService.postRequestDocument(formData).subscribe((response: any) => {
      this.SelectedsurveyNumber = '';
      // this.timeLeft = 120;
      this.startTimer();
    });

  }
  pauseTimer() {
    clearInterval(this.interval);
  }

  ResetForm() {
    this.photographForm.controls.FileType.setValue("");
    this.photographForm.controls.FileName.setValue("");
    this.photographForm.controls.Description.setValue("");
    // this.photographForm.controls.uploadfile.setValue([]);
  }

  downloadPdf(docname: any) {
    this.base64.forEach( element => {
      const byteArray = new Uint8Array(
        atob(element)
          .split("")
          .map(char => char.charCodeAt(0))
      );
      const file = new Blob([byteArray], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      let pdfName = docname + ".pdf";
      const nav = (window.navigator as any);
      if (nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(file, pdfName);
      } else {
        let link = document.createElement("a");
        link.download = pdfName;
        link.target = "_blank";
        link.href = fileURL;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
      
  }
  removeDocument(type) {
    if ( (this.selectedDocument === "Govt. certified copy of latest 7/12" || this.selectedDocument === "Property Card") && type == true) 
      this.removeOwner.emit();
    this.base64 = [];
    this.inputType = undefined;
    this.surveyNumbers = undefined;
    this.selectedDocument = ''
    // this.timeLeft = 120;
    this.isCaptchaEntered = false;
    this.isWrongCaptcha = false;
    this.photographForm.reset();
    if(this.DocumentType !== null || this.DocumentType !== '') {
      // console.log(this.DocumentType);
      this.photographForm.controls.selectDocument.disable();
    }
    this.photographForm.controls.selectState.disable();
    this.photographForm.controls.CreatedBy.setValue(this.currentUser.UserID);
    this.photographForm.controls.UserID.setValue(this.currentUser.UserID);
    this.SelectedsurveyNumber = '';
    this.captchaValue = '';
    this.fileToUpload = [];   
    this.currentKey = undefined;
    this.IsManualUpload = undefined;
  }
  updateAppStatus(status) {
    let data = {
      applicationStatus: status
    }
    this.isLoading = true;
    // console.log(data);
    this.generalService.updateAppStatus(this.appId, data).subscribe((res) => {
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
    });
  }
  CloseDialog(close:boolean) {
    this.dialogRef.close(close);
  }

}
