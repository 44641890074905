import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-upload-document-dialog',
  templateUrl: './upload-document-dialog.component.html',
  styleUrls: ['./upload-document-dialog.component.scss']
})
export class UploadDocumentDialogComponent implements OnInit {
  photographForm: FormGroup;
  // @Output() valueChange = new EventEmitter();
  @Output() buttonChange = new EventEmitter();
  @Output() removeOwner = new EventEmitter();
  @Input() appid: any;
  breadCrumbItems: any;
  applicationData: any;
  currentUser: any;
  DocumentList: Array<any>;
  AllowMultiple = false;
  fileToUpload: Array<any> = [];
  file: any;
  isLoading: boolean;
  submited: boolean;
  fileExtension: string;
  @ViewChild("Files", { static: false }) files: any;
  stateList: any[] = [
    // {
    //   MappingID: 1,
    //   StateID: 7,
    //   APIState: "Gujarat",
    //   APIDocument: "Satbara",
    // },
    // {
    //   MappingID: 2,
    //   StateID: 7,
    //   APIState: "Gujarat",
    //   APIDocument: "Property Card",
    // },
  ];
  stateName: any;
  selectedDocument: any;
  requestURL: any;
  docFields: any;
  applicationDatadisplay: any;
  param: any;
  surveyNumbers: any = [];
  base64: any = [];
  // timeLeft: number = 120;
  interval: any;
  // isTimeOut = false;
  SelectedsurveyNumber: any;
  Selectedstate: any = 'Select';
  captchaValue: any;
  imagePath: any;
  inputType: any;
  docs: any;
  doctype: any;
  isClicked: any = false;
  isWrongCaptcha: boolean = false;
  isCaptchaEntered: boolean = false;
  key: any;
  isFetchData: boolean = false;
  satbaraDocName: string;
  EightADocName: string;
  IsManualUpload: boolean = undefined;
  uploadType: any;
  currentKey: any;
  base64Array: any;
  isCall: boolean = true;
  DocumentType: string;
  DocumentSubType: string;
  DocumentName: string;
  DocumentId: string = '';
  otherdoc: boolean = false;
  constructor(
    private generalService: GeneralService,
    private router: Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<UploadDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log(this.data);
    if(this.data.DocumentSubType == null || this.data.DocumentSubType == 'null' || this.data.DocumentSubType == undefined) {
      this.DocumentType = '';
      this.DocumentSubType = '';
      console.log('ABC');
    } else{
      this.DocumentType = this.data.DocumentType;
      this.DocumentSubType = this.data.DocumentSubType;
    }
    if(this.data.DocumentId !== null && this.data.DocumentId !== 'null' && this.data.DocumentId !== undefined){
      this.DocumentId ='?DocumentId=' + data.DocumentId; 
    }
    
    
    this.DocumentName = this.data.DocumentName;
    this.currentUser = this.generalService.getcurrentUser();
    this.photographForm = new FormGroup({
      FileName: new FormControl("", [ Validators.required ]),
      FileType: new FormControl("", Validators.required),
      Description: new FormControl(""),
      uploadfile: new FormControl(null),
      DocumentSubTypeId: new FormControl(this.DocumentSubType),
      UserID: new FormControl(this.currentUser.UserID, Validators.required),
      CreatedBy: new FormControl(this.currentUser.UserID, Validators.required),
      DocumentTypeId: new FormControl(this.DocumentType),
      selectDocument: new FormControl(this.DocumentName, Validators.required),
      selectState: new FormControl(null),
      uploadType: new FormControl('Manual', Validators.required),
      selectSurveyNumber: new FormControl(null),
      DocNumber: new FormControl(null),
      CaptchCode: new FormControl(null),
    });
  }
  ngOnInit() {
    this.DocumentList = [];
    this.isLoading = true;
    this.ChangeType('Manual');
    this.generalService.getApplicationInformationdisplay(this.data.Appid).subscribe((res) => {
      this.applicationDatadisplay = res.data;
    });
    this.generalService
      .GetApplicationInformation(this.data.Appid)
      .subscribe((appInfo) => {
        this.applicationData = appInfo.data[0];
        if (!this.appid) {
          if (this.currentUser.UserType === "Bank Manager") {
            this.breadCrumbItems = [
              { label: "Dashboard", path: "/loan" },
              { label: "Applications", path: "/loan/applications" },
              {
                label: `${this.applicationData.FirstName} ${this.applicationData.LastName}`,
                path: `/loan/title-search/${this.applicationData.AppID}`,
              },
              { label: "Upload Documents", path: "/", active: true },
            ];
          } else if (this.currentUser.UserType === "Lawyer") {
            this.breadCrumbItems = [
              { label: "Dashboard", path: "/loan" },
              { label: "Assignment", path: "/loan/assignment" },
              {
                label: `${this.applicationData.FirstName != null ? this.applicationData.FirstName : ''} ${this.applicationData.LastName != null ? this.applicationData.LastName : ''}`,
                path: `/loan/assignment/${this.applicationData.AppID}`,
              },
              { label: "Upload Documents", path: "/", active: true },
            ];
          }
        }
        this.generalService
          .GetLawyerDocumentList(this.data.Appid)
          .subscribe((resp) => {
            this.isLoading = false;
            resp.data.map((data) => {
              if (data.Status !== "Reviewed") {
                this.DocumentList.push(data);
              }
            });
            this.submited = false;
            this.isLoading = false;
            this.photographForm.controls.FileType.disable();
            this.photographForm.controls.selectState.disable();
            if(this.DocumentType !== null) {
              console.log(this.DocumentType);
              this.photographForm.controls.selectDocument.disable();
            }
          });
        this.generalService
          .getDropDownState(appInfo.data[0].StateID)
          .subscribe((res) => {
            this.isLoading = false;
            this.stateList = res.data;
            // this.getDocumentName();
          });
      });
  }

  // getDocumentName(){
  //   if(this.stateList[0].APIState === 'Karnataka') {
  //     this.satbaraDocName = 'RTC';
  //     this.EightADocName = '11B';
  //   } else if(this.stateList[0].APIState === 'Gujarat') {
  //     this.satbaraDocName = 'Satbara';
  //     this.EightADocName = '8A';
  //   } else if(this.stateList[0].APIState === 'Andhra Pradesh') {
  //     this.satbaraDocName = 'Adangal';
  //     this.EightADocName = '1B';
  //   }
  // }
  private prepareSave(): any {
    const input = new FormData();
    input.append('PropertyID',this.data.PropertyID ? this.data.PropertyID : '')
    for (var i = 0; i < this.fileToUpload.length; i++) {
      if(this.IsManualUpload == true) {
        input.append("uploadfile", this.fileToUpload[i]);
      } else {
        input.append("uploadfile", this.fileToUpload[i], this.photographForm.get("FileName").value);
      }  
    }
   
    // This can be done a lot prettier; for example automatically assigning values by
    // looping through `this.form.controls`, but we'll keep it as simple as possible here
    input.append(
      "FileName",
      this.photographForm.get("FileName").value);
    input.append("FileType", this.photographForm.get("FileType").value);
    input.append("Description", this.photographForm.get("Description").value);
    input.append(
      "DocumentSubTypeId",
      this.photographForm.get("DocumentSubTypeId").value
    );
    // input.append('uploadfile', this.photographForm.get('uploadfile');
    input.append("UserID", this.photographForm.get("UserID").value);
    input.append("CreatedBy", this.photographForm.get("CreatedBy").value);
    input.append(
      "DocumentTypeId",
      this.photographForm.get("DocumentTypeId").value
    );
    return input;
  }
  private prepareotherDocSave(): any {
    const input = new FormData();
    for (var i = 0; i < this.fileToUpload.length; i++) {
      input.append("File", this.fileToUpload[i], this.photographForm.get("FileName").value + this.fileExtension);  
    }
    
    input.append("FileName", this.photographForm.get("FileName").value);
    input.append("FileType", this.photographForm.get("FileType").value);
    input.append("Description", this.photographForm.get("Description").value);
    input.append("AppID", this.applicationData.AppID);
    input.append("PropertyID", this.applicationData.PropertyID);
    return input;
  }
  private prepareSave_Process_PropertyDoc(AppID, state, document): any {
    // debugger;
    console.log('savgd');
    const input1 = new FormData();
    for (var i = 0; i < this.fileToUpload.length; i++) {
      if(this.IsManualUpload == true) {
        input1.append("files[]", this.fileToUpload[i]);
      } else {
        input1.append("files[]", this.fileToUpload[i], this.photographForm.get("FileName").value + this.fileExtension);
      }
    }
    // This can be done a lot prettier; for example automatically assigning values by
    // looping through `this.form.controls`, but we'll keep it as simple as possible here
    input1.append("application_id", AppID);
    input1.append("state", state);
    input1.append("document", document);
    
    return input1;
  }
  get f() {
    return this.photographForm.controls;
  }
  onchange(type, e, name) {
    if (type == 'Manual') {
      this.IsManualUpload = true;
      // if (!this.AllowMultiple && e.length > 1) {
      //   this.photographForm.controls.uploadfile.setErrors({
      //     CustomeFileUploadError: true
      //   })
      // }
      if (e && e.length > 0) {
        const file = e[0];
        this.fileToUpload = e;
        let fileName = file.name.split(".")[0];
        const filetype = file.type;
        const fileExtension = file.name.split(".").pop();
        this.setform(fileName, filetype, fileExtension);
      } else {
        this.photographForm.controls.FileType.setValue("");
        this.photographForm.controls.FileName.setValue("");
        this.photographForm.controls.Description.setValue("");
        // this.photographForm.controls.DocumentTypeId.setValue(null);
        this.fileExtension = "";
      }
    } else if(type == 'Auto') {
      const file = new File([e], name);
      this.fileToUpload = e;
      let fileName = name;
      const filetype = 'application/pdf';
      const fileExtension = 'pdf';
      this.photographForm.controls.FileType.setValue("");
      this.setform(fileName, filetype, fileExtension);
    }
  }
  setform(fileName, filetype, extension) {
    if (
      filetype.toLowerCase() === "application/pdf" &&
      extension.toLowerCase() === "pdf"
    ) {
      this.photographForm.controls.FileType.setValue("pdf");
      this.photographForm.controls.FileName.setValue(fileName);
      this.fileExtension = extension.toLowerCase();
    } else {
      this.photographForm.controls.uploadfile.setValue([]);
      Swal.fire({
        title: `Error`,
        text: `${extension} File Are Not Supported`,
        type: "error",
        showConfirmButton:false,
        timer:3000
      });
    }
  }
  ChangeType(data) {
    // this.photographForm.controls.selectDocument.setValue(null);
    // this.photographForm.controls.selectDocument.setValue(null);
    this.photographForm.controls.uploadfile.setValue(null);
    this.photographForm.controls.selectState.disable();
    this.photographForm.controls.selectDocument.enable();
    this.submited = false;
    this.uploadType = data;
    if(this.uploadType == 'Manual') {
      this.photographForm.controls['uploadfile'].setValidators([Validators.required]);
      this.photographForm.controls['selectState'].clearValidators();
      this.IsManualUpload = true;
    } else {
      this.photographForm.controls['selectState'].setValidators([Validators.required]);
      this.photographForm.controls['uploadfile'].clearValidators();
      this.IsManualUpload = false;
    }
    this.photographForm.controls['uploadfile'].updateValueAndValidity();
    this.photographForm.controls['selectState'].updateValueAndValidity();
    this.photographForm.controls['DocNumber'].updateValueAndValidity();
    this.photographForm.controls['selectSurveyNumber'].updateValueAndValidity();
    this.photographForm.controls['CaptchCode'].updateValueAndValidity();
  }
  addMultiplePropertyDoc() {
    this.generalService
      .AddMultiplePropertyDocument(
        this.data.PropertyID,
        this.prepareSave(),
        this.DocumentId
      )
      .subscribe((data) => {
        this.isLoading = false;
        this.ResetForm();
        if (data.status === 200) {
          this.submited = false;
          Swal.fire({
            title: "Uploaded",
            text: "Property Document Upload Successfully",
            type: "success",
            showConfirmButton:false,
            timer:3000
          }).then(() => {
            this.generalService.changeDocStatus(data.data.DocumentID).subscribe((res) => {
              this.isLoading = false;
              console.log(res);
              Swal.fire({
                  title: `Success`,
                  text: res.message,
                  type: 'success',
                  showConfirmButton:false,
                  timer:3000
                }).then(() => { 
                  this.CloseDialog(); 
                });
            });
          });
        } else {
          Swal.fire({
            title: data.error_code,
            text: data.message,
            type: "error",
            showConfirmButton:false,
            timer:3000
          });
        }
      });
    // .catch((err) => {
    //   this.isLoading = false;
    // });
  }
  addMultiplePropertyOtherDoc() {
    this.generalService
      .newDocumentUpload(
        this.prepareotherDocSave(),
        this.DocumentId
      )
      .subscribe((data) => {
        this.isLoading = false;
        if (data.status === 200) {
          this.submited = false;
          Swal.fire({
            title: "Uploaded",
            text: "Property Document Upload Successfully",
            type: "success",
            showConfirmButton:false,
            timer:3000
          }).then(() => {
            this.generalService.changeDocStatus(data.data.DocumentID).subscribe((res) => {
              this.isLoading = false;
              console.log(res);
              Swal.fire({
                  title: `Success`,
                  text: res.message,
                  type: 'success',
                  showConfirmButton:false,
                  timer:3000
                }).then(() => { 
                  this.CloseDialog(); 
                });
            });
          
          });
        } else {
          Swal.fire({
            title: data.error_code,
            text: data.message,
            type: "error",
            showConfirmButton:false,
            timer:3000
          });
        }
      });
  }
  onSubmit() {
    this.photographForm.controls['DocNumber'].clearValidators();
    this.photographForm.controls['selectSurveyNumber'].clearValidators();
    this.photographForm.controls['CaptchCode'].clearValidators();
    this.photographForm.controls['DocNumber'].updateValueAndValidity();
    this.photographForm.controls['selectSurveyNumber'].updateValueAndValidity();
    this.photographForm.controls['CaptchCode'].updateValueAndValidity();
    this.submited = true;
    if( this.otherdoc == true) {
      this.photographForm.controls['DocumentTypeId'].clearValidators();
      this.photographForm.controls['DocumentSubTypeId'].clearValidators();
      this.photographForm.controls['selectDocument'].clearValidators();
      this.photographForm.controls['DocumentTypeId'].updateValueAndValidity();
      this.photographForm.controls['DocumentSubTypeId'].updateValueAndValidity();
      this.photographForm.controls['selectDocument'].updateValueAndValidity();
      this.photographForm.controls.DocumentTypeId.setValue('');
      this.photographForm.controls.DocumentSubTypeId.setValue('');
      this.photographForm.controls.selectDocument.setValue('');
    } 
    if (this.photographForm.valid) {
      this.stateName = this.applicationDatadisplay.State
      // 1 is Property ID
      this.isLoading = true;
      // if (this.AllowMultiple) {
      if (this.photographForm.value.selectState) {
        // this.generalService.ownerInfo.emit(true);
        if(this.IsManualUpload == true) {
          this.generalService
          .ProcessDocumentUpload(
            this.prepareSave_Process_PropertyDoc(
              this.applicationData.AppID,
              this.stateName,
              this.photographForm.value.selectState.APIDocument
            )
          )
          .then((data) => {
            this.addMultiplePropertyDoc();
          })
          .catch((err) => {
            this.isLoading = false;
          });
        } else {
          this.addMultiplePropertyDoc();
        }
        setTimeout(() => {
          if (this.isLoading) {
            this.addMultiplePropertyDoc();
          }
        }, 100000);
      } else {
        this.addMultiplePropertyDoc();
      }
    } else{
      console.log(this.photographForm);
    }
  }
  Changedocument(e) {
    this.photographForm.controls.selectState.enable();
    this.photographForm.controls.selectState.setValue(null)
    this.selectedDocument = e.DocumentName;
    if (
      this.selectedDocument === "Govt. certified copy of latest 7/12" ||
      this.selectedDocument === "Property Card"
    ) {
      this.isFetchData = true;
    } else {
      this.isFetchData = false;
    }
    this.AllowMultiple = false;
    if (e.DocumentName === "Govt. certified copy of latest 7/12")
      this.AllowMultiple = true;

    this.ResetForm();
    if (e) {
      this.f.DocumentSubTypeId.setValue(e.DocumentSubType);
      this.f.DocumentTypeId.setValue(e.DocumentType);
    } else {
      this.f.DocumentSubTypeId.setValue(null);
      // this.f.DocumentTypeId.setValue(null);
    }
  }
  onChangeState(value) {
    let find = this.stateList.find((f) => f.APIDocument === value.APIDocument);
    if (find) {
      this.stateName = find.APIState;
    }
    if(this.IsManualUpload == false) {
      this.getDocURL(value.StateID, value.APIDocument);
    }
  }
  getDocURL(stateId, doctype) {
    this.isLoading = true;
    this.generalService
      .GetDocumentURL(stateId, doctype)
      .subscribe((res) => {
        this.docFields = res;
        this.requestURL = res.url;
        this.getDocument();
      });

  }
  getDocument() {
    let formData: FormData = new FormData();

    formData.append('app_id', this.applicationData.AppID);
    formData.append('state', this.applicationData.StateName);
    formData.append('document', this.docFields.document);
    const hasDistrict = 'District' in this.docFields;
    if (hasDistrict) {
      const distFieldname = this.docFields.District;
      formData.append('district', this.applicationDatadisplay[distFieldname]);
    }
    const hasHobli = 'Hobli' in this.docFields;
    if (hasHobli) {
      const hobliFieldname = this.docFields.Hobli;
      formData.append('hobli', this.applicationDatadisplay[hobliFieldname]);
    }
    const hastaluka = 'Taluka' in this.docFields;
    if (hastaluka) {
      const talukaFieldname = this.docFields.Taluka;
      formData.append('taluka', this.applicationDatadisplay[talukaFieldname]);
    }
    const hasVillage = 'Village' in this.docFields;
    if (hasVillage) {
      const villageFieldname = this.docFields.Village;
      formData.append('village', this.applicationDatadisplay[villageFieldname]);
    }
    this.generalService
      .downloadDocument(this.requestURL, formData)
      .subscribe((res): any => {
        this.isLoading = false;
        if (res.base64PDF) {
          const responseType = typeof(res.base64PDF)
          this.inputType = undefined;
          this.key = undefined;
          if (responseType === 'object') {
            this.base64.push(res.base64PDF.data);
          } else if(responseType === 'string') {
            if(res.base64PDF.includes('data')) {
              let str:string =`'data': '`;
              let str2: string = `'}`;
              this.base64Array = res.base64PDF.split(',');
              for(let i=0;i<this.base64Array.length;i++) {
                this.base64Array[i] = this.base64Array[i].replace(str,'"data": "');
                this.base64Array[i] = this.base64Array[i].replace(str2,'"}');
              }
              let base64PDF:any = this.base64Array.join(',');
              base64PDF = JSON.parse(base64PDF);
              this.base64Array = [];
              if(this.applicationData.StateName === "Karnataka") {
                this.base64Array = base64PDF;
              } else {
                this.base64Array.push(base64PDF);
              }
              for(let i=0;i<this.base64Array.length;i++) {
                this.base64.push(this.base64Array[i].data);
              }
            } else {
              if(this.applicationData.StateName === "Madhya Pradesh" || this.applicationData.StateName === "Chhattisgarh" || this.applicationData.StateName === "Tripura") {
                let str3:string =`[`;
                let str4: string = `]`;
                let str5: string = `b'`;
                let str6: string = `'`;
                this.base64Array = res.base64PDF.split(',');
                for(let i=0;i<this.base64Array.length;i++) {
                  this.base64Array[i] = this.base64Array[i].replace(str3,'');
                  this.base64Array[i] = this.base64Array[i].replace(str4,'');
                  this.base64Array[i] = this.base64Array[i].replace(str5,'');
                  this.base64Array[i] = this.base64Array[i].replace(str6,'');
                }
                for(let i=0;i<this.base64Array.length;i++) {
                  this.base64.push(this.base64Array[i]);
                }
              }
              else {
                this.base64.push(res.base64PDF);
                if(res.base64PDF.includes(`b'`)) {
                  this.base64[0] = this.base64[0].substring(2, this.base64[0].length - 1);
                }
              }
            } 
          }
          if (this.base64.length > 0) {
            this.pauseTimer();
            this.isLoading = false;
            if (this.isFetchData) {
              // this.valueChange.emit();
            }
            let file_array= [];
            for(let i=0;i<this.base64.length;i++) {
              const byteArray = new Uint8Array(
                atob(this.base64[i])
                  .split("")
                  .map(char => char.charCodeAt(0))
              );
              const file = new Blob([byteArray], { type: "application/pdf" });
              file_array.push(file);
            }
            
            let pdfName = this.docFields.document + ".pdf";
            this.onchange('Auto', file_array, pdfName);
          } else {
            this.removeDocument(false);
            Swal.fire({
              title: `Error`,
              text: `Your Requested Document Not Found`,
              type: "error",
              showConfirmButton:false,
              timer:3000
            });
            this.isLoading = false;
            this.pauseTimer();
          }
        }
        else {
          this.removeDocument(false);
          Swal.fire({
            title: `Error`,
            text: `Your Requested Document Not Found`,
            type: "error",
            showConfirmButton:false,
            timer:3000
          });
          this.isLoading = false;
          this.pauseTimer();
        }
      }, error => {
        this.removeDocument(false);
        Swal.fire({
          title: `Error`,
          text: `Your Requested Document Not Found`,
          type: "error",
          showConfirmButton:false,
          timer:3000
        });
        this.isLoading = false;
        this.pauseTimer();
      });
    this.startTimer();
  }
  _getSurveyNumbers() {
    // this.isTimeOut === false && 
    if (this.isCall == true) {
      this.isCall = false;
      this.generalService.getSurveyList(this.applicationData.AppID).subscribe((response: any) => {
        this.isCall = true;
        this.inputType = response.Type;
        this.key = response.key;
        if (response.key && response.key != 'Captcha'){
          if(this.currentKey != response.key) {
            if (this.inputType == 'dropdown') {
              this.surveyNumbers = response.value.split(',');
              this.SelectedsurveyNumber = this.surveyNumbers[0];
            }
            this.currentKey = response.key;
            this.pauseTimer();
            this.isLoading = false;
          }
        } else if (response.key && response.key === 'Captcha') {
          if (response.value != 'True' && response.value != 'False' && this.isCaptchaEntered == false) {
            this.imagePath = response.value;
            if(this.imagePath.charAt(0) == ',') {
              this.imagePath = this.imagePath.substring(1);
            }
            this.pauseTimer();
            this.isLoading = false;
          } else if (response.value == 'True') {
            this.pauseTimer();
            this.isWrongCaptcha = false;
          } else if (response.value == 'False' && this.isCaptchaEntered == true) {
            this.isWrongCaptcha = true;
            this.isCaptchaEntered = false;
            this.captchaValue = '';
            return;
          }
        }
      }, error => {
        this.isCall = true;
      });
    }
  }

  startTimer() {
    let limit;
    if (this.key == 'Captcha')
      limit = 1000;
    else
      limit = 2000;
    this.interval = setInterval(() => {
      // if (this.timeLeft > 0) {
      //   this.timeLeft--;
      // }
      // if (this.timeLeft === 0) {
      //   this.isTimeOut = true;
      // }
      this._getSurveyNumbers();
    }, limit);
  }
  _postRequest() {
    this.isLoading = true;
    this.pauseTimer();
    let formData: FormData = new FormData();
    if (this.key === 'Captcha') 
      this.isCaptchaEntered = true;
    formData.append('app_id', this.applicationData.AppID);
    formData.append('key', this.key);
    formData.append('value', `${this.key != 'Captcha' ? this.SelectedsurveyNumber.trim() : this.captchaValue}`);
    this.generalService.postRequestDocument(formData).subscribe((response: any) => {
      this.SelectedsurveyNumber = '';
      // this.timeLeft = 120;
      this.startTimer();
    });

  }
  pauseTimer() {
    clearInterval(this.interval);
  }

  ResetForm() {
    this.photographForm.controls.FileType.setValue("");
    this.photographForm.controls.FileName.setValue("");
    this.photographForm.controls.Description.setValue("");
    // this.photographForm.controls.uploadfile.setValue([]);
  }

  downloadPdf(docname: any) {
    this.base64.forEach( element => {
      const byteArray = new Uint8Array(
        atob(element)
          .split("")
          .map(char => char.charCodeAt(0))
      );
      const file = new Blob([byteArray], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      let pdfName = docname + ".pdf";
      const nav = (window.navigator as any);
      if (nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(file, pdfName);
      } else {
        let link = document.createElement("a");
        link.download = pdfName;
        link.target = "_blank";
        link.href = fileURL;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
      
  }

  removeDocument(type) {
    if ( (this.selectedDocument === "Govt. certified copy of latest 7/12" || this.selectedDocument === "Property Card") && type == true) 
      this.removeOwner.emit();
    this.base64 = [];
    this.inputType = undefined;
    this.surveyNumbers = undefined;
    this.selectedDocument = ''
    // this.timeLeft = 120;
    this.isCaptchaEntered = false;
    this.isWrongCaptcha = false;
    this.photographForm.reset();
    if(this.DocumentType !== null) {
      console.log(this.DocumentType);
      this.photographForm.controls.selectDocument.disable();
    }
    this.photographForm.controls.selectState.disable();
    this.photographForm.controls.CreatedBy.setValue(this.currentUser.UserID);
    this.photographForm.controls.UserID.setValue(this.currentUser.UserID);
    this.SelectedsurveyNumber = '';
    this.captchaValue = '';
    this.fileToUpload = [];   
    this.currentKey = undefined;
    this.IsManualUpload = undefined;
  }
  CloseDialog() {
    this.dialogRef.close(true);
  }
}
