import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { GeneralService } from 'src/app/services/general.service';
import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import readXlsxFile from 'read-excel-file';
import Swal from 'sweetalert2';
import { Workbook } from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss']
})
export class BulkUploadComponent implements OnInit {
  ResponseData: Array<any>;
  errorArray:any[] =[];
  statedata: Array<any>;
  file: Array<any>;
  stateId: number;
  stateName: any;
  isLoading: boolean;
  breadCrumbItems: Array<any>;
  permissionData: Array<any>;
  currentUser: any;
  form: FormGroup;
  StateBaseDocType : any;
  BaseDocumentType:number;
  submitted: boolean = false;
  constructor(private service: GeneralService, private datepipe: DatePipe, private router: Router, private fb: FormBuilder) {
    this.currentUser = this.service.getcurrentUser();
    this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' },
    { label: 'Bulk Upload Application', path: '/', active: true }];
  }

  ngOnInit() {
    this.StateBaseDocType = [
      {
        "ID": 1,
        "Name": "Rural",
      },
      {
        "ID": 2,
        "Name": "Urban",
      }
    ],
    this.form = this.fb.group({
      BaseDocumentType: new FormControl(null,Validators.required),
      StateID: new FormControl(null, Validators.required),
    })
    this.stateId = null;
    this.isLoading = true;
    this.service.statesList(this.currentUser.UserID).subscribe(res => {
      this.statedata = res.data;
      console.log('statelist',this.statedata);
      this.isLoading = false;
    })
    this.service.GetStatePermissionWise(this.currentUser.UserID).subscribe((res) => {
      this.permissionData = res.data;
      console.log("line==37",this.permissionData)


    })
    this.ResponseData = [];
    this.errorArray = [];
  }
  onchange(e) {
    if (this.file.length > 1) {
      Swal.fire({
        title: `Error`,
        text: `You Cannot Upload More Then 1 Excel`,
        type: 'error',
        showConfirmButton: false,
        timer:3000
      }).then(() => { this.file = [this.file[0]]; })
    }
    this.ResponseData = [];
    this.errorArray = [];
  }
  readFile() {
    this.ResponseData = [];
    this.errorArray = [];
    readXlsxFile(this.file[0]).then(async (rows) => {
      if (rows.length > 251) {
        Swal.fire({
          title: `Error`,
          text: `You Cannot Upload More Then 250 Applications`,
          type: 'error',
          showConfirmButton: false,
          timer:3000
        }).then(() => { this.file = [] })
      } else {
        for (let index = 1; index < rows.length; index++) {
          this.isLoading = true;
          await this.setDataandUpload(rows[index], index).then((data) => {
            this.ResponseData.push(data)
          }).catch((err) => {
            this.ResponseData.push(err)
          })
          this.isLoading = false;
        }
        Swal.fire({
          title: 'File Uploaded Successfully',
          text: 'File Uploaded Successfully , Want to see Log?',
          type: 'success',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          confirmButtonClass: 'btn btn-danger mt-2',
          cancelButtonClass: 'btn btn-success ml-2 mt-2',
          buttonsStyling: false,
          showConfirmButton: true,
          timer:3000
        }).then((result) => {
          if (result.value) {
            // this.router.navigate(["/loan/applications"]);

             return
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire({
              title: 'Upload Another Excel ?',
              text: 'Want To Upload Another Excel?',
              type: 'info',
              showCancelButton: true,
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              confirmButtonClass: 'btn btn-success mt-2',
              cancelButtonClass: 'btn btn-danger ml-2 mt-2',
              buttonsStyling: false,
              showConfirmButton: true,
              timer:3000
            }).then((result) => {
              if (result.value) {
                location.reload();
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                this.router.navigate(['loan/applications']);
              }
            });
          }
        });
        // new UploadData(rows[index], this.currentUser.UserID, this.service).getdata()
        // }
      }
    })
}


  onSubmit() {
    console.log('statid',this.stateId,this.BaseDocumentType);
    console.log('file',this.file);
    if (this.file && this.file.length === 1) {
      const filetype = this.file[0].type;
      let formData:FormData = new FormData();
      formData.append('files', this.file[0],this.file[0].name);
      formData.append('created_by',this.currentUser.UserID);
      this.submitted = true;
      let message = '';
      if(this.stateId === null || this.BaseDocumentType === null){
        // message =  `Please Select State and Base Document Type`
        // Swal.fire({
        //   title: `Invalid Form`,
        //   text: message,
        //   type: 'error',
        //   showConfirmButton: false,
        //   timer:3000
        // })
        return;
      }
      else if (filetype.toLowerCase() !== 'application/vnd.ms-excel' && filetype.toLowerCase() !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        Swal.fire({
          title: `Error`,
          text: `${this.file[0].name} File Are Not Supported`,
          type: 'error',
          showConfirmButton: false,
          timer:3000
        }).then(() => { this.file = [] })
      } else {
       
        let url= ''
        console.log("state id",this.stateId);
        formData.append('StateID', this.stateId.toString());

        url = this.getStateUrlAndFormData(this.stateId);
        console.log('url',typeof(url));
        if(url != undefined && url !=''){
          this.isLoading = true;
          this.service.uploadExcel(url,formData).subscribe((res:any) =>
          {
            console.log(res);
            this.isLoading = false;
              this.errorArray = res.data;
              if(res.message === 'Excel column header Mismatch, Kindly refer Sample Excel' ||
              res.message === 'Excel file is empty. Please provide data.'||
              res.message === 'Excel file contains rows with missing data. Please ensure all fields are filled.'||
              res.message === 'Excel column header mismatch. Kindly refer to the sample Excel file.'
         ){
                Swal.fire({
                  title: 'Failed',
                  text: res.message,
                  type: 'error',
                  showConfirmButton: false,
                  timer:3000
                });
                return;
              }
              if(res.message === ''){
                Swal.fire({
                  title: 'Failed',
                  text: res.message,
                  type: 'error',
                  showConfirmButton: false,
                  timer:3000
                });
                return;
              }
              else{
                Swal.fire({
                  title: 'Success',
                  text: res.message,
                  type: 'success',
                  showConfirmButton: false,
                  timer:3000
                });
              }
              
            }
          )
        }
        else{
          Swal.fire({
            title: `Info`,
            text: `Bulk Uplaod Functionality is not Developed for this state`,
            type: 'info',
            showConfirmButton: false,
            timer:5000
          })
        }
        // this.readFile();
      }
    } else {
      let message = ''
      this.submitted = true;
      console.log("state id",this.stateId);
      if(this.stateId === null || this.BaseDocumentType == null){
        // message = 'Please Select State,File and Base Document Type';
        return
      }
      else {
        message = 'Please Select File'
      }
      Swal.fire({
        title: `Invalid Form`,
        text: message,
        type: 'error',
        showConfirmButton: false,
        timer:3000
      })
    }

  }

  getStateUrlAndFormData(stateId: number):any {
    let url: string;
    if(this.BaseDocumentType === 1){
      switch (stateId) {
        case 25: //telengana
         url='_tn/xl/';
        break
        case 1:
        case 28:
          url = 'ap_tn/xl';
          break;
        case 7:
        case 15:
        case 14: //MP
          url = 'GJ_MH/xl';
          break;
        case 24:
          url = 'Tamil_Nadu/xl';
          break;
        case 5:
          url = 'cg/xl';
          break;
        case 12:
          url = 'KTK/xl';
          break;
        case 9:
        case 21:
          url = 'HP/xl';
          break;
        case 27:
          url = 'up/xl';
          break;
        case 22:
          url = 'rj/xl';
          break;
        case 6:
          url = 'goa/xl';
          break;
        case 4:
        url ='br/xl';
          break;
        case 11:
            url ='jr/xl';
            break;
        case 8:
          url = 'HR/xl';
          break;
        default:
          // Handle other cases or invalid stateId here
          break;
      }
    }
    else{
      switch (stateId) {
        case 7:
          url = 'ur/Gj/xl';
          break;
        case 6:
          url = 'ur/GOA/xl';
          break;
        case 14:
          url = 'ur/MP/xl';
          break;
        case 15:
          url = 'ur/MH/xl';
          break;
        case 24:
            url = 'ur/TN/xl';
            break
        case 25:
            url = 'ur/TG/xl';
            break
        case 1:
            url = 'ur/AP/xl';
            break
        default:
          url = ''
          break;
      }
    }
      return url;
  }
  

  setDataandUpload(row, index) {
    index = index + 1;
    return new Promise((resolve, reject) => {
      if (row.every(x => x == null)) {
        reject({ index, status: 'Error', message: `${index} Row is Empty` })
      } else {
        this.service.GetAllLoanID(row[7], row[8], row[9], row[11], 'null', row[20], row[12], row[10]).subscribe((res) => {
          if (this.permissionData.some(x => x.StateID == res.data.StateID)) {
            let response = res.data;
            let mendatoryCollumn = ['ApplicantFirstName', 'ApplicantLastName', 'ApplicationNo', 'StateID', 'DistrictID', 'TalukaID', 'VillageID','PropertyType','Type Of Loan','Loan Amount ','Is There Known Lien on Property',
              'LoanPropertyTypeID', 'PropertyAddress', 'KhataNo', 'TypeOfLoan', 'LoanAmount','BankID','Is Applicant/Owner Same?','Owner Name' ,'State','District','Taluka','Village',
              
              ]
            let temp = {
              ApplicantFirstName: row[0] ? row[0] : '',
              ApplicantLastName: row[1] ? row[1] : '',
              ApplicationNo: row[2] ? row[2].toString() : '',
              MobileNo: row[3] ? row[3].toString() : '',
              Email: row[4] ? row[4] : '',
              IsOwnerSame: row[5] ? row[5] == 'Yes' ? 'true' : 'false' : 'false',
              PropertyOwners: [],
              StateID: response.StateID ? response.StateID : '',
              DistrictID: response.DistrictID ? response.DistrictID : '',
              TalukaID: response.TalukaID ? response.TalukaID : '',
              VillageID: response.VillageID ? response.VillageID : '',
              HobliID: response.HobliID ? response.HobliID : '',
              LoanPropertyTypeID: response.LoanPropertyTypeID ? response.LoanPropertyTypeID : '',
              PropertyAddress: row[13] ? row[13] : '',
              SurveyNo: row[14] ? row[14].toString() : '',
              HissaNo: row[15] ? row[15].toString() : '',
              CitySurveyNumber: row[16] ? row[16].toString() : '',
              TpNo: row[17] ? row[17].toString() : '',
              FpNo: row[18] ? row[18].toString() : '',
              Khata_No: row[19] ? row[19] : '',
              TypeOfLoan: response.TypeOfLoan ? response.TypeOfLoan : '',
              LoanAmount: row[21] ? row[21] : '',
              IsLien: row[22] ? row[22] == 'Yes' ? 'true' : 'false' : 'false',
              LienPersonName: row[23] ? row[23] : '',
              LienFrom: row[24] ? row[24] : '',
              LienAmount: row[25] ? row[25] : '',
              LienDate: null,
              CreatedBy: this.currentUser.UserID
            };

            if (typeof (row[26]) === "number") {
              temp.LienDate = row[26] ? this.datepipe.transform(new Date(Math.round((row[26] - 25569) * 86400 * 1000)), 'yyyy-MM-dd') : '';
            } else if (typeof (row[26]) === "string") {
              temp.LienDate = row[26] ? moment(row[26]).format('YYYY-MM-DD') : '';
            }
            let ownernames = row[6].split(',');
            ownernames.forEach(element => {
              let owner = { OwnerName: element }
              temp.PropertyOwners.push(owner);
            });
            if (response.StateID == 12 && response.HobliID == null) {
              return reject({ index, status: 'Error', message: `Hobli is Required When State is Karnataka` })
            } 
            const emptyobject = Object.keys(temp).filter(k => !temp[k]);
            if (emptyobject.length > 0) {
              const err = emptyobject.filter(k => mendatoryCollumn.includes(k))
              if (err.length > 0) {
                reject({ index, status: 'Error', message: `${err.join(' ,')} Required` })
              } else {
                this.service.AddLoanApplication(temp).subscribe((res) => {
                  if (res.status === 200) {
                    resolve({ index, status: 'Success', message: 'Application Added Successfully' })
                  }
                  else {
                    reject({ index, status: 'Error', message: res.message })
                  }
                })
              }
            } else {
              this.service.AddLoanApplication(temp).subscribe((res) => {
                if (res.status === 200) {
                  resolve({ index, status: 'Success', message: 'Application Added Successfully' })
                }
                else {
                  reject({ index, status: 'Error', message: res.message })
                }
              })
            }
          } else {
            reject({ index, status: 'Error', message: `Not Permitted To Add Application In ${row[7]} State` })
          }
        })
      }
    })

  }

  exportToExcel1()
  {
    if (!this.stateId) {
      return
    }
    console.log('form value');
    console.log('stateid',this.stateId);
    console.log('this.base doc type',this.BaseDocumentType);
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    if(this.BaseDocumentType === 1){
      link.href = 'assets/files/'+this.stateId+'.xlsx';
      link.download = this.stateId+'.xlsx';
    }
    else{
      link.href = 'assets/files/'+this.stateId+'_urban'+'.xlsx';
      link.download = this.stateId+'_urban'+'.xlsx';
    }
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  exportToExcel() {
    if (!this.stateId) {
      return
    }
    this.isLoading = true;
    // this.dataService.Getdata('api/area/list/Distict/:DistrictID')

    let workbook = new Workbook();
    let fname = `Loan_Application_Info`;

    this.service.GetLoanPropertyTypes()
      .subscribe(propertytypes => {

        let worksheet = workbook.addWorksheet("Property Types");

        let sheet_Key = Object.keys(propertytypes.data[0]);

        var row = worksheet.addRow(['Property ID', 'Property Type']);

        propertytypes.data.forEach((element, index) => {

          var row = worksheet.getRow(1 + (index + 1));

          sheet_Key.forEach((ele, i) => {

            row.getCell(i + 1).value = element[ele];
            if (index + 1 == propertytypes.data.length && i + 1 == sheet_Key.length) {

              this.service.GetLoanTypes()
                .subscribe(loantypes => {

                  let worksheet = workbook.addWorksheet("Loan Types");

                  let sheet_Key = Object.keys(propertytypes.data[0]);

                  var row = worksheet.addRow(['Loan ID', 'Loan Type']);

                  loantypes.data.forEach((element, index) => {

                    var row = worksheet.getRow(1 + (index + 1));

                    sheet_Key.forEach((ele, i) => {

                      row.getCell(i + 1).value = element[ele];

                      if (index + 1 == loantypes.data.length && i + 1 == sheet_Key.length) {

                        this.service.GetPVRBankList()
                          .subscribe(banklist => {

                            let worksheet = workbook.addWorksheet("Bank List");

                            let sheet_Key = Object.keys(banklist.data[0]);

                            var row = worksheet.addRow(['Bank ID', 'Bank Name']);

                            banklist.data.forEach((element, index) => {

                              var row = worksheet.getRow(1 + (index + 1));

                              sheet_Key.forEach((ele, i) => {

                                row.getCell(i + 1).value = element[ele];

                                if (index + 1 == banklist.data.length && i + 1 == sheet_Key.length) {

                                  this.service.GetExcelExportData(this.stateId)
                                    .subscribe(data => {
                                      console.log(data);

                                      let worksheet = workbook.addWorksheet("District Wise List");

                                      let sheet_Key = Object.keys(data.data[0]);

                                      var row = worksheet.addRow(sheet_Key);

                                      if (data.data.length == 0) {
                                        workbook.xlsx.writeBuffer().then((data) => {
                                          this.isLoading = false;
                                          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                                          fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                                        });
                                      }
                                      else {
                                        data.data.forEach((element, index) => {

                                          var row = worksheet.getRow(1 + (index + 1));

                                          sheet_Key.forEach((ele, i) => {

                                            row.getCell(i + 1).value = element[ele];

                                            if (index + 1 == data.data.length && i + 1 == sheet_Key.length) {

                                              workbook.xlsx.writeBuffer().then((data) => {
                                                this.isLoading = false;
                                                let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                                                fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
                                              });

                                            }
                                          })

                                        });
                                      }





                                    })

                                }
                              })

                            });


                          })

                      }
                    })

                  });


                })

            }
          })

        });


      })
  }
  reset(){
    this.errorArray = [];
    this.file = [];
    this.stateId = null;
    this.BaseDocumentType = null;
    this.submitted = false
  }
}
// export class UploadData {
//   data: {
//     ApplicantFirstName: String,
//     ApplicantLastName: String,
//     ApplicationNo: Number,
//     MobileNo: Number,
//     Email: String,
//     IsOwnerSame: String,
//     PropertyOwners: Array<{ OwnerName: String }>,
//     StateID: Number,
//     DistrictID: Number,
//     TalukaID: Number,
//     VillageID: Number,
//     LoanPropertyTypeID: Number,
//     PropertyAddress: String,
//     SurveyNo: String,
//     CitySurveyNumber: String,
//     TpNo: String,
//     FpNo: String,
//     BankID: Number,
//     BranchCode: String,
//     TypeOfLoan: Number,
//     LoanAmount: Number,
//     IsLien: String,
//     LienPersonName: String,
//     LienFrom: String,
//     LienAmount: Number,
//     LienDate: String,
//     CreatedBy: Number
//   }
//   constructor(row, userID, private service: GeneralService) {
//     this.service.GetAllLoanID(row[7], row[8], row[9], row[10], row[17], row[19], row[11]).subscribe((res) => {
//       let response = res.data;
//       this.data = {
//         ApplicantFirstName: row[0] ? row[0] : '',
//         ApplicantLastName: row[1] ? row[1] : '',
//         ApplicationNo: row[2] ? row[2] : '',
//         MobileNo: row[3] ? row[3] : '',
//         Email: row[4] ? row[4] : '',
//         IsOwnerSame: row[5] ? row[5] : '',
//         PropertyOwners: [],
//         StateID: response.StateID ? response.StateID : null,
//         DistrictID: response.DistrictID ? response.DistrictID : null,
//         TalukaID: response.TalukaID ? response.TalukaID : null,
//         VillageID: response.VillageID ? response.VillageID : null,
//         LoanPropertyTypeID: response.LoanPropertyTypeID ? response.LoanPropertyTypeID : null,
//         PropertyAddress: row[12] ? row[12] : '',
//         SurveyNo: row[13] ? row[13] : '',
//         CitySurveyNumber: row[14] ? row[14] : '',
//         TpNo: row[15] ? row[15] : '',
//         FpNo: row[16] ? row[16] : '',
//         BankID: response.BankID ? response.BankID : null,
//         BranchCode: row[18] ? row[18] : '',
//         TypeOfLoan: response.TypeOfLoan ? response.TypeOfLoan : null,
//         LoanAmount: row[20] ? row[20] : '',
//         IsLien: row[21] ? row[21] : '',
//         LienPersonName: row[22] ? row[22] : '',
//         LienFrom: row[23] ? row[23] : '',
//         LienAmount: row[24] ? row[24] : '',
//         LienDate: row[25] ? row[25] : '',
//         CreatedBy: userID
//       };
//       let ownernames = row[6].split(',');
//       ownernames.forEach(element => {
//         let owner = { OwnerName: element }
//         this.data.PropertyOwners.push(owner);
//       });
//     })
//   }
//   async getdata() {

//   }

// }
