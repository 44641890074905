import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { GeneralService } from 'src/app/services/general.service';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;

}
@Component({
  selector: 'app-view-gpp',
  templateUrl: './view-gpp.component.html',
  styleUrls: ['./view-gpp.component.scss']
})
export class ViewGppComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  AppID: any;
  state: string;
  FirstName:string;
  isLoading:boolean = false;
  dtElement: DataTableDirective | undefined;
  dtTrigger = new Subject<any>();
  dtOptions1: DataTables.Settings = {};
  people = [
    {
      date: 'Base GPP',
      Period: '-',
      month: 'base7',
      state: '7'
    },{
    date: '1st Jan 2023',
    Period: '1st Jan - 31st Jan',
    month: 'jan7',
    state: '7'
  }, {
    date: '1st Feb 2023',
    Period: '1st Feb - 28th Feb',
    month: 'feb7',
    state: '7'
  },
  {
    date: 'Base GPP',
    Period: '-',
    month: 'base12',
    state: '12'
  },
  {
    date: '1st Aug 2022',
    Period: '1st Aug - 30th Aug',
    month: 'jan15',
    state: '15'
  },
  {
    date: '1st Jan 2023',
    Period: '1st Jan - 31st Jan',
    month: 'jan12',
    state: '12'
  },
  {
    date: 'Base GPP',
    Period: '-',
    month: 'base15',
    state: '15'
  },
  {
    date: 'Base GPP',
    Period: '-',
    month: 'base27',
    state: '27'
  },
  {
    date: 'Base GPP',
    Period: '-',
    month: 'base27',
    state: '27'
  }];
  
  findNonAdults(people: any[]): any[] {
    this.state = this.Route.snapshot.params.state;
    if(this.state=='7')
    {
    return people.filter(p => p.state == 7);
    }
    if(this.state=='12')
    {
    return people.filter(p => p.state == 12);
    }
    if(this.state=='15')
    {
    return people.filter(p => p.state == 15);
    }
    if(this.state=='26')
    {
    return people.filter(p => p.state == 26);
    }
    if(this.state=='27')
    {
    return people.filter(p => p.state == 27);
    }
  }

  constructor(
    private service: GeneralService, 
    private datepipe: DatePipe,  
    private Route: ActivatedRoute, 
    private router: Router, 
    private http: HttpClient,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.AppID = this.Route.snapshot.params.AppID;
    this.state = this.Route.snapshot.params.state;
    this.service.GetApplicationInformation(this.Route.snapshot.params.AppID).subscribe((Response) => {
        var state = Response.data[0].StateID;
        this.FirstName = Response.data[0].FirstName;
        this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'Applications', path: '/loan/post-mortgage-applications' },
        {label: `${Response.data[0].FirstName}` ,
         path: `/loan/post-mortgage-dashboard/${this.Route.snapshot.params.AppID}`}
        , { label: 'View GPP', path: '/', active: true }];
      
      });
      this.dtOptions1 = {
        // pagingType: "simple_numbers",
        pageLength: 10,
        // serverSide: true,
        paging: false,
        searching: false,
        ordering: false,
        // processing: true,
        ajax: (dataTablesParameters: any, callback) => {
          this.isLoading = true;
          this.http
            .get<DataTablesResponse>(
              this.service.GetBaseUrl() +
              `getlist/gpp/${this.AppID}`,               
              {}
            )
            .subscribe((resp: any) => {
              console.log("line==199",resp)
              this.isLoading = false;
              // this.applications = resp.data;
              callback({
                // recordsTotal: resp.data.length,
                // recordsFiltered: resp.data.length,
                data: resp.data,
              });
            });
        },
        columns: [
          {
            title: 'Sr.No.',
            data: null,
            render: (data, type, row, meta) => {
              return meta.row + 1;
            },
          },
          {
            title: "Date",
            name: "Date",
            data: "Date",
            render:(data, type, row)=>{
              if(data === null || data === undefined || data === 'null'){
                return '--'
              }
              else{
                return this.datepipe.transform(data, 'MMM dd,yyyy');
              }}
          },
          {
            title: "Period",
            name: "TimePeriod",
            data: "TimePeriod",
            render:(data, type, row)=>{
              if(data === null || data === undefined || data === 'null'){
                return '--'
              }
              else{
                return data ? data : '--'
              }}
          },
          {
            title: "Summary",
            name: "summary",
            data: "summary",
            render:(data, type, row)=>{
              if(data === null || data === undefined || data === 'null'){
                return '--'
              }
              else{
                return data ? data : '--'
              }}
          },
          
          {
            title: "Action",
            data: null,
            render: function (data, type, row) {
              if(row.GPP_URL != ''){
                return `<a class="text-primary" title="View Document"
              GPP_URL = "${row.GPP_URL}"><i class=" m-1 ml-3 mdi mdi-eye font-18 text-secondary" GPP_URL = "${row.GPP_URL}"  
               }' aria-hidden="false"></i></a>`;
              }
              
              
            }
          },
        ],
        order: [[0, "desc"]],
        columnDefs: [
          { targets: 0, visible: true },
          { targets: 3, orderable: false },
        ],
      };

  
}  
 openGPPDocument(month,state) {
  console.log(state)
}
rerender(): void {
  this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    // Destroy the table first
    dtInstance.destroy();
    // Call the dtTrigger to rerender again
    this.dtTrigger.next();
  });
} 
ngAfterViewInit(): void {
  this.dtTrigger.next();
  this.renderer.listen('document', 'click', (event) => {
    if (event.target.hasAttribute('GPP_URL')) {
      this.router.navigate(['/loan/view-gpp-document'],{state:{GPP_URL: event.target.getAttribute('GPP_URL'),firstname:this.FirstName, AppID: this.AppID, state: this.state}});
    }
  });

}

}