import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FN_SubInput } from '../add-application/fn_subInput.validator';
import Swal from 'sweetalert2';
import { GeneralService } from 'src/app/services/general.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-add-pos-application',
  templateUrl: './add-pos-application.component.html',
  styleUrls: ['./add-pos-application.component.scss']
})
export class AddPosApplicationComponent implements OnInit {
  breadCrumbItems:any;
  loan: FormGroup;
  currentUser:any;
  isLoading: boolean;
  StateList:any[]=[];
  baseDocType: any;
  fieldsData: any[];
  DataList: any = {};
  URL: string;
  dynamicFields = [];
  stateID: any;
  StateBaseDocType: any;
  propertyDetails: any;
  submitted:boolean;
  areas: any[];
  DistrictList: any[];
  myValue_3: any;
  constructor(private Fb: FormBuilder, private service: GeneralService) {
    this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'Applications', path: '/loan/applications' },
    { label: 'Add New Application', path: '/loan/addapplication', active: true }];
   }

  ngOnInit() {
    this.submitted = false;
    this.currentUser = this.service.getcurrentUser();
    this.fetchstatelist();
    this.loan = this.Fb.group({
      PropertyOwners: this.Fb.array([
        this.initOwner()
      ]),
      BaseDocumentType: new FormControl(null),
      PropertyType: new FormControl(null, Validators.required),
      ApplicationNo: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      // ApplicantFirstName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]),
      // ApplicantLastName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]),
      CreatedBy: new FormControl(this.currentUser.UserID),
      StateID: new FormControl(21, Validators.required),
    })
    this.ChangeState(21);
  }
  ChangeState(e) {

    this.getStateBaseDocTypeByStateID(e);
    this.stateID = e;
    this.loan.controls.BaseDocumentType.setValue(null);
    this.dynamicFields.forEach(element => {
      this.loan.removeControl(element);
    });
    // this.DistrictList = [];
    this.fieldsData = [];
    // this.loan.controls.VillageID.setValue(null);
    // this.loan.controls.DistrictID.setValue(null);
    // this.loan.controls.TalukaID.setValue(null);
    // this.loan.controls.taluka.setValue('');
  }


  getStateBaseDocTypeByStateID(id) {
    this.isLoading = true;
    this.service.getStateBaseDocTypeByStateID(id).subscribe((res: any) => {
      // if (this.propertyDetails != undefined) {
      //   this.loan.controls.BaseDocumentType.setValue(Number(this.propertyDetails.BaseDocumentType));
      //   this.ChangeBaseDocType(this.propertyDetails.BaseDocumentType);
      // }
      this.isLoading = false;
      this.StateBaseDocType = res.data;
    });
  }
  
  ChangeBaseDocType(data) {
    this.baseDocType = data;
    this.areas = [];
    if(this.StateBaseDocType != undefined) {
      this.loan.controls.PropertyType.setValue(this.StateBaseDocType.find(x => x.ID === data).Name)
    }
    this.dynamicFields.forEach(element => {
      this.loan.removeControl(element);
    });
    if (data !== undefined) {
      let param = new URLSearchParams();
      param.set('StateID', this.stateID);
      param.set('Service_subtype_id', '1');
      param.set('StateBaseDocTypeID', this.baseDocType);
      param.set('DisplayLevel', '1');
      this.isLoading = true;
      this.fieldsData.forEach(element => {
        this.loan.removeControl(`${element.DBColumnName}`); 
      });
      this.service.getDynamicFields(param.toString()).subscribe((res: any) => {
        this.isLoading = false;
        this.fieldsData = res.data;
        this.fieldsData.forEach(element => {
          this.dynamicFields.push(element.DBColumnName);
          if (element.FieldType === 'Text' && element.ValidationFunctionName != '') {
            this.loan.addControl(`${element.DBColumnName}`, this.Fb.control(null, this[element.ValidationFunctionName]()));
          } else if ((element.FieldType === 'Dropdown' && element.APIName != '' && element.IsDependantField != true)) {
            this.loan.addControl(`${element.DBColumnName}`, this.Fb.control(null));
            this.URL = element.APIName + '/' + this.stateID;
            this.isLoading = true;
            this.service.dynamicData(this.URL).pipe(first()).subscribe((data) => {
              this.URL = '';
              this.isLoading = false;
              if (data.error) {
                Swal.fire({
                  title: data.error_code,
                  text: data.message,
                  type: 'error',
                  showConfirmButton: false,
                  timer: 3000
                });
                return;
              } else {
                this.DataList[element.DBColumnName] = data.data;
              }
            });
          } else {
            this.loan.addControl(`${element.DBColumnName}`, this.Fb.control(null));
          }
          console.log(this.loan);
        });
        this.fieldsData.forEach(element => {
          if (this.propertyDetails != undefined && element.FieldType === 'Dropdown' && element.IsDependantField == true) {
            let index = this.fieldsData.findIndex(x => x.DependentFieldName === element.FieldName);
            if(index != -1 && index != null) {
              this.onChangesDynamicField(index, this.propertyDetails[this.fieldsData[index].DBColumnName]);
            }
          }
          this.loan.controls[element.DBColumnName].setValue(this.propertyDetails[element.DBColumnName]);
        });
      });
    } else {
      this.DistrictList = [];
      this.fieldsData = [];
    }
    // this.loan.controls.VillageID.setValue(null);
    // this.loan.controls.DistrictID.setValue(null);
    // this.loan.controls.TalukaID.setValue(null);
    // this.loan.controls.taluka.setValue('');
  }
  removeWhitespace_3(value: any) {
    this.myValue_3 = this.myValue_3.replace(/\s/g, '');
  }
  
  onChangesDynamicField(index, id) {
    this.clearFields(index);
    if (id !== undefined) {
      this.fieldsData.forEach(element => {
        if (this.fieldsData[index].DependentFieldName === element.FieldName) {
          this.URL = element.APIName + '/' + id;
          if (this.URL != '') {
            this.isLoading = true;
            this.service.dynamicData(this.URL).pipe(first()).subscribe((data) => {
              this.URL = '';
              this.isLoading = false;
              if (data.error) {
                Swal.fire({
                  title: data.error_code,
                  text: data.message,
                  type: 'error',
                  showConfirmButton: false,
                  timer: 3000
                });
                return;
              } else {
                this.DataList[element.DBColumnName] = data.data;
              }
            });
          }
        }
      });
    }

  }
  clearFields(i) {
    for (let index = 0; index < this.fieldsData.length; index++) {
      if (this.fieldsData[i].DependentFieldName != '' && this.fieldsData[i].DependentFieldName === this.fieldsData[index].FieldName) {
        this.loan.controls[`${this.fieldsData[index].DBColumnName}`].setValue(null);
        this.clearFields(index);
      }
    }
  }


  isOwnerNameDuplicate(index: number, owners: FormArray, ownerName: string): boolean {
    for (let i = 0; i < index; i++) {
      if (owners.at(i).value.OwnerName === ownerName) {
        return true;
      }
    }
    return false;
  }
  removeOwner(i: number) {
    const control = this.f.PropertyOwners as FormArray;
    control.removeAt(i);
  }
  addOwner() {
    const control = this.f.PropertyOwners as FormArray;
    control.push(this.initOwner());
  }
  isOwnerNameEmpty(index: number): boolean {
    const ownerNameControl = this.loan.controls.PropertyOwners['controls'][index].get('OwnerName');
    return !ownerNameControl.value || ownerNameControl.value.trim() === '';
  }

  isOwnerNameTouched(index: number): boolean {
    const ownerNameControl = this.loan.controls.PropertyOwners['controls'][index].get('OwnerName');
    return ownerNameControl.touched;
  }
  initOwner(i?) {
    if (i && i !== undefined) {
      return this.Fb.group({
        OwnerName: new FormControl(i.OwnerName, Validators.required),
      });
    } else {
      return this.Fb.group({
        OwnerName: new FormControl('', Validators.required)
      });
    }
    
  }
  FN_SubInput() {
    return FN_SubInput();
  }
  get f() { return this.loan.controls; }

  fetchstatelist() {
    this.isLoading = true;
    this.service.GetStatePermissionWise(this.currentUser.UserID)
      .pipe(first())
      .subscribe(
        data => {
          if (data.error) {
            this.isLoading = false;
            Swal.fire({
              title: data.error_code,
              text: data.message,
              type: 'error',
              showConfirmButton: false,
              timer: 3000
            });
            return;
          } else {
            this.isLoading = false;
            this.StateList = data.data;
            // console.log("statelist", this.StateList);
          }
        });
    // this.f.DistrictID.disable();
  }

  save(){
    console.log("ownernaeme",this.loan.value);
    let owners = []
    this.loan.controls.PropertyOwners['controls'].forEach((i, index) => {
      owners.push(this.loan.controls.PropertyOwners['controls'][index].getRawValue().OwnerName);
    })
    let data = this.loan.value;
    data['PropertyOwners'] = owners;
    // let data = this.loan.value;
    // if (this.loan.valid) {
    //   // this.f.control.value.trim().length === 0;
    //   this.isLoading = true;
    //   this.service.AddLoanApplication(data).subscribe((res) => {
    //     this.isLoading = false;
    //     if (res.error) {
    //       Swal.fire({
    //         title: res.error_code,
    //         text: res.message,
    //         type: 'error',
    //         showConfirmButton: false,
    //         timer: 3000
    //       });
    //       return;
    //     }
    //     else {
    //       Swal.fire({
    //         title: 'Success',
    //         text: 'Application Added Successfully',
    //         type: 'success',
    //         showConfirmButton: false,
    //         timer: 3000
    //       }).then(() => {
    //         // this.router.navigate(['/loan/applications']);
    //       });
    //     }
    //     this.isLoading = false;
    //   });
    // } else {
    //   Swal.fire({
    //     title: 'Invalid',
    //     text: 'Invalid Form Data',
    //     type: 'error',
    //     showConfirmButton: false,
    //     timer: 3000
    //   });
    // }
  }

}
