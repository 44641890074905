import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { ImageModalComponent } from '../image-modal/image-modal.component';
import { ViewDocumentDialogComponent } from '../view-document-dialog/view-document-dialog.component';
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { UploadLawyerDocumentsComponent } from '../upload-lawyer-documents/upload-lawyer-documents.component';
import { AdminUploadLawyerComponent } from '../admin-upload-lawyer/admin-upload-lawyer.component';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-view-pvr',
  templateUrl: './view-pvr.component.html',
  styleUrls: ['./view-pvr.component.scss']
})

export class ViewPvrComponent implements OnInit {

  breadCrumbItems: Array<{}>;
  AppID: any;
  applicationData: any;
  appData:any;
  dtOptions1: DataTables.Settings = {};
  dtOptions: DataTables.Settings = {};
  fileUrl:string;
  documentData: any
  propertyDocumentData: any;
  loaded: boolean;
  totaldocument = 0;
  pending = 0;
  received = 0;
  applicationStatus:string;  
  revenueRadialChart: any;
  today = new Date();
  appID: number;
  ApplicationID:any;
  responseData:any[];
  submitted: boolean;
  isLoading: boolean;
  PVRStatusList = ["Verified", "Provisionally Verified", "Not Verified"];
  PVRRiskMeterList = ["Excellent", "Good", "Average", "Poor"];
  PVRForm: FormGroup;
  PropertyForm:FormGroup;
  FinalForm:any
  currentUser: any;
  ownership: any;
  property: any;
  encumbrance: any;
  encumbranceFound: any;
  government: any;
  survey:any
  test1:any;
  governmentFound: any;
  regexStr = "^[0-9]*$";
  NumberOfOwners: any
  isDocUploaded: boolean = false;
  encumbranceRemarks: any;
  propertyText1: any;
  propertyText2: any;
  interval: any;
  datalength: any;
  isDataAdded: any = false;
  converted: any = false;
  isNan: any = false;
  ownerName: any;
  inputValue1:string =''
  inputValue2: string =''
  propertyRemarksArray: string;
  ownerRemarksArray: string;
  pertaining: any;
  defaultUnit: any = '';
  selectedUnit: any = null;
  aipvr_id: any;
  is_litigation: string;
  is_change_survey:string ='';
  is_change_in_landOwnership:string;
  is_change_in_Area:string;
  is_cersai: string;
  is_bankCharge_available:string;
  other_charge_available:string;
  other_charge_type:string;
  propertyText3: string;
  firstName: any;
  lastName: any;
  StateName:string;
  data: any[] = [];
  APPID: any;
  DocumentID: any;
  PropertyID: any;
  owners: any[] =[];
  TotalLandSize: any;
  survey_checkbox:any;
  is_Document_uploaded:any;
  risk_category:string
  Prioritization:string ='' ;
  reason_code:string;
  statusValue:any;
  is_totalExcent_greater:boolean = false;
  totalExtent:any;
  stateID:Number;
  scenarioMap: Map<string, { risk_category: string, reason_code: string }> = new Map([
    ['H1', { risk_category: 'High', reason_code: 'No Land- Cases(LNDSLTFR1' }],
    ['H2', { risk_category: 'High', reason_code: 'Part Land Sale/transfer-Collateral <50% (LNDSLTFR2)' }],
    ['H3', { risk_category: 'High', reason_code: 'Part Land Sale/transfer-Collateral >=50% to 100% (LNDSLTFR3)' }],
    ['H4', { risk_category: 'Medium', reason_code: 'Part Land Sale/transfer-Collateral>100 to 150% (LNDSLTFR4)' }],
    ['H7', { risk_category: 'Medium', reason_code: 'Part Land Sale/transfer-Collateral>150% (LNDSLTFR5)' }],
    ['H6', { risk_category: 'High', reason_code: 'Other Financier First Charge-bank (DBLEFIN2)' }],
    ['M1', { risk_category: 'Medium', reason_code: 'Land in name of other family members (LNDSLTFR6)' }],
    ['M2', { risk_category: 'Medium', reason_code: 'Other Financier First Charge-PACS(>1Lac)-DBLEFIN4' }],
    ['M3', { risk_category: 'Medium', reason_code: 'Other Financier-First Charge-PACS (Upto 1 lac) (DBLEFIN3)' }],
    ['M4', { risk_category: 'Medium', reason_code: 'Land related – Govt acquired / Chakbandi / Resurvey / Promulgation / Court order' }],
    ['L2', { risk_category: 'Low', reason_code: 'HDFC Charge is Missing/ Charge of lesser value created (INSUFSEC)' }],
    ['L3', { risk_category: 'Low', reason_code: 'Other Financier Second charge (DBLEFIN1)' }],
  ]);
  constructor(private service: GeneralService, private dialog : MatDialog,private Route: ActivatedRoute, private datepipe: DatePipe,private router: Router,private Fb: FormBuilder) { 
    this.breadCrumbItems = [
      { label: "Dashboard", path: "loan" },
      { label: "Applications", path: "/loan/applications" },
      { label: "IPVR Report", path: "/", active: true },
    ];
    let a = this.router.getCurrentNavigation().extras;
    if(a){
      if(a.state){
        this.applicationStatus  = a.state.ApplicationStatus;
        this.AppID = a.state.AppID
        this.appID = a.state.AppID;
        localStorage.setItem('ApplicationStatus',this.applicationStatus);
        localStorage.setItem('AppID',this.AppID);
      }
    }
    if(localStorage.getItem('AppID')){
      this.AppID = localStorage.getItem('AppID');
      this.appID = this.AppID;
      this.applicationStatus = localStorage.getItem('ApplicationStatus');
    }

  }

  ngOnInit() {
    this.getApplicationData();
    // this.isLoading = true;
    this.submitted = false;
    this.currentUser = this.service.getcurrentUser();
    this.PropertyForm = this.Fb.group({
      // PropertyInfo: this.Fb.array([this.initPropertyInfo()])
      PropertyInfo :this.Fb.array([]),
      // OwnerRemark: new FormControl(""),
      // PropertyRemark: new FormControl("")
      })
    this.PVRForm = this.Fb.group({
      OwnerCoOwnersName: this.Fb.array([this.initOwner()]),
      EncumbranceDetails: this.Fb.array([
        this.initEncumbranceDetails()
      ]),
      // PropertyInfo: this.Fb.array([this.initPropertyInfo()]),
      // CropDetails: this.Fb.array([
      //   this.initCropDetails()
      // ]),
      TotalLandSize: new FormControl("", Validators.required),
      NumberOfOwners: new FormControl("1", Validators.required),
      PVRPropertyStatus: new FormControl(null, Validators.required),
      PVRRiskMeterStatus: new FormControl(null, Validators.required),
      ownership: new FormControl("", Validators.required),
      property: new FormControl("", Validators.required),
      encumbrance: new FormControl("", Validators.required),
      unit: new FormControl("", Validators.required),
      encum_applicant: new FormControl("", Validators.required),
      is_litigation: new FormControl(""),
      other_charge_details: new FormControl('',Validators.required),
      other_charge_details_type:new FormControl('',Validators.required),
      bank_charge_status: new FormControl('',Validators.required),
      Ownership_status_details :new FormControl('',Validators.required),
      is_cersai: new FormControl(""),
      no_encum_applicant: new FormControl("", Validators.pattern("^[0-9]*$")),
      government: new FormControl("", Validators.required),
      OwnerRemarks: new FormControl("", Validators.required),
      PVRStatusLine1: new FormControl("", Validators.required),
      PVRStatusLine2: new FormControl("", Validators.required),
      PropertyRemarks: new FormControl("", Validators.required),
      EncumbranceRemarks: new FormControl("", Validators.required),
      reason_code: new FormControl(""),
      CreatedBy: new FormControl(
        this.currentUser.UserID,
        Validators.required
      ),
      encumbranceFound: new FormControl(""), // change_in_survey
      encumbranceText: new FormControl(""),
      governmentFound: new FormControl(""),
      is_change_survey : new FormControl('',Validators.required),
      is_change_in_landOwnership: new FormControl('',Validators.required),
      is_change_in_Area:new FormControl('',Validators.required),
      change_in_survey: new FormControl(''),
      change_in_landOwnership: new FormControl(''),
      change_in_Area: new FormControl(''),
      is_bankCharge_available:new FormControl('',Validators.required),
      other_charge_available: new FormControl('',Validators.required),
      governmentText: new FormControl(""),
    });
 
  }
  
  uploadDoc(id){
    let vID = this.appData.data.Properties.Documents.PropertyID
    this.router.navigate(['/loan/viewdocument/' + vID + '/' + id + '/' + this.applicationData.AppID]);
  }
  getDefaultUnit (state) {
    if (state === 'Gujarat' || state === 'Maharashtra' || state === 'Tamil Nadu' || state === 'Uttarakhand' || state === 'Goa' || state === 'Uttar Pradesh' || state === 'Madhya Pradesh' || state === 'Maharashtra')
    {
      return 'sq_Meter';
    } else if (state === 'Andhra Pradesh' || state === 'Bihar' || state === 'Jharkhand' || state === 'West Bengal' || state === 'Tripura')
    {
      return 'Acres';
    } else if (state === 'Karnataka' || state === 'Telangana')
    {
      return 'Acres.Guntha';
    } else if (state === 'Chhatisgarh')
    {
      return 'Hector';
    } else if (state === 'Himanchal Pradesh')
    {
      return 'Hector';
    } else if (state === 'Rajasthan')
    {
      return 'sq_Meter';
    } else if (state === 'Punjab' || state === 'Harayana')
    {
      return 'Kanal';
    }
  }
  onKeyPress(e) {
    if (
      [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode == 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.keyCode == 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+V
      (e.keyCode == 86 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.keyCode == 88 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode < 40)
    ) {
      // let it happen, don't do anything
      return;
    }
    let ch = String.fromCharCode(e.keyCode);
    let regEx = new RegExp(this.regexStr);
    if (regEx.test(ch)) {
      return;
    } else {
      e.preventDefault();
    }
  }
  removeOwner() {
    const control = this.PVRForm.controls.OwnerCoOwnersName as FormArray;
    let length = control.length
    for(let i=length-1;i>=0;i--){
      if( control.value[i].PVRID === this.aipvr_id) {
        control.removeAt(i);
      }
    }
    if(control.length == 0) {
      control.push(this.initOwner());
      this.PVRForm.controls.NumberOfOwners.setValue(0);
      this.isDataAdded = false;
    }
    this.PVRForm.reset();  
    this.PVRForm.controls.CreatedBy.setValue(this.currentUser.UserID);
    this.ownership = '';
    this.property = '';
    this.encumbrance = '';
    this.government = '';
    this.pertaining = '';
    this.is_cersai = '';
    this.is_litigation = '';
  }
  onChangeCheckboxes(formControlName, targetType, event?) {

    this.setValidators(formControlName,targetType, event)
    if (targetType && formControlName === "other_charge_details_type") {
      if (formControlName === "other_charge_details_type") {
        this.other_charge_type = targetType
          if(event.target.checked && targetType !== 'Only_Bank_Charge'){
            this.PVRForm.controls.other_charge_details.setValue('');
            this.PVRForm.controls.other_charge_details.updateValueAndValidity();
          }
          if(targetType === 'Only_Bank_Charge'){
            this.PVRForm.patchValue({
              other_charge_details:''
            })
            this.PVRForm.controls.other_charge_details.setValidators(null);
            this.PVRForm.controls.other_charge_details.updateValueAndValidity();
          }
          else{
            this.PVRForm.controls.other_charge_details.setValidators(Validators.required);
          }
      }
      return;
    }
    if (this.PVRForm.value.is_bankCharge_available === targetType && formControlName === "is_bankCharge_available") {
      if (formControlName === "is_bankCharge_available") {
        this.is_bankCharge_available = "";
        this.PVRForm.patchValue({
          is_bankCharge_available: "",
        });
      }
      return;
    }
    if (this.PVRForm.value.other_charge_available === targetType && formControlName === "other_charge_available") {
      if (formControlName === "other_charge_available") {
        this.other_charge_available = "";
        this.PVRForm.patchValue({
          other_charge_available: "",
        });
      }
      return;
    }
    if (this.PVRForm.value.ownership === targetType && formControlName === "ownership") {
      if (formControlName === "ownership") {
        this.ownership = "";
        this.PVRForm.patchValue({
          ownership: "",
        });
      }
      this.setStatus();
      return;
    }
    if (this.PVRForm.value.property === targetType  && formControlName === "property") {
      if (formControlName === "property") {
        this.property = "";
        this.PVRForm.patchValue({
          property: "",
        });
      }
      this.setStatus();
      return;
    }
    if (this.PVRForm.value.encumbrance === targetType && formControlName === "encumbrance") {
      if (formControlName === "encumbrance") {
        this.encumbrance = "";
        this.PVRForm.patchValue({
          encumbrance: "",
        });
        this.PVRForm.patchValue({
          encumbranceFound: "",
        });
        this.encumbranceRemarks = null;
      }
      this.setStatus();
      return;
    }
    if (this.PVRForm.value.government === targetType && formControlName === "government") {
      if (formControlName === "government") {
        this.government = "";
        this.PVRForm.patchValue({
          government: "",
        });
        this.PVRForm.patchValue({
          governmentFound: "",
        });
      }
      this.setStatus();
      return;
    }
    if (this.PVRForm.value.is_change_survey === targetType && formControlName === "is_change_survey") {
      if (formControlName === "is_change_survey") {
        this.is_change_survey = '';
        this.PVRForm.patchValue({
          is_change_survey: "",
        });
        this.PVRForm.patchValue({
          change_in_survey : "",
        });
      }
      return;
    }
    if (this.PVRForm.value.is_change_in_landOwnership === targetType && formControlName === "is_change_in_landOwnership") {
      if (formControlName === "is_change_in_landOwnership") {
        this.is_change_in_landOwnership = '';
        this.PVRForm.patchValue({
          is_change_in_landOwnership: "",
        });
        this.PVRForm.patchValue({
          change_in_landOwnership : "",
        });
      }
      return;
    }
    if (this.PVRForm.value.is_change_in_Area === targetType && formControlName === "is_change_in_Area") {
      if (formControlName === "is_change_in_Area") {
        this.is_change_in_Area = '';
        this.PVRForm.patchValue({
          is_change_in_Area: "",
        });
        this.PVRForm.patchValue({
          change_in_Area : "",
        });
      }
      this.setPriority()
      return;
    }
    if (this.PVRForm.value.encum_applicant === targetType && formControlName === "Pertaining") {
      if (formControlName === "Pertaining") {
        this.pertaining = "";
        this.PVRForm.patchValue({
          encum_applicant: "",
          no_encum_applicant:""
        });
      }
      this.setStatus();
      return;
    }
    if (this.PVRForm.value.is_litigation === targetType && formControlName === "is_litigation") {
      if (formControlName === "is_litigation") {
        this.is_litigation = "";
        this.PVRForm.patchValue({
          is_litigation: "",
        });
      }
      this.setStatus();
      return;
    }
    if (this.PVRForm.value.is_cersai === targetType && formControlName === "is_cersai") {
      if (formControlName === "is_cersai") {
        this.is_cersai = "";
        this.PVRForm.patchValue({
          is_cersai: "",
        });
      }
      this.setStatus();
      return;
    } 
    if (formControlName === "ownership") {
      this.ownership = targetType;
      this.PVRForm.patchValue({
        ownership: targetType,
      });
      this.setStatus()
    }
    if (formControlName === "property") {
      this.property = targetType;
      this.PVRForm.patchValue({
        property: targetType,
      });
      this.setStatus()
    }
    if (formControlName === "encumbrance") {
      this.encumbrance = targetType;
      this.PVRForm.patchValue({
        encumbrance: targetType,
      });
      // if(targetType === "found") {
      this.PVRForm.patchValue({
        encumbranceFound: "",
      });
      this.encumbranceRemarks = null;
      // }
      this.setStatus()
    }
    if (formControlName === "government") {
      this.government = targetType;
      this.PVRForm.patchValue({
        government: targetType,
      });
      // if(targetType === "found") {
      this.PVRForm.patchValue({
        governmentfound: "",
      });
      // }
      this.setStatus()
    }
    this.updateFormControl(formControlName, targetType, this.PVRForm);
    if (formControlName === "is_bankCharge_available") {
      this.is_bankCharge_available = targetType;
      this.PVRForm.patchValue({
        is_bankCharge_available: targetType,
      });
    }
    if (formControlName === "other_charge_available") {
      this.other_charge_available = targetType;
      this.PVRForm.patchValue({
        other_charge_available: targetType,
      });
    }
    if (formControlName === "Pertaining") {
      this.pertaining = targetType;
      this.PVRForm.patchValue({
        encum_applicant: targetType,
      });
      this.setStatus()
    }
    if (formControlName === "is_litigation") {
      this.is_litigation = targetType;
      this.PVRForm.patchValue({
        is_litigation: targetType,
      });
      this.setStatus()
    }
    if (formControlName === "is_cersai") {
      this.is_cersai = targetType;
      this.PVRForm.patchValue({
        is_cersai: targetType,
      });

    }
    // this.setStatus();
  }

  updateFormControlValidators(control, value, validators) {
    if (!value || value === 'false') {
      control.clearValidators();
      control.setValue('');
    } 
    else if(!value || value === 'not found'){
      control.clearValidators();
      control.setValue('');
    }
    else {
      control.setValidators(validators);
    }
    control.updateValueAndValidity();
  }
  setValidators(formControlName,targetType,event?){
    if (targetType && event && event.target.checked) {
      switch (formControlName) {
        case "is_change_survey":
          this.is_change_survey = targetType;
          this.updateFormControlValidators(this.PVRForm.controls.change_in_survey, targetType, Validators.required);
          break;
        case "is_change_in_Area":
          this.is_change_in_Area = targetType;
          this.updateFormControlValidators(this.PVRForm.controls.change_in_Area, targetType, Validators.required);
          this.setPriority();
          break;
        case "is_change_in_landOwnership":
          this.is_change_in_landOwnership = targetType;
          this.updateFormControlValidators(this.PVRForm.controls.change_in_landOwnership, targetType, Validators.required);
          break;
        case "government":
            this.government = targetType;
            this.updateFormControlValidators(this.PVRForm.controls.governmentFound, targetType, Validators.required);
          break;
        case "Pertaining":
            this.pertaining = targetType;
            this.updateFormControlValidators(this.PVRForm.controls.no_encum_applicant, targetType, Validators.required);
          break;
        case "encumbrance":
            this.encumbrance = targetType;
            this.updateFormControlValidators(this.PVRForm.controls.encumbranceFound, targetType, Validators.required);
          break;
        default:
          break;
      }
    } else {
      switch (formControlName) {
        case "is_change_survey":
          this.updateFormControlValidators(this.PVRForm.controls.change_in_survey, targetType, null);
          break;
        case "is_change_in_Area":
          this.updateFormControlValidators(this.PVRForm.controls.change_in_Area, targetType, null);
          this.setPriority()
          break;
        case "is_change_in_landOwnership":
          this.updateFormControlValidators(this.PVRForm.controls.change_in_landOwnership, targetType, null);
          break;
        case "government":
          this.updateFormControlValidators(this.PVRForm.controls.governmentFound, targetType, null);
          break;
        case "Pertaining":
          this.updateFormControlValidators(this.PVRForm.controls.no_encum_applicant, targetType, null);
          break;
        case "encumbrance":
            this.updateFormControlValidators(this.PVRForm.controls.encumbranceFound, targetType, null);
            break;
        default:
          break;
      }
    }
  }
  updateFormControl(formControlName, targetType, form) {
    const controlMappings = {
      "is_change_survey": {
        control: 'change_in_survey',
        value: targetType
      },
      "is_change_in_landOwnership": {
        control: 'change_in_landOwnership',
        value: targetType
      },
      "is_change_in_Area": {
        control: 'change_in_Area',
        value: targetType
      }
    };
    if (controlMappings.hasOwnProperty(formControlName)) {
      const { control, value } = controlMappings[formControlName];
      this[formControlName] = value;
      this.PVRForm.patchValue({
        [formControlName]: value,
        [control]: ""
      });
    }
    if(formControlName === 'is_change_in_Area'){
      this.setPriority()
    }
  }
  setStatus() {
    // Ownership 1  Property  Encumbrance
    // Confirmed    Verified  Not Found
    if (
      this.PVRForm.value.ownership === "confirmed" &&
      this.PVRForm.value.property === "verified" &&
      this.PVRForm.value.encumbrance === "not found"
    ) {
      let propertyText: any;
      this.propertyText1 = "Property verified with total area of ";
      this.propertyText3 = "from which"
      this.propertyText2 = "owned as per the latest government land records.";
      if (this.PVRForm.value.TotalLandSize) {
        propertyText =
          "Property verified with total area of " +
          this.PVRForm.value.TotalLandSize + ' ' + this.selectedUnit +
          " as per the latest government land records.";
      } else {
        propertyText =
          "Property verified with total area of as per the latest government land records.";
      }
      this.setStatusValues(
        "Good",
        "Applicant name verified as per the latest government land records.",
        propertyText,
        "No encumbrance found as per the latest government land records.",
        `Property recommended  for ${this.applicationData.Type_of_Loan ? this.applicationData.Type_of_Loan : 'Loan'}`,
        "-"
      );
    }
    // Ownership 2  Property  Encumbrance
    // Confirmed    Verified  Found
    if (
      this.PVRForm.value.ownership === "confirmed" &&
      this.PVRForm.value.property === "verified" &&
      this.PVRForm.value.encumbrance === "found"
    ) {
      let propertyText: any;
      this.propertyText1 = "Property verified with total area of ";
      this.propertyText3 = "from which"
      this.propertyText2 = "owned as per the latest government land records.";
      if (this.PVRForm.value.TotalLandSize) {
        propertyText =
          "Property verified with total area of " +
          this.PVRForm.value.TotalLandSize + ' ' + this.selectedUnit +
          " as per the latest government land records.";
      } else {
        propertyText =
          "Property verified with total area of as per the latest government land records.";
      }
      this.setStatusValues(
        "Average",
        "Applicant name verified as per the latest government land records.",
        propertyText,
        " encumbrance found as per the latest government land records.",
        `Property recommended for ${this.applicationData.Type_of_Loan ? this.applicationData.Type_of_Loan : 'Loan'}`,
        "Provided that No Due certificate is obtained from the applicant."
      );
      this.encumbranceRemarks =
        " encumbrance found as per the latest government land records.";
    }
    // Ownership 3  Property      Encumbrance
    // Confirmed    Not Verified  Not Found
    if (
      this.PVRForm.value.ownership === "confirmed" &&
      this.PVRForm.value.property === "not verified" &&
      this.PVRForm.value.encumbrance === "not found"
    ) {
      this.setStatusValues(
        "Average",
        "Applicant name verified as per the latest government land records.",
        "Property not verified as per the latest government land records.",
        "No encumbrance found as per the latest government land records.",
        "-",
        "-"
      );
    }
    // Ownership 4  Property      Encumbrance
    // Confirmed    Not Verified  Found
    if (
      this.PVRForm.value.ownership === "confirmed" &&
      this.PVRForm.value.property === "not verified" &&
      this.PVRForm.value.encumbrance === "found"
    ) {
      this.setStatusValues(
        "Poor",
        "Applicant name verified as per the latest government land records.",
        "Property not verified as per the latest government land records.",
        " encumbrance found as per the latest government land records.",
        "-",
        "-"
      );
      this.encumbranceRemarks =
        " encumbrance found as per the latest government land records.";
      // this.myInputField.select();
    }
    // Ownership 5      Property      Encumbrance
    // Co Owners found  Verified      Not Found
    if (
      this.PVRForm.value.ownership === "co-owners found" &&
      this.PVRForm.value.property === "verified" &&
      this.PVRForm.value.encumbrance === "not found"
    ) {
      let propertyText: any;
      this.propertyText1 = "Property verified with total area of ";
      this.propertyText3 = "from which"
      this.propertyText2 = "owned as per the latest government land records.";
      if (this.PVRForm.value.TotalLandSize) {
        propertyText =
          "Property verified with total area of " +
          this.PVRForm.value.TotalLandSize + ' ' + this.selectedUnit +
          " as per the latest government land records.";
      } else {
        propertyText =
          "Property verified with total area of as per the latest government land records.";
      }
      this.setStatusValues(
        "Average",
        "Applicant name verified as co-owner as per the latest government land records.",
        propertyText,
        "No encumbrance found as per the latest government land records.",
        `Property recommended for ${this.applicationData.Type_of_Loan ? this.applicationData.Type_of_Loan : 'Loan'}`,
        "provided Co-owner's consent in obtained"
      );
    }
    // Ownership 6      Property  Encumbrance
    // Co Owners found  Verified  Found
    if (
      this.PVRForm.value.ownership === "co-owners found" &&
      this.PVRForm.value.property === "verified" &&
      this.PVRForm.value.encumbrance === "found"
    ) {
      this.propertyText1 = "Property verified with total area of ";
      this.propertyText3 = "from which"
      this.propertyText2 = "owned as per the latest government land records.";
      let propertyText: any;
      if (this.PVRForm.value.TotalLandSize) {
        propertyText =
          "Property verified with total area of " +
          this.PVRForm.value.TotalLandSize + ' ' + this.selectedUnit +
          " as per the latest government land records.";
      } else {
        propertyText =
          "Property verified with total area of as per the latest government land records.";
      }
      this.setStatusValues(
        "Average",
        "Applicant name verified as co-owner as per the latest government land records.",
        propertyText,
        " encumbrance found as per the latest government land records.",
        `Property recommended for ${this.applicationData.Type_of_Loan ? this.applicationData.Type_of_Loan : 'Loan'} `,
        "Provided Co-owner's consent is obtained and No-Due Certificate is obtained from applicant"
      );
      this.encumbranceRemarks =
        " encumbrance found as per the latest government land records.";
    }
    // Ownership 7      Property      Encumbrance
    // Co Owners found  Not Verified  Not Found
    if (
      this.PVRForm.value.ownership === "co-owners found" &&
      this.PVRForm.value.property === "not verified" &&
      this.PVRForm.value.encumbrance === "not found"
    ) {
      this.setStatusValues(
        "Average",
        "Applicant name verified as co-owner as per the latest government land records.",
        "Property not verified as per the latest government land records.",
        "No encumbrance found as per the latest government land records.",
        "-",
        "-"
      );
    }
    // Ownership 8      Property      Encumbrance
    // Co Owners found  Not Verified  Found
    if (
      this.PVRForm.value.ownership === "co-owners found" &&
      this.PVRForm.value.property === "not verified" &&
      this.PVRForm.value.encumbrance === "found"
    ) {
      this.setStatusValues(
        "Poor",
        "Applicant name verified as co-owner as per the latest government land records.",
        "Property not verified as per the latest government land records.",
        " encumbrance found as per the latest government land records.",
        "-",
        "-"
      );
      this.encumbranceRemarks =
        " encumbrance found as per the latest government land records.";
    }
    // Ownership 9     Property   Encumbrance
    // Not Confirmed   Verified   Not Found
    if (
      this.PVRForm.value.ownership === "not confirmed" &&
      this.PVRForm.value.property === "verified" &&
      this.PVRForm.value.encumbrance === "not found"
    ) {
      this.propertyText1 = "Property verified with total area of ";
      this.propertyText3 = "from which"
      this.propertyText2 = "owned as per the latest government land records.";
      let propertyText: any;
      if (this.PVRForm.value.TotalLandSize) {
        propertyText =
          "Property verified with total area of " +
          this.PVRForm.value.TotalLandSize + ' ' + this.selectedUnit +
          " as per the latest government land records.";
      } else {
        propertyText =
          "Property verified with total area of as per the latest government land records.";
      }
      this.setStatusValues(
        "Average",
        "Applicant name not verified as per the latest government land records.",
        propertyText,
        "No encumbrance found as per the latest government land records.",
        `Property recommended for ${this.applicationData.Type_of_Loan ? this.applicationData.Type_of_Loan : 'Loan'}`,
        "Provided Ownership is authenticated by Sales Deed / Index-II copy"
      );
    }
    // Ownership 10    Property   Encumbrance
    // Not Confirmed   Verified   Found
    if (
      this.PVRForm.value.ownership === "not confirmed" &&
      this.PVRForm.value.property === "verified" &&
      this.PVRForm.value.encumbrance === "found"
    ) {
      this.propertyText1 = "Property verified with total area of ";
      this.propertyText3 = "from which"
      this.propertyText2 = "owned as per the latest government land records.";
      let propertyText: any;
      if (this.PVRForm.value.TotalLandSize) {
        propertyText =
          "Property verified with total area of " +
          this.PVRForm.value.TotalLandSize + ' ' + this.selectedUnit +
          " as per the latest government land records.";
      } else {
        propertyText =
          "Property verified with total area of as per the latest government land records.";
      }
      this.setStatusValues(
        "Poor",
        "Applicant name not verified as per the latest government land records.",
        propertyText,
        " encumbrance found as per the latest government land records.",
        `Property recommended for ${this.applicationData.Type_of_Loan ? this.applicationData.Type_of_Loan : 'Loan'}`,
        "Provided Ownership is authenticated by Sales Deed / Index-II copy and No-Due Certificate is obtained from applicant"
      );
      this.encumbranceRemarks =
        " encumbrance found as per the latest government land records.";
    }
    // Ownership 11    Property       Encumbrance
    // Not Confirmed   Not Verified   Not Found
    if (
      this.PVRForm.value.ownership === "not confirmed" &&
      this.PVRForm.value.property === "not verified" &&
      this.PVRForm.value.encumbrance === "not found"
    ) {
      this.setStatusValues(
        "Poor",
        "Applicant name not verified as per the latest government land records.",
        "Property not verified as per the latest government land records.",
        "No encumbrance found as per the latest government land records.",
        `Property not recommended for ${this.applicationData.Type_of_Loan ? this.applicationData.Type_of_Loan : 'Loan'}`,
        "-"
      );
    }
    // Ownership 12   Property       Encumbrance
    // Not Confirmed  Not Verified   Found
    if (
      this.PVRForm.value.ownership === "not confirmed" &&
      this.PVRForm.value.property === "not verified" &&
      this.PVRForm.value.encumbrance === "found"
    ) {
      this.setStatusValues(
        "Poor",
        "Applicant name not verified as per the latest government land records.",
        "Property not verified as per the latest government land records.",
        " encumbrance found as per the latest government land records.",
        "-",
        "-"
      );
      this.encumbranceRemarks =
        " encumbrance found as per the latest government land records.";
    }
    if (
      this.PVRForm.value.ownership === "" ||
      this.PVRForm.value.property === "" ||
      this.PVRForm.value.encumbrance === ""
    ) {
      this.setStatusValues("", "", "", "", "", "");
      // this.PVRForm.patchValue({
      //   encumbranceFound: "",
      // });
      this.encumbranceRemarks = null;
    }
  }
  setStatusValues(
    riskMeter,
    ownerRemarks,
    propertyRemarks,
    encumbranceRemarks,
    line1,
    line2,
    
  ) {
    if (this.PVRForm.value.encumbranceFound && encumbranceRemarks) {
      this.PVRForm.patchValue({
        PVRRiskMeterStatus: riskMeter,
        OwnerRemarks: ownerRemarks,
        PropertyRemarks: propertyRemarks,
        EncumbranceRemarks:
          this.PVRForm.value.encumbranceFound + encumbranceRemarks,
        PVRStatusLine1: line1,
        PVRStatusLine2: line2,
      });
      // for(let i = 0; i < this.appData.data.Properties.length ; i++){
      //   this.PropertyForm['controls']['PropertyInfo']['controls'][i]['controls']['OwnerRemark'].setValue(ownerRemarks);
      //   this.PropertyForm['controls']['PropertyInfo']['controls'][i]['controls']['PropertyRemarks'].setValue(propertyRemarks);
      // }
    } else {
      this.PVRForm.patchValue({
        PVRRiskMeterStatus: riskMeter,
        OwnerRemarks: ownerRemarks,
        PropertyRemarks: propertyRemarks,
        EncumbranceRemarks: encumbranceRemarks,
        PVRStatusLine1: line1,
        PVRStatusLine2: line2,
      });
    }
    // for(let i = 0; i < this.appData.data.Properties.length ; i++){
    //   this.PropertyForm['controls']['PropertyInfo']['controls'][i]['controls']['OwnerRemark'].setValue(ownerRemarks);
    //   this.PropertyForm['controls']['PropertyInfo']['controls'][i]['controls']['PropertyRemarks'].setValue(propertyRemarks);
    // }

  }
  initOwner(i?) {
    if (i && i !== undefined) {
      return this.Fb.group({
        OwnersName: new FormControl(i.OwnerName, Validators.required),
        LandSize: new FormControl(i.LandSize,),
        PVRID: new FormControl(i.PVRID),
      });
    } else {
      return this.Fb.group({
        OwnersName: new FormControl("", Validators.required),
        LandSize: new FormControl(""),
        PVRID: new FormControl(""),
      });
    }
  }
  initPropertyInfo(i?){
    // console.log('i',i)
    let property = 'Property verified as per the latest government land records'
    let ownership = 'Applicant name verified as per the latest government land records.'
    if (i && i !== undefined){
      return this.Fb.group({
        OwnerRemark:new FormControl(ownership),
        PropertyRemarks:new FormControl(property),
        isPropertyVerified :new FormControl(i.isPropertyVerified),
        isOwnershipVerified :new FormControl(i.isPropertyVerified)
      })
    }
    else{
      return this.Fb.group({
        OwnerRemark: new FormControl(ownership),
        PropertyRemarks: new FormControl(property),
        isPropertyVerified :new FormControl(true),
        isOwnershipVerified :new FormControl(true),
      })
    }
  }
  initEncumbranceDetails(i?) {
    if (i && i !== undefined) {
      return this.Fb.group({
        LoanTakenBy: new FormControl(i.LoanTakenBy),
        LoanAmount: new FormControl(i.LoanAmount),
        LoanTakenOn: new FormControl(i.LoanTakenOn),
        LoanGivenBy: new FormControl(i.LoanGivenBy),
      });
    } else {
      return this.Fb.group({
        LoanTakenBy: new FormControl(""),
        LoanAmount: new FormControl(""),
        LoanTakenOn: new FormControl(""),
        LoanGivenBy: new FormControl(""),
      });
    }
  }
  initCropDetails(i?) {
    if (i && i !== undefined) {
      return this.Fb.group({
        Year: new FormControl(i.Year),
        Season: new FormControl(i.Season),
        Crop: new FormControl(i.Crop),
        Area: new FormControl(i.Area),
        MSP: new FormControl(i.MSP),
      });
    } else {
      return this.Fb.group({
        Year: new FormControl(""),
        Season: new FormControl(""),
        Crop: new FormControl(""),
        Area: new FormControl(""),
        MSP: new FormControl(""),
      });
    }
  }
  get f() {
    return this.PVRForm.controls;
  }

  propetyCheckboxChange(index){
    if(this.PropertyForm['controls']['PropertyInfo']['controls'][index].value['isPropertyVerified'] === true){
      this.PropertyForm['controls']['PropertyInfo']['controls'][index].controls.PropertyRemarks.setValue('Property verified as per the latest government land records')
    }
    else{
      this.PropertyForm['controls']['PropertyInfo']['controls'][index].controls.PropertyRemarks.setValue('Property not verified as per the latest government land records')
    }
  }
  ownershipCheckboxChange(index){
    if(this.PropertyForm['controls']['PropertyInfo']['controls'][index].value['isOwnershipVerified'] === true){
      this.PropertyForm['controls']['PropertyInfo']['controls'][index].controls.OwnerRemark.setValue('Applicant name verified as per the latest government land records.')
    }
    else{
      this.PropertyForm['controls']['PropertyInfo']['controls'][index].controls.OwnerRemark.setValue('Applicant name not verified as per the latest government land records.')
    }
  }

  onChangeLandSize() {
    if (
      this.PVRForm.value.TotalLandSize &&
      this.PVRForm.value.PropertyRemarks &&
      this.PVRForm.value.property === "verified"
    ) {
      this.PVRForm.patchValue({
        PropertyRemarks:
        this.propertyText1 +
        this.PVRForm.value.TotalLandSize + ' ' +
        this.selectedUnit + ' ' +
        this.propertyText3 + ' ' +
        this.PVRForm.value.TotalshareOwned + ' ' +
        this.firstName + ' ' +
        this.propertyText2,
      });
    }
    this.calculateIndividualShare();
  }

  getApplicationData(){
    this.isLoading = true;
    // this.AppID = '127954';
    this.service.getMultiplePropertyInfo(this.AppID).subscribe((res:any)=>{
      this.appData = res;
      this.isLoading = false;
      this.applicationData = res.data.ApplicatntDetails[0];
      this.owners = res.data.PropertyOwnerShip;
      this.defaultUnit = this.getDefaultUnit(this.StateName);
      this.firstName = this.appData.data.ApplicatntDetails[0].FirstName;
      this.lastName =  this.appData.data.ApplicatntDetails[0].LastName;
      this.stateID = Number(this.appData['data']['Properties'][0].StateID);
      this.StateName = this.appData.data.Properties[0].StateName;
      this.ApplicationID =  this.appData.data.ApplicatntDetails[0].ApplicationID;
      this.PropertyID = this.appData.data.Properties[0].PropertyID
      this.DocumentID = this.appData.data.Properties[0].Documents[0].DocumentID
      this.APPID = this.appData.data.ApplicatntDetails[0].AppID;
      this.TotalLandSize =this.appData.data.ApplicatntDetails[0].TotalLandSize
      // this.PropertyForm.controls["PropertyRemark"].setValue(this.PVRForm.controls.PropertyRemarks.value);
      // this.PropertyForm.controls["OwnerRemark"].setValue(this.PVRForm.controls.OwnerRemarks.value);
      if(this.appData.data.Properties){

        for(let i = 0;i < this.appData.data.Properties.length ; i++){
          
          const control = this.PropertyForm['controls'].PropertyInfo as FormArray;
          control.push(this.initPropertyInfo(i));
        }
       
      }
    })

  }

  save() {
    let data = {} 
    console.log('PVRForm',this.PropertyForm['controls']['PropertyInfo']['controls']);
    this.generateRemarks();
    this.appData.data.Properties.forEach((i,index)=>{
      data = {PropertyID:i.PropertyID,
        ownerRemarks:this.PropertyForm['controls']['PropertyInfo']['controls'][index]['controls']['OwnerRemark'].value,
        PropertyRemarks:this.PropertyForm['controls']['PropertyInfo']['controls'][index]['controls']['PropertyRemarks'].value }
        this.data.push(data);
        data = {}
    });
    this.PVRForm.controls.change_in_survey.setValue(this.PVRForm.controls.change_in_survey.value.trim())
    this.PVRForm.controls.change_in_survey.updateValueAndValidity();
    this.PVRForm.controls.change_in_Area.setValue(this.PVRForm.controls.change_in_Area.value.trim())
    this.PVRForm.controls.change_in_Area.updateValueAndValidity();
    this.PVRForm.controls.change_in_landOwnership.setValue(this.PVRForm.controls.change_in_landOwnership.value.trim())
    this.PVRForm.controls.change_in_landOwnership.updateValueAndValidity();
    this.submitted = true;
    if(this.PVRForm.valid){
      if(this.documentVerfication() === undefined){
        Swal.fire({
          title: "Upload Document",
          text: "There Is No Document Attached With this iPVR",
          type: "error",
          showConfirmButton: false,
          timer:3000
        });
        return
      }
      const FinalValue = {
        ...this.PVRForm.value,
      }
      FinalValue.TotalLandSize = this.PVRForm.controls.TotalLandSize.value + ' ' + this.PVRForm.controls.unit.value
      FinalValue['PropertyInfo'] = this.data;
      if(this.appData.data.Properties[0].StateID == 25){
        let survey = this.PVRForm.controls.change_in_survey.value
        let newExtent = this.PVRForm.controls.change_in_Area.value;
        let change = `EXTENT CHANGE S NO. - ${survey ? survey :'NA'} `
        let oldExtent = ` ,OLD EXTENT - ${this.totalExtent} ${this.selectedUnit} `
        newExtent = ` ,NEW EXTENT - ${newExtent ? newExtent :this.totalExtent} ${this.selectedUnit} </br>`
        let result = change +' ' +oldExtent +' '+ newExtent;
        let caseStatus = '';
        if(this.PVRForm.controls.reason_code.value){
           caseStatus = 'NEGATIVE'
          let reason_code = `<br/><span style="color:black"> ${this.PVRForm.controls.reason_code.value ? this.PVRForm.controls.reason_code.value : ''}</span>`
          this.PVRForm.controls.reason_code.setValue(result + reason_code)
        }
        else{
           caseStatus = 'POSITIVE'
          this.PVRForm.controls.reason_code.setValue(result)
        }
        FinalValue['EncumbranceRemarks'] = this.PVRForm.controls.EncumbranceRemarks.value+' (' +caseStatus+ ')' +'<br/><br/> ' + (this.PVRForm.controls.reason_code.value ? this.PVRForm.controls.reason_code.value : '')
      }
      console.log('final value',FinalValue);
      this.isLoading = true;
      this.service
            .SavePVR(FinalValue, this.appID)
            .subscribe((res) => {
              if (res.error) {
                this.isLoading = false;
                Swal.fire({
                  title: res.error_code,
                  text: res.message,
                  type: "error",
                  showConfirmButton: false,
                  timer:3000
                });
                return;
              } else {
                this.isLoading = true;
                this.service.GeneratePVR(this.appID).subscribe((res) => {
                  if(res.error){
                    this.isLoading = false;
                    Swal.fire({
                      title: res.error_code,
                      text: res.message,
                      type: "error",
                      showConfirmButton: false,
                      timer:3000
                    });
                    return;
                  }
                  else{
                    Swal.fire({
                      title: "Success",
                      text: "iPVR generated",
                      type: "success",
                      showConfirmButton: false,
                      timer:3000
                    }).then(() => {
                      this.service
                        .changeStatus(this.appID, "iPVR Sent")
                        .subscribe(() => {
                          this.isLoading = false;
                          this.router.navigate(["/loan/applications"]);
                        });
                    });
                  }
               
                });
              }
            });
    this.isLoading = true;
    }
    else{
      this.isLoading = false;
      Swal.fire({
        title: "Invalid",
        text: "Invalid Form Data",
        type: "error",
        showConfirmButton: false,
        timer:3000
      });
    }
    // if (this.PVRForm.valid) {
    //   const FinalValue = {
    //     ...this.PVRForm.value,
    //     ...this.PropertyForm.value,
    //   }
    //   // FinalValue.get('PropertyForm').get('OwnerRemark').setValue("PropertyForm.get('OwnerRemark').value")
    //   // FinalValue.get('PropertyForm').get('PropertyRemark').setValue("PropertyForm.get('PropertyRemark').value")
    //   FinalValue.TotalLandSize = this.PVRForm.controls.TotalLandSize.value + ' ' + this.PVRForm.controls.unit.value
    //   this.isLoading = true;
    //   this.service.GetDocumentList(this.appID).subscribe((list) => {
    //     if (
    //       list.data.every((x) => x.DocumentID == null && x.Status == "Pending")
    //     ) {
    //       this.isLoading = false;
    //       Swal.fire({
    //         title: "Are you sure?",
    //         text: "There Is No Document Attached With this PVR, Are You Sure You Want To Generate PVR Without Document Attached ?",
    //         type: "warning",
    //         showCancelButton: true,
    //         confirmButtonText: "Yes",
    //         cancelButtonText: "No",
    //         confirmButtonClass: "btn btn-danger mt-2",
    //         cancelButtonClass: "btn btn-success ml-2 mt-2",
    //         buttonsStyling: false,
    //         showConfirmButton: true,
    //       }).then((result) => {
    //         if (result.value) {
    //           this.isLoading = true;
    //           const data = this.PVRForm.value
    //           console.log("Property Info",data)
    //           FinalValue.TotalLandSize = this.PVRForm.controls.TotalLandSize.value  + this.PVRForm.controls.unit.value
    //           this.service
    //             .SavePVR(FinalValue, this.appID)
    //             .subscribe((res) => {
    //               this.isLoading = false;
    //               if (res.error) {
    //                 Swal.fire({
    //                   title: res.error_code,
    //                   text: res.message,
    //                   type: "error",
    //                   showConfirmButton: false,
    //                   timer:3000
    //                 });
    //                 return;
    //               } else {
    //                 this.isLoading = true;
    //                 this.service.GeneratePVR(this.appID).subscribe((res) => {
    //                   this.isLoading = false;
    //                   Swal.fire({
    //                     title: "Success",
    //                     text: "PVR generated",
    //                     type: "success",
    //                     showConfirmButton: false,
    //                     timer:3000
    //                   }).then(() => {
    //                     this.service
    //                       .changeStatus(this.appID, "iPVR Sent")
    //                       .subscribe(() => {
    //                         this.router.navigate(["/loan/applications"]);
    //                       });
    //                   });
    //                 });
    //               }
    //             });
    //         } else if (result.dismiss === Swal.DismissReason.cancel) {
    //           return false;
    //         }
    //       });
    //     } else {
    //       this.service
    //         .SavePVR(FinalValue, this.appID)
    //         .subscribe((res) => {
    //           if (res.error) {
    //             Swal.fire({
    //               title: res.error_code,
    //               text: res.message,
    //               type: "error",
    //               showConfirmButton: false,
    //               timer:3000
    //             });
    //             return;
    //           } else {
    //             this.service.GeneratePVR(this.appID).subscribe((res) => {
    //               Swal.fire({
    //                 title: "Success",
    //                 text: "PVR generated",
    //                 type: "success",
    //                 showConfirmButton: false,
    //                 timer:3000
    //               }).then(() => {
    //                 this.service
    //                   .changeStatus(this.appID, "iPVR Sent")
    //                   .subscribe(() => {
    //                     this.router.navigate(["/loan/applications"]);
    //                   });
    //               });
    //             });
    //           }
    //         });
    //       this.isLoading = true;
    //     }
    //   });
    // } else {
    //   Swal.fire({
    //     title: "Invalid",
    //     text: "Invalid Form Data",
    //     type: "error",
    //     showConfirmButton: false,
    //     timer:3000
    //   });
    // }
  }
  addEncumbranceDetails() {
    const control = this.PVRForm.controls.EncumbranceDetails as FormArray;
    control.push(this.initEncumbranceDetails());
  }
  addPropertyInfo(){
    const control = this.PVRForm.controls.PropertyInfo as FormArray
    control.push(this.initPropertyInfo())
  }
  removePropertyInfo(i) {
    const control = this.PVRForm.controls.PropertyInfo as FormArray;
    control.removeAt(i);
  }
  removeEncumbranceDetails(i) {
    const control = this.PVRForm.controls.EncumbranceDetails as FormArray;
    control.removeAt(i);
  }
  removeCropDetails(i) {
    const control = this.PVRForm.controls.CropDetails as FormArray;
    control.removeAt(i);
  }
  addCropDetails() {
    const control = this.PVRForm.controls.CropDetails as FormArray;
    control.push(this.initCropDetails());
  }
  removeOwnerDetails(i) {
    const control = this.PVRForm.controls.OwnerCoOwnersName as FormArray;
    control.removeAt(i);
    this.calculateIndividualShare();
    this.PVRForm.controls.NumberOfOwners.setValue(control.length);
  }
  addOwnerDetails() {
    const control = this.PVRForm.controls.OwnerCoOwnersName as FormArray;
    // this.LandSize.push(new FormControl('', Validators.required));

    control.push(this.initOwner());
    this.calculateIndividualShare();
    this.PVRForm.controls.NumberOfOwners.setValue(control.length);
  }
  convertLandSize() {
    this.converted = true;
    this.PVRForm.controls.TotalLandSize.setValue(this.convertintoAcre(Number(this.PVRForm.controls.TotalLandSize.value) , this.defaultUnit, this.selectedUnit).toFixed(4))
    this.calculateIndividualShare();
  }
  calculateIndividualShare() {
    const control = this.PVRForm.controls.OwnerCoOwnersName as FormArray;
    let length = control.length
    for(let i=length-1;i>=0;i--){
      control.at(i).get('LandSize').setValue((Number(this.PVRForm.controls.TotalLandSize.value) / length).toString() + ' ' + (this.PVRForm.controls.unit.value == null || this.PVRForm.controls.unit.value == undefined ? '' : this.PVRForm.controls.unit.value));
    }
  }
  getusers() {
    let names = "";
    this.applicationData.PropertyOwners.forEach((owner) => {
      names += ` ${owner.OwnerName}`;
    });
    return names;
  }
  convertintoAcre(data , CurrentUnit, ConvertedUnit) {
    let valueinAcre = 0;
    switch (CurrentUnit){
      case 'Acres': {
        valueinAcre =  data * 1;
        break;
      }
      case 'Acres.Guntha': {
        const roundoff = Number(data)%1;
        valueinAcre = Math.floor(Number(data)) + Number(roundoff * 2.499999999);
        break;
      }
      case 'Bigha': {
        valueinAcre =  data * 0.619834;
        break;
      } 
      case 'Biswa': {
        valueinAcre =  data * 0.030992;
        break;
      } 
      case 'Guntha': {
        valueinAcre =  data * 0.025000;
        break;
      } 
      case 'Hector': {
        valueinAcre =  data * 2.471052;
        break;
      } 
      case 'Kanal': {
        valueinAcre =  data * 0.125000;
        break;
      } 
      case 'sq_Foot': {
        valueinAcre =  data * 0.000023;
        break;
      } 
      case 'Marla': {
        valueinAcre =  data * 0.006250;   
        break;
      } 
      case 'sq_Inch': {
        valueinAcre =  data * 1.594224e-7;    
        break;
      } 
      case 'sq_Kilometer': {
        valueinAcre =  data * 247.105163;   
        break;
      } 
      case 'sq_Meter': {
        valueinAcre =  data * 0.000247;   
        break;
      } 
      case 'sq_Mile': {
        valueinAcre =  data * 640.002990;  
        break;
      } 
      case 'sq_Yard': {
        valueinAcre =  data * 0.000207; 
        break;    
      } 
    }
    return this.convertsize(valueinAcre, ConvertedUnit);
  }
  convertsize(valueinAcre, ConvertedUnit) {
    switch (ConvertedUnit){
        case 'Acres': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 1);
        } 
        case 'Acres.Guntha': {
          const roundoff = Number(valueinAcre)%1;
          this.defaultUnit = ConvertedUnit;
          return (Math.floor(Number(valueinAcre)) + Number(roundoff * 0.4000000001));
        }
        case 'Bigha': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 1.613335);
        } 
        case 'Biswa': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 32.266695);
        } 
        case 'Guntha': {
          this.defaultUnit = ConvertedUnit;
          return (1 * 40.000036);
          
        } 
        case 'Hector': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 0.404686);
        } 
        case 'Kanal': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 8.000008);
        } 
        case 'sq_Foot': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 43560.057264);
        } 
        case 'Marla': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 159.999842);
        } 
        case 'sq_Inch': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 6272645.545291);
        } 
        case 'sq_Kilometer': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 0.004047);
        } 
        case 'sq_Meter': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 4046.860000);
        } 
        case 'sq_Mile': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 0.001562);
        } 
        case 'sq_Yard': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 4840.006363);
        } 
      }
  } 
  displayvalue(e) {
    if(e == 'success') {
      this.isDocUploaded = true;
    }
    this.service.GetiPVRDetailsRead(this.appID).subscribe((data) => {
      const myValue = data.data[0];
      this.isLoading = false;
      this.PVRForm.controls["OwnerRemarks"].setValue(myValue.owner);
      this.PVRForm.controls["PropertyRemarks"].setValue(myValue.land_use);
     
      if (myValue.encumbrance) {
        this.PVRForm.controls["encumbranceText"].setValue(myValue.encumbrance);
        this.PVRForm.controls["encumbrance"].setValue("found");
        this.encumbrance = "found";
        // this.PVRForm.controls["EncumbranceRemarks"].setValue(
        //   myValue.encumbrance
        // );
        // this.encumbranceRemarks = myValue.encumbrance;
      } else {
        this.PVRForm.controls["encumbranceText"].setValue("");
        this.encumbrance = "";
      }
      if (myValue.government) {
        this.PVRForm.controls["governmentText"].setValue(myValue.government);
        this.PVRForm.controls["government"].setValue("true");
        this.government = "true";
      } else {
        this.PVRForm.controls["governmentText"].setValue("");
        this.government = "";
      }
      const control = this.PVRForm.controls.OwnerCoOwnersName as FormArray;
      if(!this.isDataAdded) {
        control.removeAt(0);
        this.isDataAdded = true;
       
    
      }
      this.aipvr_id = myValue.aipvr_id
      if(this.StateName == 'Andhra Pradesh') {
        let i = 0;
        let owners = myValue.owner.split(",");
        while(i < owners.length) {
          control.push(
            this.initOwner({
            OwnerName: owners[i],
            PVRID: myValue.aipvr_id,
            LandSize: owners[i+1],
          }));
          i= i + 2;
        }
      } else {
        myValue.owner.split(",").forEach((element) => {
          control.push(
          this.initOwner({
            OwnerName: element,
            PVRID: myValue.aipvr_id
            //LandSize: myValue.owner,
        })
        );
        });
      }
      
      this.PVRForm.controls.NumberOfOwners.setValue(control.length);
    });
  }
  LoadChart() {
    this.revenueRadialChart = {
      chart: {
        height: 200,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '65%',
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              fontSize: '24px',
              color: 'rgb(29, 173, 56)',
              offsetY: 10,
              formatter: (val) => {
                return val + '';
              }
            }
          }
        }
      },
      colors: ['rgb(29, 173, 56)'],
      series: [this.totaldocument ? (((this.received >= this.totaldocument ? this.totaldocument : this.received) / this.totaldocument) * 100).toFixed() : 0],
      stroke: {
        lineCap: 'round',
      },
    };
  }
  
  onUploadDocument(doctype, docsubtype, docname,Index,docIndex) {
    const dialog = this.dialog.open(AdminUploadLawyerComponent,{
      height:'500px',
      width:'700px',
      disableClose:true,
      data:{
        DocumentType: doctype,
        DocumentSubType: docsubtype,
        DocumentName: docname,
        PropertyIndex: Index ? Index : 0,
        documentIndex: docIndex,
        appId: this.AppID,
        applicationData: this.appData
      }
    }
    ).afterClosed().subscribe((res)=>{
      if(res){
        // this.getApplicationData();
        this.service.getMultiplePropertyInfo(this.AppID).subscribe((res:any)=>{
          this.appData = res;
          // this.appData['data']['Properties'] = res['data']['Properties']
          // this.temp = this.appData['data']['Properties'].map((res,index)=>{ if(Index != index){ return 0} else { return 1}} )
          // console.log('temp',this.temp);
          // this.applicationData = res.data.ApplicatntDetails[0];
        })
      }
    })

    // this.router.navigate(['loan/uploadlawyerdocument/' + this.APPID], { queryParams: { DocumentType: doctype, DocumentSubType: docsubtype, DocumentName: docname,PropertyIndex: Index}});
  }
    
  onViewDocument(id,propIndex,docIndex) {
    this.router.navigate(['/loan/viewdocument/' + this.appData.data.Properties[0].Documents[0].PropertyID + '/' + id + '/' + this.appData.data.ApplicatntDetails[0].AppID  +'/ipvr'], { state: { PropertyIndex: propIndex, DocumentIndex: docIndex, isFromAddIPVR : true} } );
  }
  onRequestingDocument(id) {

  }
  openImageDialog(id) {
    this.router.navigate([`loan/View-Image/${id}/`+ this.appID]);
    // const dialogRef = this.dialog.open(ImageModalComponent, {
    //   autoFocus: false,
    //   maxHeight: '6oopx',
    //   width: '600px',
    //   data: URL,
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.ngOnInit();
    // });
  }
  encumbranceCount() {
    const count = Number(this.PVRForm.controls.no_encum_applicant.value);
    const control = this.PVRForm.controls.EncumbranceDetails as FormArray;
    if (!Number.isNaN(count) && count != 0) {
      const length = control.length; 
      for(let i=length-1;i>=0;i--) {
        control.removeAt(i);
      }
      if(control.length == 0) {
        for(let i=0;i<count;i++) {
          control.push(this.initEncumbranceDetails());
        }
      }
    } else {
      const length = control.length; 
      for(let i=length-1;i>=0;i--) {
        control.removeAt(i);
      }
      control.push(this.initEncumbranceDetails());
    }
  }
  onChangeEncrumbranceFound() {

    if (this.encumbranceRemarks) {
      this.PVRForm.patchValue({
        EncumbranceRemarks:
          this.PVRForm.value.encumbranceFound + this.encumbranceRemarks,
      });
    }
  }
  AddGPP(){
    this.router.navigate(['/GPP/Add-Gpp-Alert'],{state:{AppID:this.AppID, FromIpvrScreen : 'true'}});
  }

  documentVerfication() {
    for (const property of this.appData.data.Properties) {
      let a = property.Documents;
      this.is_Document_uploaded = a.find(item => item.Status === "Received");
      if (this.is_Document_uploaded) {
        return false;
      }
    }
    return this.is_Document_uploaded;
  }
  onChangeBankChargeStatus(status){
    if(status === 'First_charge'){
      if(this.other_charge_type !== 'Other_Society_Charge'){
        this.comparePriority('H6',this.Prioritization)
      }
      if(this.other_charge_type === 'Other_Society_Charge'){
        this.changeInput()
      }
    }
    else if(status === 'Second_charge'){
      if(this.Prioritization === 'H6'){
        // this.Prioritization = 'L3';
        // this.comparePriority('L3',this.Prioritization)
        // this.setReasoncode();
      }
      else{
        // this.comparePriority('L3',this.Prioritization)
      }
    }
    else{
      // this.PVRForm.controls.reason_code.setValue('');
      // this.setReasoncode()
    }
  }
 getRiskCategoryAndReason(prioritization) {
    const scenario = this.scenarioMap.get(prioritization);
    if (scenario) {
      return scenario.reason_code+ '  ' + scenario.risk_category+ '  ' + prioritization
    } else {
      return 'Invalid scenario'
    }
  }

  changeInput(){
    let bank_charge_status = this.PVRForm.controls['bank_charge_status'].value
    if (this.other_charge_type === 'Other_Society_Charge') {
      const otherChargeValue = this.PVRForm.controls['other_charge_details'].value;
      
      if (bank_charge_status === 'First_charge') {
        // if (this.Prioritization == 'H6') {
          if (otherChargeValue && otherChargeValue <= 100000) {
            this.Prioritization = 'M3'
            this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('M3'));
            return;
          }
          else{
            this.Prioritization = 'M2'
            this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('M2'));
            return;
          }
        // } 
        // else {
          // const riskCategory = otherChargeValue >= 100000 ? 'M2' : 'M3';
          // this.comparePriority(riskCategory, this.Prioritization);
        // }
      }
   }
    
    // if(this.other_charge_type === 'Both_Bank_And_Society' || this.other_charge_type === 'Only_Bank_Charge'){
    //   if(this.PVRForm.controls['bank_charge_status'].value === 'First_charge')
    //   this.comparePriority('H6',this.Prioritization)
    // }
  //  console.log('rasoncode',this.PVRForm.controls.reason_code.value)
  }
  onOwnershipStatusChange(){
    if(this.PVRForm.controls['Ownership_status_details'].value === 'Transfer_with_family'){
      // this.comparePriority('M1',this.Prioritization)
    }
    else{
      // this.setReasoncode();
    }
  }

comparePriority(heading1: string, heading2: string) {
  const priorityLevels: string[] = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'H7', 'M1', 'M2', 'M3', 'M4', 'L2', 'L3'];

  const normalizeHeading = (heading: string) => heading.toUpperCase();

  const isValidHeading = (heading: string) => priorityLevels.includes(normalizeHeading(heading));

  if (isValidHeading(heading1) && isValidHeading(heading2)) {
      const index1: number = priorityLevels.indexOf(normalizeHeading(heading1));
      const index2: number = priorityLevels.indexOf(normalizeHeading(heading2));

      if (index1 < index2 && (!this.Prioritization || priorityLevels.indexOf(normalizeHeading(this.Prioritization)) > index1)) {
          this.Prioritization = heading1;
      } else if (!this.Prioritization || priorityLevels.indexOf(normalizeHeading(this.Prioritization)) > index2) {
          this.Prioritization = heading2;
      }
  } else {
      if (!this.Prioritization) {
          this.Prioritization = isValidHeading(heading1) ? heading1 : null;
      }
  }

  if (this.Prioritization) {
      // this.statusValue = this.getRiskCategoryAndReason(this.Prioritization);
      this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason(this.Prioritization));
  } else {
      console.log('Invalid heading');
  }
}

setPriority(){
  if(this.is_change_in_Area == 'true' && this.PVRForm.controls.change_in_Area.value != 0 && this.TotalLandSize !=0){
  let securityAmount = this.appData.data.Properties[0].Security_Amount;
  let newExtent = this.PVRForm.controls.change_in_Area.value;
  this.totalExtent = this.appData.data.Properties.reduce((sum, property) => sum + Number(property.Extent != null ? property.Extent : 0), 0);
  let LoanAmount = this.appData.data.Properties[0].Loan_Amount
  let percentage = 0;
  if(this.totalExtent > 0){
   let firstValue = (securityAmount * newExtent) / this.totalExtent
   percentage = (firstValue *100) / LoanAmount
  }
  let survey = this.PVRForm.controls.change_in_survey.value
    let change = `EXTENT CHANGE S NO - ${survey} `
    let oldExtent = ` OLD EXTENT - ${this.totalExtent} ${this.selectedUnit} `
    newExtent = ` NEW EXTENT - ${newExtent} ${this.selectedUnit} </br>`
    let result = change +' ' +oldExtent +' '+ newExtent
  if(percentage < 50 && percentage !=0){
    this.Prioritization = 'H2'
    this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('H2'));
  }
  else if(percentage >= 50 && percentage<=100){
    this.Prioritization = 'H3'
    this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('H3'));
  }
  else if(percentage > 100 && percentage <=150){
    this.Prioritization = 'H4'
    this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('H4'));
  }
  else if(percentage > 150){
    this.Prioritization = 'H7'
   if(this.PVRForm.controls['bank_charge_status'].value === 'First_charge' && this.PVRForm.controls['other_charge_details_type'].value !== 'Other_Society_Charge'){
      this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('H6'));
    }
    else{
      this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('H7'));
    }
  }
  else{
  }
  }
}

setReasoncode(){
  if(this.PVRForm.value.TotalLandSize == 0){
    this.comparePriority('H1',this.Prioritization)
  }
  if(this.PVRForm.controls.change_in_Area.value){
    this.setPriority()
  }
  if(this.PVRForm.controls['Ownership_status_details'].value){
    this.onOwnershipStatusChange()
  }
  if(this.PVRForm.controls.bank_charge_status.value){
    this.onChangeBankChargeStatus(this.PVRForm.controls.bank_charge_status.value)
  }
  if(this.PVRForm.controls['bank_charge_status'].value){
    this.changeInput()
  }
  
}

onChange(){
  if(this.government === 'true'){
    this.comparePriority('M4',this.Prioritization);
  }
  else{
    this.PVRForm.controls.reason_code.setValue('');
  }
}

generateRemarks(){
  const otherChargeValue = this.PVRForm.controls['other_charge_details'].value;
  this.totalExtent = this.appData.data.Properties.reduce((sum, property) => sum + Number(property.Extent != null ? property.Extent : 0), 0);
  if(Number(this.PVRForm.controls.TotalLandSize.value) === 0){
    this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('H1'));
  }
  else if(this.is_change_in_Area == 'true' && Number(this.PVRForm.controls.change_in_Area.value) != 0 && Number(this.PVRForm.controls.TotalLandSize.value) != 0 && this.totalExtent>0){
      this.setPriority();
  }
  else if(this.PVRForm.controls['bank_charge_status'].value === 'First_charge' && this.PVRForm.controls['other_charge_details_type'].value !== 'Other_Society_Charge'){
    this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('H6'));
  }
  else if(this.PVRForm.controls['Ownership_status_details'].value === 'Transfer_with_family'){
    this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('M1'));
  }
  else if(this.PVRForm.controls['bank_charge_status'].value === 'First_charge' && this.other_charge_type === 'Other_Society_Charge' && otherChargeValue && otherChargeValue > 100000){
    this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('M2'));
  }
  else if(this.government === 'true'){
    this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('M4'));
  }
  else if(this.PVRForm.controls['bank_charge_status'].value === 'First_charge' && this.other_charge_type === 'Other_Society_Charge' && otherChargeValue && otherChargeValue <= 100000){
    this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('M3'));
  }
  else if(this.is_bankCharge_available === 'false'){
    this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('L2'));
  }
  else if(this.PVRForm.controls.bank_charge_status.value === 'Second_charge'){
    this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('L3'));
  }
  else{
    this.PVRForm.controls.reason_code.setValue('');
    this.PVRForm.controls.reason_code.updateValueAndValidity();
  }

}
  // generateRemarks(){
  //   console.log('pvrform',this.PVRForm.value)
  //   console.log('test',this.PVRForm.controls.TotalLandSize.value)
  //   this.totalExtent = this.appData.data.Properties.reduce((sum, property) => sum + Number(property.Extent != null ? property.Extent : 0), 0);
  //   if(Number(this.PVRForm.controls.TotalLandSize.value) === 0){
  //     console.log('H1')
  //     this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('H1'));
  //   }
  //   else if(this.is_change_in_Area == 'true' && Number(this.PVRForm.controls.change_in_Area.value) != 0 && Number(this.PVRForm.controls.TotalLandSize.value) != 0 && this.totalExtent>0){
  //       this.setPriority();
  //   }
  //   // const otherChargeValue = this.PVRForm.controls['other_charge_details'].value;

  //   else if(this.PVRForm.controls['bank_charge_status'].value === 'First_charge'){
  //     if(this.PVRForm.controls['other_charge_details_type'].value !== 'Other_Society_Charge')
  //     {
  //       this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('H6'));
  //     }
  //     else if(this.PVRForm.controls['Ownership_status_details'].value === 'Transfer_with_family'){
  //       this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('M1'));
  //     }
  //     else if(this.government === 'true'){
  //       this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('M4'));
  //     }
  //     else{
  //       if(this.other_charge_type === 'Other_Society_Charge'){
  //         this.changeInput();
  //       }
  //     }
  //   }
  //   else if(this.PVRForm.controls['Ownership_status_details'].value === 'Transfer_with_family'){
  //     this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('M1'));
  //   }
  //   else if(this.government === 'true'){
  //     this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('M4'));
  //   }
  //   else if(this.is_bankCharge_available === 'false'){
  //     this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('L2'));
  //   }
  //   else if(this.PVRForm.controls.bank_charge_status.value === 'Second_charge'){
  //     this.PVRForm.controls.reason_code.setValue(this.getRiskCategoryAndReason('L3'));
  //   }
  //   else{
  //     this.PVRForm.controls.reason_code.setValue('');
  //     this.PVRForm.controls.reason_code.updateValueAndValidity();
  //   }

  // }

  checkTotalExcentGreater(){
    this.totalExtent = this.appData.data.Properties.reduce((sum, property) => sum + Number(property.Extent != null ? property.Extent : 0), 0);
    if(this.is_change_in_Area === 'true' && this.appData.data.Properties[0].StateID == 25){
      if(this.PVRForm.controls.change_in_Area.value > this.totalExtent){
        this.is_totalExcent_greater = true;
      }
      else{
        this.is_totalExcent_greater = false;
      }
    }
    else{
      this.is_totalExcent_greater = false;
    }
  }
}

