import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
import { ViewFilterDocumentsComponent } from './view-filter-documents/view-filter-documents.component';
import { TitleClearCompleteComponent } from './title-clear-complete/title-clear-complete.component';
import { PublicNoticeViewComponent } from './public-notice-view/public-notice-view.component';
import { TitleClearComponent } from './title-clear/title-clear.component';
import { TitleClearListComponent } from './title-clear-list/title-clear-list.component';
import { PublicNoticeComponent } from './public-notice/public-notice.component';
import { AssignmentComponent } from './assignment/assignment.component';
import { AssignmentsComponent } from './assignments/assignments.component';
import { LawyersComponent } from './lawyers/lawyers.component';

import { GeneratePVRReportComponent } from './generate-pvr-report/generate-pvr-report.component';
import { PvrReportComponent } from './pvr-report/pvr-report.component';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { AddApplicationComponent } from './add-application/add-application.component';

import { ApplicantDashboardComponent } from './applicant-dashboard/applicant-dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ApplicationComponent } from './application/application.component';
import { ViewDocumentComponent } from './view-document/view-document.component';
import { PublicNoticesComponent } from './public-notices/public-notices.component';
import { AssignLawyerComponent } from './assign-lawyer/assign-lawyer.component';
import { AddLawyerComponent } from './add-lawyer/add-lawyer.component';
import { SelectDocumentsComponent } from './select-documents/select-documents.component';
import { UploadLawyerDocumentsComponent } from './upload-lawyer-documents/upload-lawyer-documents.component';
import { ViewIpvrDocumentsComponent } from './view-ipvr-documents/view-ipvr-documents.component';
import { ViewTitleclearDocumentsComponent } from './view-titleclear-documents/view-titleclear-documents.component';
import { AddIpvrDocumentsComponent } from './add-ipvr-documents/add-ipvr-documents.component';
import { AddTitleclearDocumentsComponent } from './add-titleclear-documents/add-titleclear-documents.component';
import { SearchInProgressComponent } from './search-in-progress/search-in-progress.component';
import { AssignValuersComponent } from './assign-valuers/assign-valuers.component';
import { AddValuerComponent } from './add-valuer/add-valuer.component';
import { ValuationDocumentsComponent } from './valuation-documents/valuation-documents.component';
import { ValuersAssignmentsComponent } from './valuers-assignments/valuers-assignments.component';
import { MortgageProtectionComponent } from './mortgage-protection/mortgage-protection.component';
import { PastPublicNoticesMainComponent } from './past-public-notices-main/past-public-notices-main.component';
import { ViewPastPublicNoticeComponent } from './view-past-public-notice/view-past-public-notice.component';
import { AddPastPublicNoticeComponent } from './add-past-public-notice/add-past-public-notice.component';
import { AddResponseComponent } from './add-response/add-response.component';
import { ViewNoticeComponent } from './view-notice/view-notice.component';
import { RequestedDocumentListComponent } from './requested-document-list/requested-document-list.component';
import { ViewRequestedDocumentsComponent } from './view-requested-documents/view-requested-documents.component';
import { AddPublicNoticeRulesComponent } from './add-public-notice-rules/add-public-notice-rules.component';
import { ViewTitleClearReportComponent } from './view-title-clear-report/view-title-clear-report.component';
import { IpvrSentListComponent } from './ipvr-sent-list/ipvr-sent-list.component';
import { TitleClearApplicationsComponent } from './title-clear-applications/title-clear-applications.component';
import { MortgageProtectedListComponent } from './mortgage-protected-list/mortgage-protected-list.component';
import { PostMortgageDashboardComponent } from './post-mortgage-dashboard/post-mortgage-dashboard.component';
import { ImageModalComponent } from './image-modal/image-modal.component';
import { ViewGppComponent } from './view-gpp/view-gpp.component';
import { ViewGppDocComponent } from './view-gpp-doc/view-gpp-doc.component';
import { EcheckDashboardComponent } from '../e-check/echeck-dashboard/echeck-dashboard.component';
import { ViewEcheckApplicationComponent } from '../e-check/view-echeck-application/view-echeck-application.component';
import { ViewEcheckDocumentComponent } from '../e-check/view-echeck-document/view-echeck-document.component';
import { ViewLastPPPAlertDocumentComponent } from '../view-last-pppalert-document/view-last-pppalert-document.component';
import { ViewPvrComponent } from './view-pvr/view-pvr.component';
import { AddApplicationByImageComponent } from './add-application-by-image/add-application-by-image.component';
import { AddPropertiesByImageComponent } from './add-properties-by-image/add-properties-by-image.component';
import { ViewPvrListComponent } from './view-pvr-list/view-pvr-list.component';
import { AddPosApplicationComponent } from './add-pos-application/add-pos-application.component';
import { TitleClearApplicantDashboardComponent } from './title-clear-applicant-dashboard/title-clear-applicant-dashboard.component';
import { TccDashboardLawyerComponent } from './tcc-dashboard-lawyer/tcc-dashboard-lawyer.component';
import { AddModApplicationComponent } from './add-mod-application/add-mod-application.component';
import { AddPropertiesForModComponent } from './add-properties-for-mod/add-properties-for-mod.component';
import { GppDashboardComponent } from '../gpp/gpp-dashboard/gpp-dashboard.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'applications',
    component: ApplicationComponent
  },
  {
    path: 'title-search/:id',
    component: ApplicantDashboardComponent
  },
  {
    path: 'titleclear-dashbboard/:id',
    component: TitleClearApplicantDashboardComponent
  },
  {
    path: 'view-pvr-list',
    component:ViewPvrListComponent
  },
  {
    path: 'search-in-progress',
    component: SearchInProgressComponent
  },
  {
    path: 'search-in-progress/:id',
    component: SearchInProgressComponent
  },
  {
    path: 'requested-documents',
    component: RequestedDocumentListComponent
  },
  {
    path: 'view-requested-documents/:AppID',
    component: ViewRequestedDocumentsComponent
  },
  {
    path: 'lawyer',
    component: AssignLawyerComponent
  }, {
    path: 'valuer',
    component: AssignValuersComponent
  }, {
    path: 'addapplication',
    component: AddApplicationComponent
  },{
    path:'addApplicationByImage',
    component: AddApplicationByImageComponent,
  },
  {
    path:'addPropertiesByImage',
    component:AddPropertiesByImageComponent,
  },
  {
    path:'addPropertiesForMOD',
    component:AddPropertiesForModComponent,
  },
  {
    path:'gpp-dashboard',component:GppDashboardComponent
  },
  {
    path: 'viewdocument/:AppID',
    component: ViewDocumentComponent
  },
   {
    path: 'viewdocument/:propertyid/:id',
    component: ViewDocumentComponent
  }, {
    path: 'viewdocument/:propertyid/:id/:AppID',
    component: ViewDocumentComponent
  }, {
    path: 'viewdocument/:propertyid/:id/:AppID/:DocType',
    component: ViewDocumentComponent
  }, {
    path: 'viewTitleReport/:AppID',
    component: ViewTitleClearReportComponent
  }, {
    path: 'uploaddocument/:Appid',
    component: UploadDocumentComponent
  }, {
    path: 'uploadlawyerdocument/:Appid',
    component: UploadLawyerDocumentsComponent
  }, {
    path: 'PVRreport/:Appid',
    component: PvrReportComponent
  }, {
    path: 'GeneratePVR/:AppID',
    component: GeneratePVRReportComponent
  }, {
    path: 'Lawyers/:AppID',
    component: LawyersComponent
  },
  {
    path:'view-pvr',
    component:ViewPvrComponent
  }
  , {
    path: 'assignment',
    component: AssignmentsComponent
  },
  {
    path: 'pastpublicnotice',
    component: PastPublicNoticesMainComponent
  },
  {
    path: 'viewpastpublicnotice/:AppID',
    component: ViewPastPublicNoticeComponent
  },
  {
    path: 'valuerassignment',
    component: ValuersAssignmentsComponent
  }, {
    path: 'assignment/:AppID',
    component: AssignmentComponent
  },
  {
    path: 'tcc-dashboard-lawyer/:AppID',
    component: TccDashboardLawyerComponent
  },
   {
    path: 'mortgageprotection',
    component: MortgageProtectionComponent
  }, {
    path: 'publicnotice/:AppID',
    component: PublicNoticeComponent
  }, {
    path: 'viewNotice/:AppID/:NoticeID',
    component: ViewNoticeComponent
  }, {
    path: 'addResponse/:AppID/:NoticeID',
    component: AddResponseComponent
  }, {
    path: 'publicnotices',
    component: PublicNoticesComponent
  }, {
    path: 'titleclear',
    component: TitleClearListComponent
  }, {
    path: 'titleclear/:AppID',
    component: TitleClearComponent
  }, {
    path: 'viewpublicnotice',
    component: PublicNoticeViewComponent
  }, {
    path: 'titleclearcompleted/:AppID',
    component: TitleClearCompleteComponent
  }, {
    path: 'addPublicNotice/:AppID',
    component: AddPastPublicNoticeComponent
  }, {
    path: 'app-public-notice-rules',
    component: AddPublicNoticeRulesComponent
  }, {
    path: 'View-Documents/:AppID',
    component: ViewFilterDocumentsComponent
  }, {
    path: 'iPVR-applications',
    component: IpvrSentListComponent
  },
  {
    path: 'iPVR-applications/:AppStatus',
    component: IpvrSentListComponent
  }, {
    path: 'title-clear-applications',
    component: TitleClearApplicationsComponent
  },  {
    path: 'title-clear-applications/:AppStatus',
    component: TitleClearApplicationsComponent
  }, {
    path: 'post-mortgage-applications/:AppStatus',
    component: MortgageProtectedListComponent
  }, {
    path: 'post-mortgage-applications',
    component: MortgageProtectedListComponent
  }, {
    path: 'post-mortgage-dashboard/:id',
    component: PostMortgageDashboardComponent
  }, {
    path: 'bulkupload',
    component: BulkUploadComponent
  },
  {
    path: 'add-lawyer',
    component: AddLawyerComponent
  },
  {
    path: 'add-valuer',
    component: AddValuerComponent
  },
  {
    path: 'documents/:AppID',
    component: SelectDocumentsComponent
  },
  {
    path: 'valuationdocuments/:AppID',
    component: ValuationDocumentsComponent
  },
  {
    path: 'iPVRDocuments',
    component: ViewIpvrDocumentsComponent
  }, 
  {
    path: 'TitleclearDocuments',
    component: ViewTitleclearDocumentsComponent
  },  
  {
    path: 'addTitleclearDocuments',
    component: AddTitleclearDocumentsComponent
  }, 
  {
    path: 'addiPVRDocuments',
    component: AddIpvrDocumentsComponent
  },
  {
    path: 'View-Image/:alertid/:AppID',
    component:ImageModalComponent
  },
  {
    path: 'view-gpp/:AppID/:state',
    component: ViewGppComponent
  }, {
    path: 'view-gpp-document/:mon/:AppID/:state',
    component: ViewGppDocComponent
  },
  {
    path: 'view-gpp-document',
    component: ViewGppDocComponent
  },
  {
    path:'view-last-ppp-document',
    component: ViewLastPPPAlertDocumentComponent
  },
  {
    path:'add-pos-application',
    component:AddPosApplicationComponent,
  },
  {
    path:'add-mod-application',
    component:AddModApplicationComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BankModuleRoutingModule { }
