import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from './../../services/general.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material';
import { ConfimDialogComponent } from 'src/app/shared/components/confim-dialog/confim-dialog.component';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-view-documents',
  templateUrl: './view-documents.component.html',
  styleUrls: ['./view-documents.component.scss']
})
export class ViewDocumentsComponent implements OnInit {
  isShowAdd = false;
  breadCrumbItems: any;
  comment: any = '';
  url: any;
  data: any;
  editorData: any;
  currentUser: any;
  filetype: string;
  isLoading: boolean;
  commentlistLoader:boolean
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // dtTrigger = new Subject<any>();
  commentlist: any[] = [];
  AppID: any;
  submitted: boolean;
  DocumentIndex:any;
  PropertyIndex:any;
  isFromAddIPVR:any;
  isForMulProperty: any;
  applicationData: any;
  status: any;

  constructor(private service: GeneralService, private Route: ActivatedRoute, private sanitizer: DomSanitizer, private router: Router, private dialog: MatDialog) {
    this.currentUser = this.service.getcurrentUser();
    let a = router.getCurrentNavigation().extras;

    if(a){
      if(a.state){
        this.DocumentIndex = a.state.DocumentIndex;
        this.PropertyIndex =  a.state.PropertyIndex;
        this.isFromAddIPVR = a.state.isFromAddIPVR;
        localStorage.setItem('DocumentIndex',this.DocumentIndex);
        localStorage.setItem('PropertyIndex',this.PropertyIndex);
        localStorage.setItem('isFromAddIPVR',this.PropertyIndex);
      }
    }
    // else{
      if(localStorage.getItem('DocumentIndex')){
        this.DocumentIndex = localStorage.getItem('DocumentIndex');
        this.PropertyIndex =  localStorage.getItem('PropertyIndex');
        this.isFromAddIPVR = localStorage.getItem('isFromAddIPVR');
        
      }
    // }

  }

  ngOnInit() {
    this.AppID = this.Route.snapshot.params.AppID;
    localStorage.setItem('AppID',this.AppID)
    this.isForMulProperty = this.Route.snapshot.queryParamMap.get('isForMulProperty');
   
   
    if(this.isFromAddIPVR){
    this.isLoading = true;
      this.service.getMultiplePropertyInfo(this.Route.snapshot.params.AppID).subscribe((Response:any) => {
        this.applicationData = Response.data;
        if (this.currentUser.UserType === 'Lawyer') {
          this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'Assignments', path: '/loan/assignment' },
          {
            label: `${Response.data[0].FirstName} ${Response.data[0].LastName ? Response.data[0].LastName : ''}`
            , path: `/loan/tcc-dashboard-lawyer/${this.Route.snapshot.params.AppID}`
          }
            , { label: 'View Documents', path: '/', active: true }];
        } else if (this.currentUser.UserType === 'Bank Manager') {
          this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'Applications', path: 'loan/applications' },
          {
            label: `${Response.data.ApplicatntDetails[0].FirstName} ${Response.data.ApplicatntDetails[0].LastName ? Response.data.ApplicatntDetails[0].LastName : ''}`,
            path: `/loan/view-pvr-list`
          }
            , { label: 'View Documents', path: '/', active: true }];
        }
        else if (this.currentUser.UserType === 'Proplegit' ) {
          if(Response.data.Properties[0].StateID === 22){
            this.breadCrumbItems = [{ label: 'Dashboard', path: 'rj-applications' }, { label: 'Applications', path: '/rj-applications/Applications' },
            {
              label: `${Response.data.ApplicatntDetails[0].FirstName} ${Response.data.ApplicatntDetails[0].LastName ? Response.data.ApplicatntDetails[0].LastName :'' }`,
              path: `/rj-applications/Add-iPVR`
            }
              , { label: 'View Documents', path: '/', active: true }];
          }
          else{
            this.breadCrumbItems = [{ label: 'Dashboard', path: 'cg-applications' }, { label: 'Applications', path: '/cg-applications/Applications' },
          {
            label: `${Response.data.ApplicatntDetails[0].FirstName} ${Response.data.ApplicatntDetails[0].LastName ? Response.data.ApplicatntDetails[0].LastName :'' }`,
            path: `/cg-applications/View-Application`
          }
            , { label: 'View Documents', path: '/', active: true }];
          }
          
        }
        this.url = Response.data.Properties[this.PropertyIndex].Documents[this.DocumentIndex];
        this.data = Response.data[0];
        this.dtOptions = {
          pagingType: 'full_numbers',
          pageLength: 5,
          // serverSide : true,
          paging: false,
          ordering: false,
          order: [[0, 'desc']]   // '0' is the table column(1st column) and 'desc' is the sorting order
        }
        this.filetype = Response.data.Properties[this.PropertyIndex].Documents[this.DocumentIndex].FileType;
        this.isLoading = false;
      });
    }
    else{
      if(this.currentUser.UserType === 'Lawyer'){
         this.getDocComment();
      }
      if (this.Route.snapshot.params.AppID && !this.Route.snapshot.params.DocType) {
        // if(this.isForMulProperty){
        // }
        // else{
        //   console.log('in else condition condition');
          this.getApplicationData();
        // }
        this.isLoading = false;
        if(this.Route.snapshot.params.id){
          this.isLoading = true;
          this.service.getDocument2(this.Route.snapshot.params.AppID, this.Route.snapshot.params.id).subscribe((res) => {
            // console.log(res.data[this.Route.snapshot.params.propertyid][0]);
            // console.log('res',res.data);
            this.url = res.data[this.Route.snapshot.params.propertyid][0];
            this.status = res.data[this.Route.snapshot.params.propertyid][0].Status;
            this.isLoading = false;
           });
      }
        // this.service.GetApplicationInformation(this.Route.snapshot.params.AppID).subscribe((Response) => {
        //   if (this.currentUser.UserType === 'Lawyer'this.currentUser.UserType === 'Lawyer') {
        //     this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'Assignments', path: '/loan/assignment' },
        //     {
        //       label: `${Response.data[0].FirstName} ${Response.data[0].LastName}`
        //       , path: `/loan/assignment/${this.Route.snapshot.params.AppID}`
        //     }
        //       , { label: 'View Documents', path: '/', active: true }];
        //   } else if (this.currentUser.UserType === 'Bank Manager') {
        //     this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'Applications', path: 'loan/applications' },
        //     {
        //       label: `${Response.data[0].FirstName} ${Response.data[0].LastName}`,
        //       path: `/loan/view-pvr-list/${this.Route.snapshot.params.AppID}`
        //     }
        //       , { label: 'View Documents', path: '/', active: true }];
        //   }
        //   // this.url = res.data[0];
        //   this.url = Response.data[0];
        //   // this.url = this.url.FileURL.replace(".PDF" , ".pdf");
        //   this.data = res.data[0];
        //   this.dtOptions = {
        //     pagingType: 'full_numbers',
        //     pageLength: 5,
        //     // serverSide : true,
        //     paging: false,
        //     ordering: false,
        //     order: [[0, 'desc']]   // '0' is the table column(1st column) and 'desc' is the sorting order
        //   }
        //   this.filetype = res.data[0].FileType;
        //   this.isLoading = false;
        // });
      } 
        else if (this.Route.snapshot.params.AppID && this.Route.snapshot.params.DocType) {
       this.service.getDocument(this.Route.snapshot.params.propertyid, this.Route.snapshot.params.id).subscribe((res) => {

        this.getApplicationData();
    // this.service.GetApplicationInformation(this.Route.snapshot.params.AppID).subscribe((Response:any) => {
    //   if (this.currentUser.UserType === 'Lawyer') {
    //     this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'Assignments', path: '/loan/assignment' },
    //     {
    //       label: `${Response.data[0].FirstName} ${Response.data[0].LastName}`
    //       , path: `/loan/assignment/${this.Route.snapshot.params.AppID}`
    //     }
    //       , { label: 'View Documents', path: '/', active: true }];
    //   } else if (this.currentUser.UserType === 'Bank Manager') {
    //     this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'Applications', path: 'loan/applications' },
    //     {
    //       label: `${Response.data[0].FirstName} ${Response.data[0].LastName}`,
    //       path: `/loan/view-pvr-list/${this.Route.snapshot.params.AppID}`
    //     }
    //       , { label: 'View Documents', path: '/', active: true }];
    //   }
      this.url = res.data[0];
      if(this.url){
        this.url = this.url.replace(".PDF" , ".pdf");
      }
    //   this.data = res.data[0];
    //   this.dtOptions = {
    //     pagingType: 'full_numbers',
    //     pageLength: 5,
    //     // serverSide : true,
    //     ordering: false,
    //     order: [[0, 'desc']]   // '0' is the table column(1st column) and 'desc' is the sorting order
    //   }
      this.filetype = res.data[0].FileType;
      this.isLoading = false;
    // });
  });

      }
   
    }


  }
  getApplicationData(){

    this.service.getMultiplePropertyInfo(this.Route.snapshot.params.AppID).subscribe((Response:any)=>{
      // console.log('res',Response);
      this.applicationData = Response.data;
      if (this.currentUser.UserType === 'Lawyer') {
        this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'Assignments', path: '/loan/assignment' },
        {
          label: `${Response.data.ApplicatntDetails[0].FirstName} ${Response.data.ApplicatntDetails[0].LastName ? Response.data.ApplicatntDetails[0].LastName : ''}`
          , path: `/loan/tcc-dashboard-lawyer/${this.Route.snapshot.params.AppID}`
        }
          , { label: 'View Documents', path: '/', active: true }];
        this.isLoading =false;
        if(!this.isForMulProperty){
          this.url = Response.data.ApplicatntDetails[0];
        }
      } 
      else if (this.currentUser.UserType === 'Bank Manager') {
        // 
        if(Response.data.ApplicatntDetails[0].ApplicationStatus === 'TitleClear In Progress'){

          this.breadCrumbItems = [{ label: 'Dashboard', path: '/loan' }, { label: 'Applications', path: 'loan/title-clear-applications' },
          {
            label: `${Response.data.ApplicatntDetails[0].FirstName} ${Response.data.ApplicatntDetails[0].LastName ? Response.data.ApplicatntDetails[0].LastName : ''}`,
            path: `/loan/titleclear-dashbboard/${this.Route.snapshot.params.AppID}`
          }
            , { label: 'View Documents', path: '/', active: true }];

            if(!this.isForMulProperty){
              this.url = Response.data.ApplicatntDetails[0];
            }
        }
        else if(Response.data.ApplicatntDetails[0].ApplicationStatus === 'Title Clear Sent'){
          this.breadCrumbItems = [{ label: 'Dashboard', path: '/loan' }, { label: 'Applications', path: 'loan/title-clear-applications' },
             , { label: 'View Documents', path: '/', active: true }];
        }
        else{

          if(Response.data.Properties[0].StateID === 22){
            this.breadCrumbItems = [{ label: 'Dashboard', path: 'rj-applications' }, { label: 'Applications', path: '/rj-applications/Applications' },
            {
              label: `${Response.data.ApplicatntDetails[0].FirstName} ${Response.data.ApplicatntDetails[0].LastName ? Response.data.ApplicatntDetails[0].LastName :'' }`,
              path: `/rj-applications/Add-iPVR`
            }
              , { label: 'View Documents', path: '/', active: true }];
          }
          else{
            this.breadCrumbItems = [{ label: 'Dashboard', path: 'cg-applications' }, { label: 'Applications', path: '/cg-applications/Applications' },
          {
            label: `${Response.data.ApplicatntDetails[0].FirstName} ${Response.data.ApplicatntDetails[0].LastName ? Response.data.ApplicatntDetails[0].LastName :'' }`,
            path: `/cg-applications/View-Application`
          }
            , { label: 'View Documents', path: '/', active: true }];
          }
        
            // this.url = Response.data.ApplicatntDetails[0];
            if(!this.isForMulProperty){
              this.url = Response.data.ApplicatntDetails[0];
            }
        }
        this.isLoading= false;
      }
      else if (this.currentUser.UserType === 'Proplegit' ) {
      
           if(Response.data.ApplicatntDetails[0].ApplicationStatus === 'Title Clear Sent'){
            this.breadCrumbItems = [{ label: 'Dashboard', path: '/loan' }, { label: 'Applications', path: 'loan/title-clear-applications' },
               , { label: 'View Documents', path: '/', active: true }];
          }
          if(Response.data.Properties[0].StateID === 22){
            this.breadCrumbItems = [{ label: 'Dashboard', path: 'rj-applications' }, { label: 'Applications', path: '/rj-applications/Applications' },
            {
              label: `${Response.data.ApplicatntDetails[0].FirstName} ${Response.data.ApplicatntDetails[0].LastName ? Response.data.ApplicatntDetails[0].LastName :'' }`,
              path: `/rj-applications/Add-iPVR`, active: true
            }
              , { label: 'View Documents', path: '/', active: true }];
          }
          else{
            this.breadCrumbItems = [{ label: 'Dashboard', path: 'cg-applications' }, { label: 'Applications', path: '/cg-applications/Applications' },
          {
            label: `${Response.data.ApplicatntDetails[0].FirstName} ${Response.data.ApplicatntDetails[0].LastName ? Response.data.ApplicatntDetails[0].LastName :'' }`,
            path: `/cg-applications/View-Application`,active: true
          }
            , { label: 'View Documents', path: '/', active: true }];
          };
          if(!this.isForMulProperty){
            this.url = Response.data.ApplicatntDetails[0];
          }
          this.isLoading = false;
      }
      this.isLoading = false;
      // this.FileUrl = this.appData.data.ApplicatntDetails[0].FileUrl
      // this.data = Response.data[0];
    })

  }
  download(url){
    window.open(`${url}`,'_blank')
  }
  ngOnDestroy(){
    localStorage.removeItem('DocumentIndex');
    localStorage.removeItem('PropertyIndex');
    localStorage.removeItem('isFromAddIPVR');
  }
  getDocComment() {
    this.commentlistLoader = true;
    this.service.GetDocumentComment(this.Route.snapshot.params.id).subscribe((res) => {
      this.commentlist = res.data;
      this.commentlistLoader = false;
      // console.log(this.commentlist);
    });
  }
  reviewed() {
    if (this.commentlist.length === 0) {
      Swal.fire({
        title: '',
        text: 'You must add a Comment before Marking the Document as Reviewed',
        type: 'error',
        showConfirmButton: false,
        timer: 3000
      }).then(() => {
        return;
      });
    } else {
      this.isLoading = true;
      if (this.status == 'Reviewed') {
        const data = {
          AppID: this.Route.snapshot.params.AppID,
          DocumentID: this.Route.snapshot.params.id,
          Comment: this.comment,
          isReviewed: 1,
          PropertyID:this.Route.snapshot.params.propertyid
        }
        this.service.addcomments(data).subscribe((res): any => {
          this.isLoading = false;
          Swal.fire({
            title: 'Success', showCancelButton: false,
            text: 'Document Comment added successfully',
            type: 'success',
            showConfirmButton: false,
            timer: 3000
          }).then(() => {
            location.reload();
          });
        });
      } else {
        this.service.MarkAsReviewed(this.Route.snapshot.params.propertyid, this.Route.snapshot.params.id).subscribe((res) => {
          this.isLoading = false;
          if (res.status == 200) {
            Swal.fire({
              title: 'Success',
              text: 'Document is Reviewed',
              type: 'success',
              showConfirmButton: false,
              timer: 3000
            }).then(() => {
              this.router.navigate(['/loan/tcc-dashboard-lawyer/' + this.AppID]);
            });
          } else {
            Swal.fire({
              title: res.error_code,
              text: res.message,
              type: 'error',
              showConfirmButton: false,
              timer: 3000
            });
          }
        }, (error): any => {
          this.isLoading = false;
          Swal.fire({
            title: error.error_code,
            text: error.message,
            type: 'error',
            showConfirmButton: false,
            timer: 3000
          });
        });
      }
    }

  }
  addComment() {
    this.submitted = true;
    this.comment = this.comment.trim();
    if (this.comment == '') {
      return;
    }
    this.isLoading = true;
    const data = {
      AppID: this.Route.snapshot.params.AppID,
      DocumentID: this.Route.snapshot.params.id,
      Comment: this.comment,
      isReviewed: 1,
      isActive: 1,
      PropertyID:this.Route.snapshot.params.propertyid
    }
    this.service.addcomments(data).subscribe((res): any => {
      this.submitted = false;
      this.isLoading = false;
      if (res.status == 200) {
        Swal.fire({
          title: 'Success', showCancelButton: false,
          text: 'Document Comment added successfully',
          type: 'success',
          showConfirmButton: false,
          timer: 3000
        }).then(() => {
          this.isShowAdd = false;
          this.getDocComment();
        });
      } else {
        Swal.fire({
          title: res.error_code,
          text: res.message,
          type: 'error',
          showConfirmButton: false,
          timer: 3000
        });
      }
    }, (error): any => {
      this.isLoading = false;
      this.submitted = false;
      Swal.fire({
        title: error.error_code,
        text: error.message,
        type: 'error',
        showConfirmButton: false,
        timer: 3000
      });
    });
  }
  changeCommentStatus(value, element) {
    this.isLoading = true;
    const data = {
      Comment: element.Comment,
      isReviewed: 1,
      isActive: value
    }
    let text = 'Status Updated successfully'
    if (value === 1) {
      text = 'Comment Added Suceessfull';
    }
    else {
      text = 'Comment Deleted Suceessfully'
    }
    this.service.updatecomments(data, element.DocumentReviewID).subscribe((res): any => {
      this.isLoading = false;
      if (res.status == 200) {
        Swal.fire({
          title: 'Success',
          text: text,
          type: 'success',
          showConfirmButton: false,
          timer: 3000
        }).then(() => {
          this.isShowAdd = false;
          this.getDocComment();
        });
      } else {
        Swal.fire({
          title: res.error_code,
          text: res.message,
          type: 'error',
          showConfirmButton: false,
          timer: 3000
        });
      }
    }, (error): any => {
      this.isLoading = false;
      Swal.fire({
        title: error.error_code,
        text: error.message,
        type: 'error',
        showConfirmButton: false,
        timer: 3000
      });
    });
  }
  addnewComment() {
    this.isShowAdd = true;
    this.comment = '';
  }

  openConfirmationDialog(element: any) {
    const dialogRef = this.dialog.open(ConfimDialogComponent, {
      disableClose: true
    });
    dialogRef.componentInstance.data = { title: 'Are you sure you want to delete comment?', acceptBtn: 'Yes', cancelBtn: 'No' }

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.changeCommentStatus(0, element);
      }
    });
  }

}
