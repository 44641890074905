import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { RequestDocumentDialogComponent } from '../request-document-dialog/request-document-dialog.component';
import { MatDialog } from '@angular/material';
import { DatePipe } from '@angular/common';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

interface Document {
  ID: number;
  PropertyID: string;
  DocumentName: string;
}

@Component({
  selector: 'app-tcc-dashboard-lawyer',
  templateUrl: './tcc-dashboard-lawyer.component.html',
  styleUrls: ['./tcc-dashboard-lawyer.component.scss']
})
export class TccDashboardLawyerComponent implements OnInit {

  breadCrumbItems: any;
  AppID: string;
  applicationData: any;
  isLoading: boolean;
  firstName: string;
  lastName: string;
  currentUser: any;
  StateName: any;
  owners: any[] = [];
  ApplicationID: any;
  TotalLandSize: any;
  lawyerInfo: any;
  lawyerId: string;
  lawyerDetails: any;
  PropertyID: any;
  DocumentID: any;
  BankManagerDetails: any;
  LoanAmount: number;
  Type_of_Loan: string;
  DocumentList: any[] = [];
  isallDocumentsReviewed: boolean;
  isPublicNotice:boolean = true;ApplicationStatus: any;
  LawyerID: any;
  Properties: any;
  propertyIds: any;
  doclist: any[] = [];
  appData: any;
  appID: any;
  FileUrl: any;
;
  dtOptions1: DataTables.Settings = {pageLength: 5};
  dtOptions: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  dtOptions3: DataTables.Settings = {};
  documents: any[] = [];
  noticelist:any[] = [];
  arrayOfDocumentArrays: any;
  @ViewChild(DataTableDirective, { static: false })
  dtElement1: DataTableDirective;
  dtTrigger1 = new Subject<any>();
  isallNoticeExpired: boolean;
  isNoticeActive: boolean;
  constructor(private dialog:MatDialog,private router: Router, private service: GeneralService, private Route: ActivatedRoute, private http: HttpClient
    ,
    
    private datepipe:DatePipe) {
    this.AppID = this.Route.snapshot.params.AppID;
    this.currentUser = this.service.getcurrentUser();
    this.dtOptions= {
      paging: true,
      searching: true,
      ordering: false,
      info: false,
      serverSide: false,
      pageLength: 10,
      pagingType: "simple_numbers"
    }
    this.dtOptions2 = {
      paging: false,
      searching: false,
      ordering: false,
      info: false,
      pageLength: 10
    }
    
    
  }

  ngOnInit() {
  
    this.breadCrumbItems = [{ label: 'Dashboard', path: '/' },
    { label: 'Assignments', path: '/loan/assignment' }, { label: 'Title Search', path: '/', active: true }];
    this.getApplicationData();
    this.isLoading = true;
    this.service.getMultiplePropertyInfo(this.AppID).subscribe((res) => {
      this.applicationData = res;
      console.log("application data", this.applicationData);
      this.isLoading = false;
      this.owners = this.applicationData.data.PropertyOwnerShip;
      this.firstName = this.applicationData.data.ApplicatntDetails[0].FirstName;
      this.lastName = this.applicationData.data.ApplicatntDetails[0].LastName;
      this.StateName = this.applicationData.data.Properties[0].StateName;
      this.ApplicationID = this.applicationData.data.ApplicatntDetails[0].ApplicationID;
      this.TotalLandSize = this.applicationData.data.ApplicatntDetails[0].TotalLandSize
      // this.PropertyID = this.applicationData.data.Properties[0].PropertyID;
      this.ApplicationStatus = this.applicationData.data.ApplicatntDetails[0].ApplicationStatus
      this.LawyerID = this.applicationData.data.ApplicatntDetails[0].LawyerID;
      console.log('lawyerid',this.LawyerID, this.ApplicationStatus)
      this.LoanAmount = this.applicationData.data.ApplicatntDetails[0].LoanAmount;
      this.Type_of_Loan = this.applicationData.data.ApplicatntDetails[0].Type_of_Loan;
      // this.DocumentID = this.applicationData.data.Properties[0].Documents[0].DocumentID
      this.AppID = this.applicationData.data.ApplicatntDetails[0].AppID
      // this.applicationData = this.applicationData.data.Properties.Documents[0].DocumentID
      this.Properties = this.applicationData.data.Properties;
      this.lawyerId = this.applicationData.data.ApplicatntDetails[0].LawyerID
      // if (this.applicationData.data.ApplicatntDetails[0].LawyerID) {
      //   this.service.viewLawyer(this.applicationData.data.ApplicatntDetails[0].LawyerID).subscribe((lawyerInfo) => {
      //     this.lawyerInfo = lawyerInfo.data[0];
      //     console.log("lawyer Info",this.lawyerInfo);
      //     // this.IsLawyerLoaded = true;
      //   });
      // }

      this.getLawyerDetails(this.lawyerId);
      this.getBankManagerDetails(this.currentUser.UserID);

      this.getTCDocuments();
    })

    // this.isLoading = true;
    
   
   
    this.dtOptions1 = {
      pagingType: "simple_numbers",
      pageLength: 10,
      ordering: false,
      serverSide:false,
      // processing:true,
      paging: false,
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .get<DataTablesResponse>(
            this.service.GetBaseUrl() + `tcc/loan/application/Documents/AppID/${this.Route.snapshot.params.AppID}`,
            {}
          ).subscribe((resp: any) => {
            const list = resp.data;
            if(resp.data['null']){
              this.DocumentList = resp.data['null'];
            }
            this.arrayOfDocumentArrays = resp.data;
            this.propertyIds = this.Properties.map((d) => { return d.PropertyID });
            // let doclist = [];
            this.propertyIds.forEach((id) => {
              list[id].forEach((doc) => {
                this.doclist.push(doc);
              })
            });
            this.DocumentList.forEach((ele)=>{
              this.doclist.push(ele);
            })
            console.log('document list',this.doclist);
            // console.log('arrayOfEntries',this.arrayOfDocumentArrays["1188140"]);
            // for(let i = 0; i< this.arrayOfDocumentArrays["1188140"].length;i++){
            //   console.log('i',this.arrayOfDocumentArrays["1188140"][i]);
            // }
            this.isallDocumentsReviewed = this.Compaire();
            console.log('isallodoucreveir',this.isallDocumentsReviewed);
            if (this.isallDocumentsReviewed == true && this.applicationData &&this.applicationData.data.ApplicatntDetails[0].ApplicationStatus == 'TC Document In Progress') {
              this.updateAppStatus('TC Document Completed');
            }
            console.log(this.isallDocumentsReviewed);
            console.log('list',this.DocumentList);
            console.log('array of documentd',this.arrayOfDocumentArrays);
            callback({
              recordsTotal:this.arrayOfDocumentArrays.length,
              recordsFiltered:this.arrayOfDocumentArrays.length,
              data: this.arrayOfDocumentArrays
            });
          });
      },
      responsive: true,
      columns: [{
        title: 'Sr No.', data: 'row', render: (data, type, row, meta) => {
          return meta.row + 1;
        }
      }, {
        title: 'Document',
        render: (data, type, row) => {
          return row.DocumentName.split(`.PDF`).join("");

        }
      }, {
        title: 'Status',
        render: (data, type, row) => {
          if (row.Status === 'Pending') {
            return `<span class="badge badge-danger p-1">${row.Status}</span>`;
          } else if (row.Status === 'Received') {
            return `<span class="badge badge-primary p-1">${row.Status}</span>`;
          } else if (row.Status === 'requested' || row.Status === 'Requested') {
            return `<span class="badge bg-soft-warning text-warning p-1">${row.Status}</span>`;
          } else {
            return `<span class="badge badge-success p-1">${row.Status}</span>`;
          }
        }
      }, {
        title: 'Action',
        data: null
      },
      {
        title: 'Comment',
        render: (data, type, row) => {
          let comment = '';
          if (row.DocumentComment != undefined) {
            row.DocumentComment.forEach(element => {
              comment += element.Comment + '<br>';
            });
            return comment;
          }
          else {
            return '';
          }
        },
      }
      ],
      columnDefs: [{ targets: 3, orderable: false }],
      rowCallback(row, data: any) {
        let btn = '';
        if (data.FileURL === null &&  this.ApplicationStatus !== 'Title Clear Sent') {
          btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
          btn += '<i class="mdi mdi-file-upload-outline font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
          btn += '</a>';
          btn += '<a href="javascript:void(0)" class="requestDocument m-1" title="Request this Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
          btn += '<i class="mdi mdi mdi-file-question font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
          btn += '</a>';
        } else if (data.FileURL !== null) {
          if (data.Status !== 'Reviewed' && this.ApplicationStatus !== 'Title Clear Sent') {
            btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
            btn += '<i class="mdi mdi-file-upload-outline font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
            btn += '</a>';
            btn += '<a href="javascript:void(0)" class="requestDocument m-1" title="Request this Document" DocumentName="' + data.DocumentName + '">';
            btn += '<i class="mdi mdi mdi-file-question font-18 text-secondary" aria-hidden="false" DocumentName="' + data.DocumentName + '"></i>';
            btn += '</a>';
          }
          btn += '<a href="javascript:void(0)" class="viewDocument m-1" title="View Document" receipt-id="' + data.DocumentID + '">';
          btn += '<i class="mdi mdi-eye font-18 text-secondary" aria-hidden="false" receipt-id="' + data.DocumentID + '"></i>';
          btn += '</a>';
          if (data.dropdownitemtype === 'multiple') {
            btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Another Document" receipt-id="' + data.DocumentID + '">';
            btn += '<i class="mdi mdi-plus font-18 text-secondary" aria-hidden="false" receipt-id="' + data.DocumentID + '"></i>';
            btn += '</a>';
          }
        }
        if (data.isMandatory == true) {
          btn += '<a title="Mark as Non-Mandatory" class="update m-1" >'
          btn += '<i class="font-18 mdi mdi-cancel text-secondary"  DocumentID="' + data.DocumentID + '" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" isMandatory="0"></i>'
          btn += '</a>';
        } else {
          btn += '<a title="Mark as Mandatory" class="update m-1" >'
          btn += '<i class="font-18 mdi mdi-recycle  text-secondary"  DocumentID="' + data.DocumentID + '"DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" isMandatory="1"></i>'
          btn += '</a>';
        }
        $('td:eq(3)', row).html(btn);
      }, drawCallback: () => {
        $('.uploadDocument').on('click', (e) => {
          this.onUploadDocument($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentName'),'');
        });
        $('.viewDocument').on('click', (e) => {
          this.onViewDocument($(e.target).attr('receipt-id'),'');
        });
        $('.requestDocument').on('click', (e) => {
          this.openRequestDocumentDialog($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType',), $(e.target).attr('DocumentName'),'');
        });
        $('.update').on('click', (e) => {
          console.log('UpdateDocument , is mandatory',$(e.target).attr('isMandatory'))
          this.UpdateDocument($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentID'), $(e.target).attr('isMandatory'), this.applicationData.data.Properties[0].PropertyID);
        });
      }
    };
   


    


  }


  getTCDocuments() {
    this.isLoading = true;
    this.service.getTCDocumentsLawyer(this.AppID).subscribe((res: any) => {
      console.log('res', res);
      if (res.error) {
        Swal.fire({
          title: res.error_code,
          text: res.message,
          type: 'error',
          showConfirmButton: false,
          timer: 3000
        });
      }
      else {
        this.documents = res.data;
        console.log('this.documents', this.documents);
      }
      this.isLoading = false;
    })
  }
  getLawyerDetails(LawyerID) {
    this.isLoading = true;
    this.service.GetUser(LawyerID).subscribe((res) => {
      this.isLoading = false;
      this.lawyerDetails = res.data[0];
      console.log(this.lawyerDetails);
    }, (error) => {
      this.isLoading = false;
    });
  }

  seeFullReport(PropertyID, DocumentID, appID) {
    if (DocumentID) {
      this.router.navigate(['/loan/viewdocument/' + PropertyID + '/' + DocumentID + '/' + appID + '/ipvr']);
    }
    else {
      return;
    }
  }
  onUploadDocument(doctype, docsubtype, docname,propertyIndex) {
    this.router.navigate(['loan/uploadlawyerdocument/' +this.applicationData.data.ApplicatntDetails[0].AppID], { queryParams: { DocumentType: doctype, DocumentSubType: docsubtype, DocumentName: docname, PropertyIndex:propertyIndex ? propertyIndex : 0} });
  }
  onViewDocument(id,propertyIndex,data?) {
    this.router.navigate(['loan/viewdocument/' + this.applicationData.data.Properties[propertyIndex].PropertyID + '/' + id + '/' + this.applicationData.data.ApplicatntDetails[0].AppID],{queryParams:{isForMulProperty: true}});
  }
  uploadOtherDocument() {
    this.router.navigate(['loan/uploadlawyerdocument/' + this.applicationData.data.ApplicatntDetails[0].AppID], { queryParams: { OtherDocument: true } });
  }
  openRequestDocumentDialog(doctype, docsubtype, docname,PropertyID): void {
    console.log('request another document is cllaedd')
    const dialogRef = this.dialog.open(RequestDocumentDialogComponent, {
      disableClose: true,
      width: '480px',
      data: {
        AppID: this.Route.snapshot.params.AppID,
        DocumentSubType: docsubtype,
        DocumentType: doctype,
        DocumentName: docname,
        PropertyID: PropertyID,
        DropDownValue:
          [
            { ID: this.applicationData.data.ApplicatntDetails[0].ApplicantID, Name: 'Applicant' },
            { ID: this.applicationData.data.ApplicatntDetails[0].CreatedBy, Name: 'Bank Manager' },
            { ID: '0', Name: 'Proplegit Admin' },
          ]
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.ngOnInit();
        this.rerender();
      }
    });
  }

  ngAfterViewInit() {
    this.dtTrigger1.next();
  }

  rerender(): void {
    this.dtElement1.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger1.next();
    });
  }

  UpdateDocument(DocType, DocSubType, DocID, isMemdatory,PropertyID?) {
    let data: any;
    console.log('doc id',DocID);
    console.log('PropertyID',PropertyID);
    console.log('is manadatory from function',isMemdatory);
    if (DocID === null || DocID === 'null' || DocID === '' || DocID === undefined) {
      data = {
        "PropertyID": PropertyID,
        "UserID": this.currentUser.UserID,
        "DocumentTypeId": DocType,
        "DocumentID": '',
        "DocumentSubTypeId": DocSubType,
        "isMandatory": Number(isMemdatory)
      };
    } else {
      data = {
        "PropertyID": PropertyID,
        "UserID": this.currentUser.UserID,
        "DocumentID": DocID,
        "DocumentTypeId": '',
        "DocumentSubTypeId": '',
        "isMandatory":Number(isMemdatory)
      };
    }
    this.isLoading = true;
    console.log(data);
    this.service.UpdateDocument(data).subscribe((res) => {
      this.isLoading = false;
      if (res.status == 200) {
        this.updateAppStatus('TC Document In Progress')
        Swal.fire({
          title: `Success`,
          text: `${isMemdatory == 0 ? 'Document has been Marked as Non Mandatory.' : 'Document has been Marked as Mandatory.'}`,
          type: 'success',
          showConfirmButton: false,
          timer: 3000
        }).then(() => { this.ngOnInit(); })
      } else {
        Swal.fire({
          title: `Error`,
          text: res.message,
          type: 'error',
          showConfirmButton: false,
          timer: 3000
        }).then(() => { });
      }
    }, (error) => {
      this.isLoading = false;
      Swal.fire({
        title: `Error`,
        text: 'Internal Server Error',
        type: 'error',
        showConfirmButton: false,
        timer: 3000
      }).then(() => { });
    });
  }

updateAppStatus(status) {
    let data = {
      applicationStatus: status
    }
    this.isLoading = true;
    console.log(data);
    this.service.updateAppStatus(this.Route.snapshot.params.AppID, data).subscribe((res) => {
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
    });
    this.isLoading = false;
  }

  getBankManagerDetails(BankID) {
    this.isLoading = true;
    this.service.GetUser(BankID).subscribe((res) => {
      this.isLoading = false;
      this.BankManagerDetails = res.data[0];
      console.log('BankManagerDetails', this.BankManagerDetails);
    });
  }

  proceedTitleClear() {
    this.router.navigate(['loan/titleclear/' + this.AppID]);
  }

  generateCommentsHtml(comments: any[]): string {
    // console.log('comments',comments);
    let html = '';
    if (comments) {
      comments.forEach((element, index) => {
        html += element.Comment;
        if (index !== comments.length - 1) {
          html += ', ';
        }
      });
    }
    return html;
  }

  CompaireNotice() {
    return this.noticelist.every(x => x.isExp === '1' || x.isExp === 1);
  }
  addnotice(propertyID:string) {
    this.router.navigate([`/loan/addPublicNotice/`+this.Route.snapshot.params.AppID],{queryParams: {PropertyID : propertyID }});
  }
  
  addResponse() {
    this.router.navigate(['/loan/viewpastpublicnotice/' + this.Route.snapshot.params.AppID]);
  }

  Compaire() {
    let MandatoryDocumentList: any = [];
    this.doclist.forEach((element) => {
      if (element.isMandatory === true) {
        MandatoryDocumentList.push(element);
      }
    });
    return MandatoryDocumentList.every(x => x.Status === 'Reviewed');
  }
  getApplicationData(){
    this.isLoading = true;
    this.service.getMultiplePropertyInfo(this.AppID).subscribe((res)=>{
      this.appData = res
      this.applicationData = res;
      this.isLoading=false;
      this.owners = this.appData.data.PropertyOwnerShip;
      this.firstName = this.appData.data.ApplicatntDetails[0].FirstName;
      this.lastName =  this.appData.data.ApplicatntDetails[0].LastName;
      this.StateName = this.appData.data.Properties[0].StateName;
      this.ApplicationID =  this.appData.data.ApplicatntDetails[0].ApplicationID;
      this.PropertyID = this.appData.data.Properties[0].PropertyID
      this.DocumentID = this.appData.data.Properties[0].Documents[0].DocumentID
      this.appID = this.appData.data.ApplicatntDetails[0].AppID
      this.TotalLandSize =this.appData.data.ApplicatntDetails[0].TotalLandSize;
      this.FileUrl = this.appData.data.ApplicatntDetails[0].FileURL
      this.ApplicationStatus = this.appData.data.ApplicatntDetails[0].ApplicationStatus
     
      // this.loaded = false;
      // this.applicationData = this.appData.data.Properties.Documents[0].DocumentID
      // console.log('Document ID',this.appData.data.Properties.Documents.DocumentID)
      // console.log('Property ID', this.appData.data.Properties.PropertyID)
    })

  }
}


