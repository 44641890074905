import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Action } from 'rxjs/internal/scheduler/Action';
import { GeneralService } from 'src/app/services/general.service';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  dtOptions: DataTables.Settings = {};
  dtOptionsAdmin: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective | undefined;
  dtTrigger = new Subject<any>();
  isLoadingTable: boolean;
  inputValue: string = '';
  applications: any;
  currentUser: any;

  constructor(private http: HttpClient, private service: GeneralService, private renderer: Renderer2, private router: Router) {
    this.breadCrumbItems = [{ label: 'Dashboard', path: '/' },
    { label: 'POS Dashboard', path: '/', active: true }];
    this.currentUser = this.service.getcurrentUser();
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: "simple_numbers",
      pageLength: 10,
      serverSide: true,
      searching: false,
      ordering: false,
      // processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.isLoadingTable = true;
        this.http
          .get<DataTablesResponse>(

            this.service.GetBaseUrl() +
            `POS/list` +
            `?pageNo=${(dataTablesParameters.start + dataTablesParameters.length) /
            dataTablesParameters.length
            }` +
            `${dataTablesParameters.length.value != ""
              ? "&limit=" + dataTablesParameters.length
              : ""
            }` +
            `${this.inputValue != "" ? "&searchkey=" + this.inputValue : ""
            }`,

            {}
          )
          .subscribe((resp: any) => {
            // console.log("line==199", resp)
            this.isLoadingTable = false;
            this.applications = resp.data;
            callback({
              recordsTotal: resp.allCount,
              recordsFiltered: resp.count,
              data: this.applications,
            });
          });
      },
      columns: [
        {
          title: "Application ID",
          name: "ApplicationID",
          data: "ApplicationID",
        },
        {
          title: "Owner Name",
          name: "owners",
          data: null,
          render: (atten, type, row) => {
            let OwnerName = ''
            row.owners.forEach((atten,i) => {
              if (OwnerName !== null || OwnerName.length !== 0 || row !== undefined) {
                if(i === 0){
                  OwnerName = atten.owner_name ;
                }
                else{
                  OwnerName = OwnerName + ', ' + atten.owner_name;
                }
              } 
             })
            // if (row !== null || row.length != 0 || row !== undefined) {
            //   return OwnerName;
            // }
            return OwnerName ? OwnerName : "--";
          },
        },
        {
          title: "Comment",
          name: "comment",
          data: "comment",
          render: (data, type, row) => {
            const style = data && data.length > 20 ? 'display: block; width: 250px; overflow-wrap: break-word;text-align: justify' : '';
            return `<span style="${style}">${data ? data : '--'}</span>`;
          }
        },
        {
          title: "Status",
          name: "status",
          data: "status",
          render: (data, type, row) => {

            if (data === 'POS Completed') {
              return `<span class="badge badge-success p-1">${data}</span>`;
            } else if (data === 'POS In Progress') {
              return `<span class="badge badge-secondary p-1">${data}</span>`;
            } else {
              return data;
            }
          }
        },
        {
          title: "Action",
          data: null,
          render: function (data, type, row) {
            if (row.status === 'POS Completed') {
              return `<a class="text-primary" title="View Application"
              viewID = "${row.POS_AppID}"><i class=" m-1 ml-3 mdi mdi-eye font-18 text-secondary" viewID = "${row.POS_AppID}"  
              appData='${JSON.stringify(row)}'  aria-hidden="false"></i></a>`;
            }
            else{
              return  `<span> <a style='color:gray' class='m-1 ml-3' > NA </a></span>`
            }

            

          }
        },
      ],
      order: [[0, "desc"]],
      columnDefs: [
        { targets: 0, visible: true },
        { targets: 3, orderable: false },
      ],
    };

    this.dtOptionsAdmin = {
      pagingType: "simple_numbers",
      pageLength: 10,
      serverSide: true,
      searching: false,
      ordering: false,
      // processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.isLoadingTable = true;

        this.http
          .get<DataTablesResponse>(
            this.service.GetBaseUrl() +
            `POS/list` +
            `?pageNo=${(dataTablesParameters.start + dataTablesParameters.length) /
            dataTablesParameters.length
            }` +
            `${dataTablesParameters.length.value != ""
              ? "&limit=" + dataTablesParameters.length
              : ""
            }` +
            `${this.inputValue != "" ? "&searchkey=" + this.inputValue : ""
            }`,
            {}
          )
          // APplicaid,ownername,status , Action
          .subscribe((resp: any) => {
            this.isLoadingTable = false;
            this.applications = resp.data;
            callback({
              recordsTotal: resp.allCount,
              recordsFiltered: resp.count,
              data: this.applications,
            });
          });
      },

      columns: [
        {
          title: "APP ID",
          name: "POS_AppID",
          data: "POS_AppID",
        },
        {
          title: "Application ID",
          name: "ApplicationID",
          data: "ApplicationID",
        },
        {
          title: "Owner Name",
          name: "owners",
          data: null,
          render: (atten, type, row) => {
            let OwnerName = ''
            row.owners.forEach((atten,i) => {
              if (OwnerName !== null || OwnerName.length !== 0 || row !== undefined) {
                if(i === 0){
                  OwnerName = atten.owner_name ;
                }
                else{
                  OwnerName = OwnerName + ', ' + atten.owner_name;
                }
              } 
             })
            return OwnerName ? OwnerName : "--";
          },
        },
        {
          title: "Comment",
          name: "comment",
          data: "comment",
          render: (data, type, row) => {
            const style = data && data.length > 20 ? 'display: block; width: 250px; overflow-wrap: break-word;text-align: justify' : '';
            return `<span style="${style}">${data ? data : '--'}</span>`;
          }
        },
        {
          title: "Status",
          name: "status",
          data: "status",
          render: (data, type, row) => {
            if (data === 'POS Completed') {
              return `<span class="badge badge-success p-1">${data}</span>`;
            } else if (data === 'POS In Progress') {
              return `<span class="badge badge-secondary p-1">${data}</span>`;
            } else {
              return data;
            }
          }
        },
        {
          title: "Action",
          data: null,
          render: function (data, type, row) {
            // if (row.status === 'POS In Progress') {
              // return `<a class="text-primary" title="Update Application"
              // ><i class=" m-1 ml-3 mdi mdi-update font-18 text-secondary" updateAppID = "${row.AppID}"  status='${row.status}'
              // appData='${JSON.stringify(row)}'  aria-hidden="false"></i></a>`;
            // }
            if (row.status === 'POS In Progress') {
              return `<a class="text-primary" title="Update Application"
              ><i class=" m-1 ml-3 mdi mdi-update font-18 text-secondary" updateAppID = "${row.POS_AppID}"  status='${row.status}'
              appData='${JSON.stringify(row)}'  aria-hidden="false"></i></a>`;
            }
            else if (row.status === 'POS Completed') {
              return `<a class="text-primary" title="View Application"
              viewID = "${row.POS_AppID}"><i class=" m-1 ml-3 mdi mdi-eye font-18 text-secondary" viewID = "${row.POS_AppID}"  
              appData='${JSON.stringify(row)}'  aria-hidden="false"></i></a>`;
            }
            
          }
        },
      ],
      order: [[0, "desc"]],
      columnDefs: [
        { targets: 0, visible: true },
        { targets: 5, orderable: false },
      ],
    };


  }
  onSearch() {
    this.rerender();
  }
  onchangeSearch() {
    // console.log(this.inputValue);
    if (this.inputValue != "") {
      return;
    }
    this.rerender();
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.renderer.listen('document', 'click', (event) => {
      if (event.target.hasAttribute('viewID')) {
        let appData = JSON.parse(event.target.getAttribute('appData'));
        // console.log("appdata",appData);
        localStorage.setItem('POSAppID',event.target.getAttribute('viewID'))
        localStorage.setItem('AppData', event.target.getAttribute('appData'))
        this.router.navigate(['/POS/view-POS-Application'], {state:{appData: appData}});
      }
      else if(event.target.hasAttribute('updateAppID')){
        let appData = JSON.parse(event.target.getAttribute('appData'));
        // console.log("appdata",appData);
        localStorage.setItem('POSAppID',event.target.getAttribute('updateAppID'))
        localStorage.setItem('AppData', event.target.getAttribute('appData'))
        this.router.navigate(['/POS/Add-Shares-Per-Owner'], {state:{appData: appData}}); 
      }
    })
  
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }


}
