import { CookieService } from './../../core/services/cookie.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';

import { AuthenticationService } from '../../core/services/auth.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  notificationItems: Array<{}>;
  languages: Array<{
    id: number,
    flag?: string,
    name: string
  }>;
  selectedLanguage: {
    id: number,
    flag?: string,
    name: string
  };
  isLoading: boolean;
  data: any;
  openMobileMenu: boolean;
  currentUser: any;
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  RequestedDocumentCount: any;
  interval: NodeJS.Timer;
  id: any;
  assignmentCount: any;

  constructor(private router: Router, private service: GeneralService, private cookie: CookieService) { }

  ngOnInit() {
    this.isLoading = true;
    this.currentUser = this.service.getcurrentUser();
    // console.log(this.currentUser);
    this.service.GetUser(this.currentUser.UserID).subscribe((Res) => {
      this.data = Res.data[0];
      this.isLoading = false;
    })
    // get the notifications
    this._fetchNotifications();
    this.openMobileMenu = false;
    this.startTimer();
  }
  startTimer() {
    let limit;
    limit = 2000;
    if(this.currentUser.UserType == 'Proplegit') {
      this.id = 0;
    } else {
      this.id = this.currentUser.UserID;
    }
    this.interval = setInterval(() => {
      // if (this.timeLeft > 0) {
      //   this.timeLeft--;
      // }
      // if (this.timeLeft === 0) {
      //   this.isTimeOut = true;
      // }
      if(this.currentUser.UserType !== 'Lawyer') {
        this.getRequestedDocumentList();
      } else {
        this.service.newAssignmentCount('?LawyerID=' + this.currentUser.UserID + '&ApplicationStatus=TitleClear In Progress').subscribe((res) => {
          this.assignmentCount = res.data.counts;
        });
      }
    }, limit);
  }
  /**
   * Change the language
   * @param language language
   */
  changeLanguage(language) {
    this.selectedLanguage = language;
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.service.logout();
    this.router.navigate(['/auth/login']);
  }

  /**
   * Fetches the notification
   * Note: For now returns the hard coded notifications
   */
  _fetchNotifications() {
    this.notificationItems = [{
      text: 'Caleb Flakelar commented on Admin',
      subText: '1 min ago',
      icon: 'mdi mdi-comment-account-outline',
      bgColor: 'primary',
      redirectTo: '/notification/1'
    },
    {
      text: 'New user registered.',
      subText: '5 min ago',
      icon: 'mdi mdi-account-plus',
      bgColor: 'info',
      redirectTo: '/notification/2'
    },
    {
      text: 'Cristina Pride',
      subText: 'Hi, How are you? What about our next meeting',
      icon: 'mdi mdi-comment-account-outline',
      bgColor: 'success',
      redirectTo: '/notification/3'
    },
    {
      text: 'Caleb Flakelar commented on Admin',
      subText: '2 days ago',
      icon: 'mdi mdi-comment-account-outline',
      bgColor: 'danger',
      redirectTo: '/notification/4'
    },
    {
      text: 'Caleb Flakelar commented on Admin',
      subText: '1 min ago',
      icon: 'mdi mdi-comment-account-outline',
      bgColor: 'primary',
      redirectTo: '/notification/5'
    },
    {
      text: 'New user registered.',
      subText: '5 min ago',
      icon: 'mdi mdi-account-plus',
      bgColor: 'info',
      redirectTo: '/notification/6'
    },
    {
      text: 'Cristina Pride',
      subText: 'Hi, How are you? What about our next meeting',
      icon: 'mdi mdi-comment-account-outline',
      bgColor: 'success',
      redirectTo: '/notification/7'
    },
    {
      text: 'Caleb Flakelar commented on Admin',
      subText: '2 days ago',
      icon: 'mdi mdi-comment-account-outline',
      bgColor: 'danger',
      redirectTo: '/notification/8'
    }];
  }
  getRequestedDocumentList() {
     
    this.service.getRequestedDocumentCount(this.id).subscribe((res) => {
      this.RequestedDocumentCount = res.data[0].counts;
    });
  }
}
