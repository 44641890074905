import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-last-pppalert-document',
  templateUrl: './view-last-pppalert-document.component.html',
  styleUrls: ['./view-last-pppalert-document.component.scss']
})
export class ViewLastPPPAlertDocumentComponent implements OnInit {
  fileUrl:string;
  breadCrumbItems: Array<{}>;
  AppID:string;
  firstName:string;
  constructor(private router: Router) {
    let a = this.router.getCurrentNavigation().extras;
    if(a){
      if(a.state){
        this.fileUrl = a.state.fileUrl;
        this.firstName = a.state.firstName
        this.AppID = a.state.AppID;
        localStorage.setItem('fileUrl',this.fileUrl);
        localStorage.setItem('FirstName',this.firstName);
        localStorage.setItem('AppID',this.AppID);
      }
    }
    if(localStorage.getItem('fileUrl')){
      this.fileUrl = localStorage.getItem('fileUrl');
      this.firstName = localStorage.getItem('FirstName');
      this.AppID = localStorage.getItem('AppID');
    }
    if(localStorage.getItem('AppID')){
      this.AppID = localStorage.getItem('AppID');
    }
   }

  ngOnInit() {
    this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'Applications', path: 'loan/post-mortgage-applications' },
    {label: `${this.firstName}`,path: `/loan/post-mortgage-dashboard/${this.AppID}`}
    ,{ label: 'View PPP Alert Document', path: '/', active: true }];
  }
 
}
